import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Results extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rand: 0
        }
    }
    componentDidMount()
    {
        // There's 20 space baby animations
        let rand = Math.ceil(Math.random() * 20);
        if (rand === 0) {
            rand = 1;
        }
        this.setState({
            rand: rand
        });
    }
    render()
    {
        return (
            <div id="results">
                <div className="fireworks">
                    <img src={"/img/fireworks.gif"} alt="" />
                </div>
                <div className="spacebaby">
                    <img src={"/img/spacebaby-animations/character" + this.state.rand + ".gif"} alt=""/>
                </div>
                <div className="options">
                    <p>Practice Complete! You answered {this.props.correct} / {this.props.total} correct!</p>
                    <button onClick={this.props.replay}>
                        <FontAwesomeIcon icon="redo" /> Practice Again
                    </button>
                    <button onClick={this.props.goToResults}>
                        <FontAwesomeIcon icon="poll-h" /> Go to Results
                    </button>
                </div>
            </div>
        )
    }
}

export default Results;
