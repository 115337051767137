import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Vimeo from "@u-wave/react-vimeo";


class SiteTour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'paused': false
        }
    }

    render() {
        if (this.props.show) {
            return (
                <div id={"site-tour"} onClick={this.props.hide}>
                    <div className={'close'} onClick={this.props.hide}>
                        <FontAwesomeIcon icon={"times-circle"}/>
                    </div>
                    <div className={'videoWrapper'}>
                        <Vimeo autoplay={true} video={this.props.videoId}/>
                    </div>
                </div>
            )
        }
        return false;
    }
}

export default SiteTour;
