import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './Lessons.css';
import Link from './Link';

const Loading = require('../common/Loading').Loading;
const moment = require('moment-timezone');
const WEBSITE_URL = require('../common/Urls').WEBSITE_URL;

class LearningPods extends Component {
    constructor(props) {
        super(props);
        this.changeCamps = this.changeCamps.bind(this);
        this.setPopUpContents = this.setPopUpContents.bind(this);
        this.showCampControls = this.showCampControls.bind(this);
    }

    setPopUpContents(event, src, title) {
        this.setState({
            popUpContents: <iframe src={src} title={title} />,
        });
        event.preventDefault();
        return false;
    }

    viewport()
    {
        var e = window
            , a = 'inner';
        if ( !( 'innerWidth' in window ) )
        {
            a = 'client';
            e = document.documentElement || document.body;
        }
        return { width : e[ a+'Width' ] , height : e[ a+'Height' ] }
    }

    showCampControls() {
        let self = this;
        let oc = document.querySelector('.class-list');
        let hasUserClasses = typeof(this.props.userClasses) !== 'undefined' && this.props.userClasses.noclasses !== 1;
        if (oc) {
            let ul = oc.querySelector('ul');
            let showArrows = hasUserClasses ? 2 : 4;
            if (self.props.page === "parent-dashboard") {
                showArrows = hasUserClasses ? 1 : 2;
            }
            let vw = self.viewport().width;

            if (vw <= 1300) {
                showArrows = 2;
            }
            if (vw <= 901) {
                showArrows = hasUserClasses ? 1 : 2;
            }
            let arrows = oc.querySelectorAll('.arrow');
            let classesDiv = oc.querySelector('.classes');
            if (ul && ul.querySelectorAll('li').length > showArrows) {
                for (let i in arrows) {
                    if (arrows.hasOwnProperty(i)) {
                        arrows[i].style.display = 'inline-block';
                    }
                }
                classesDiv.style.width = '86%';
                classesDiv.style.display = 'inline-block';
            } else {
                for (let i in arrows) {
                    if (arrows.hasOwnProperty(i)) {
                        arrows[i].style.display = 'none';
                    }
                }
                classesDiv.style.width = '98%';
                classesDiv.style.display = 'block';
            }
        }
    }

    componentDidMount() {
        this.showCampControls();
        window.onresize = this.showCampControls;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.showCampControls();
    }

    changeCamps(d) {
        var ul = document.querySelector('.online-classes ul');
        if (ul) {
            var lis = ul.querySelectorAll('li');
            if (d === 1) {
                var first = lis[0];
                ul.removeChild(first);
                ul.appendChild(first);
            } else if (d === -1) {
                var last = lis[lis.length - 1];
                ul.removeChild(last);
                ul.prepend(last);
            }
        }
    }

    render() {
        let self = this;
        let key = 0;
        let hasUserClasses = typeof(this.props.userClasses) !== 'undefined' && this.props.userClasses.noclasses !== 1;
        let now = new Date();
        let divClass = hasUserClasses ? 'class-list user-classes' :  'class-list';
        let tz = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
        return (
            <div className={divClass}>
                <div className="arrow arrow-left" onClick={function(){self.changeCamps(-1)}}>
                    <FontAwesomeIcon icon={'chevron-circle-left'} />
                </div>
                <div className={"classes"}>
                {!hasUserClasses && this.props.onlineClasses.length > 0 && <ul>
                    {this.props.onlineClasses.map(function(c){
                        return (
                        <li key={c.id}>
                            <div>
                            {c.thumbnail != null &&
                            <div className={"thumbnail"}>
                                <Link to={c.calendly_link}>
                                    <img src={c.thumbnail } alt=""/>
                                </Link>
                            </div>
                            }
                            </div>
                            <p className="title">
                                <Link to={c.calendly_link}>
                                    {c.title}
                                </Link>
                            </p>
                        </li>
                        );
                    })}
                </ul>}
                    {hasUserClasses && <ul>
                        {Object.keys(this.props.userClasses).map(function(c){
                        let cls = self.props.userClasses[c];
                        if (!cls.sessions) {
                            return false;
                        }
                        let time_key = 0;
                        for (let s in cls.sessions) {
                            if (cls.sessions.hasOwnProperty(s)) {
                                if (moment(cls.sessions[s].end_time) > now) {
                                    time_key = s;
                                    break;
                                }
                            }
                        }
                        let link = WEBSITE_URL + 'camps/class/' + cls.class.slug + '/';
                        let zoom_link = cls.class.zoom_link;
                        if (cls.sessions[time_key].teacher && cls.sessions[time_key].teacher.zoom_link) {
                            zoom_link = cls.sessions[time_key].teacher.zoom_link;
                        }
                        let start_time = moment(cls.sessions[time_key].start_time);
                        let start_time_format = start_time.format('h:mma');
                        let start_date_format = start_time.format('MMMM Do');
                        let show_link_time = start_time.subtract(30, 'minutes');
                        let show_link_time_format = show_link_time.format('h:mma');
                        let show_link_date = show_link_time.format('MMMM Do');
                        let same_day = start_time.isSame(now, 'day');
                        // NOTE: We already converted minus 30 minutes from the start time
                        let min_diff = Math.round((start_time.valueOf() - now.getTime()) / 1000 / 60);
                        key++;

                        return (
                        <li key={key}>
                            <div>
                            {cls.class.image != null &&
                            <div className={"thumbnail"}>
                                <Link to={link}>
                                    <img src={cls.class.image } alt=""/>
                                </Link>
                            </div>
                            }
                            </div>
                            <div className="title">
                                <Link to={link} dangerouslySetInnerHTML={{__html: cls.class.title}} />
                                <div>
                                {!same_day && min_diff > 30 && <div>
                                    Class starts at {start_time_format} {tz} on {start_date_format}.<br/>
                                    The link to join will appear here at {show_link_time_format} { tz } on {show_link_date}.
                                    </div>
                                }
                                {same_day && min_diff > 0 && <div>
                                    Class starts at {start_time_format} {tz}.<br/>
                                    The link to join will appear here at {show_link_time_format} { tz }.
                                    </div>
                                }
                                {same_day && min_diff <= 0 && min_diff > -30 && <div>
                                    Class starts in { min_diff + 30 } minutes.<br/>
                                    <Link target="_blank" to={zoom_link}>Click HERE to join</Link>
                                    </div>
                                }
                                {same_day && min_diff <= -30 && <div>
                                    <p>Class is in session.</p>
                                    <Link target="_blank" to={zoom_link}>Click HERE to join</Link>
                                    </div>
                                }
                                </div>
                            </div>
                        </li>
                        );
                    })}
                    </ul>}
                {this.props.onlineClasses.length === 0 && Object.keys(this.props.userClasses).length &&
                    <div style={{height: '300px', position: 'relative'}}><Loading/></div>
                }
                </div>
                <div className="arrow arrow-right" onClick={function(){self.changeCamps(1)}}>
                    <FontAwesomeIcon icon={'chevron-circle-right'} />
                </div>
            </div>
        )
    }
}
export default LearningPods;
