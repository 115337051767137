import React, {Component} from 'react';
import Lessons  from '../common/Lessons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Loading = require('../common/Loading').Loading;
const WEBSITE_URL = require('../common/Urls').WEBSITE_URL;

class HowToPrepLessons extends Component {

    componentDidMount() {
        if (this.props.user.accesss_tp === false) {
            window.location.href = WEBSITE_URL + 'buy-now/?redirected=1';
        }
    }

    render() {
        let self = this;
        let i = 0;
        return (
            <section className={"htp-section"}>
                <h2><FontAwesomeIcon icon={'file-alt'}/>Lessons for Parents/Parent Resources</h2>
                <p className="lesson-p">To learn more about the { this.props.test_page.title },
                    click the down arrow to the right of the item OR click the item to expand the information.</p>

                {self.props.test_page.tests.map(function(t) {
                    if (t.show_parent_lessons) {
                        i++;
                        if (self.props.tests_by_id.hasOwnProperty(t.id) && self.props.tests_by_id[t.id].hasOwnProperty('lessons')) {
                            return (
                                <Lessons key={i}
                                         lesson_key={i}
                                         lessons={self.props.tests_by_id[t.id]['lessons']}
                                         test_title={self.props.test_page.title}
                                         videos={self.props.test_page.videos}
                                         user={self.props.user}
                                />
                            )
                        } else {
                            return false;
                        }
                    } else{
                        return false;
                    }
                })}
                {(self.props.test_page.tests.length === 0 || Object.keys(self.props.tests_by_id).length === 0) &&
                    <div style={{position: 'relative', height: '300px'}}><Loading /></div>
                }
            </section>
        )
    }
}

export default HowToPrepLessons;
