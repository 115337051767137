import React from 'react';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'
import './index.css';
import App from './App';
// import * as serviceWorker from './service-worker';

const hostname = window && window.location && window.location.hostname;
const { version } = require('../package.json');
const path = require('path');
const errorsToIgnore = [
    'ResizeObserver loop limit exceeded',
    /PickleRick_\d\d/,
    'RangeError',
    'NotAllowedError',
    'NotSupportedError: The operation is not supported.',
    'NotSupportedError',
    'AbortError: The operation was aborted.',
    'AbortError',
    'ResizeObserver loop completed with undelivered notifications',
    "'maxthon' is undefined",
    "Error: The operation was canceled by the user.",
    'TypeError: Cancelled',
    'Cancelled',
    'cancelled',
    'TypeError: cancelled',
    'The network connection was lost.',
    'Failed to fetch',
    'NetworkError when attempting to fetch resource.',
    'Error: Network Error',
    'Permission denied to access property "clientX"',
    'The operation couldn’t be completed. Software caused connection abort',
];
if (hostname === 'staging.app.testingmom.com') {
    Sentry.init(
        {
            dsn: "https://bf64a2dff65f4940a18ba80f1ff77262@sentry.io/1491353",
            release: version,
            ignoreErrors: errorsToIgnore,
            whitelistUrls: [/https?:\/\/staging.app.testingmom.com/],
            beforeSend (event) {
                try {
                    if (event.extra.__serialized__.detail.reason.message === 'Extension context invalidated.') {
                        return null;
                    }
                } catch(_o_O) {
                    return event;
                }
                if (
                    !event.exception ||
                    !event.exception.values ||
                    !event.exception.values[0]
                ) {
                    return event
                }

                const value = event.exception.values[0];

                if (value.stacktrace && value.stacktrace.frames) {
                    const root = process.cwd();
                    value.stacktrace.frames.forEach(function (frame) {
                            let filename = path.relative(root, frame.filename);
                            let i = filename.indexOf('static/js');
                            frame.filename = filename.substr(i + 9);

                    })
                }
                return event
            }
        }
    );
}

if (hostname === 'app.testingmom.com') {
    Sentry.init(
        {
            dsn: "https://86c88130671d491bb037a525c9f2b3c3@sentry.io/1860324",
            release: version,
            ignoreErrors: errorsToIgnore,
            whitelistUrls: [/https?:\/\/app.testingmom.com/],
            beforeSend (event) {
                try {
                    let message = event.extra.__serialized__.detail.reason.message;
                    if (message === 'Extension context invalidated.') {
                        return null;
                    }
                } catch(_o_O) {
                    return event;
                }
                if (
                    !event.exception ||
                    !event.exception.values ||
                    !event.exception.values[0]
                ) {
                    return event
                }

                const value = event.exception.values[0];

                if (value.stacktrace && value.stacktrace.frames) {
                    const root = process.cwd();
                    value.stacktrace.frames.forEach(function (frame) {
                            let filename = path.relative(root, frame.filename);
                            let i = filename.indexOf('static/js');
                            frame.filename = filename.substr(i + 9);

                    })
                }
                return event
            }
        }
    );
}

ReactDOM.render(<Router><App /></Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// if (process.env.NODE_ENV === 'development') {
//     serviceWorker.register();
// }
