import {Component} from 'react';
import {withRouter} from 'react-router-dom';

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if ((
                this.props.location.pathname !== prevProps.location.pathname &&
                this.props.location.pathname.indexOf('dashboard') < 0
            ) || (
                this.props.location.pathname.substr(0, 12) !== prevProps.location.pathname.substr(0, 12) &&
                this.props.location.pathname.indexOf('dashboard') > 0
            )
        ) {
            let y = 0;
            let page = document.querySelector('.page.content');
            if (page) {
                y = page.offsetTop;
            }
            if (window.scrollY > y) {
                window.scrollTo(0, y)
            }
        }
    }

    render() {
        return this.props.children
    }
}

export default withRouter(ScrollToTop)