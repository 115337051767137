import 'whatwg-fetch'
const API_URL = require('./Urls').API_URL;

export const ApiFetch = function(endpoint, method, data) {
    if (!method) {
        method = 'GET'
    }
    let headers = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        method: method,
        body: JSON.stringify(data),
        credentials: 'include',
    };
    return fetch(API_URL + endpoint, headers)
        .then((response) => {
            if (response.ok) {
                return response.text().then(function(text) {
                    return text ? JSON.parse(text) : {}
                });
            } else {
                throw response;
            }
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
           if (typeof error.text !== 'undefined') {
                error.text().then(message => {
                    console.warn(message);
                });
            } else {
                console.warn(error);
            }
           throw error;
        });
};
