import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const WEBSITE_URL = require('./Urls').WEBSITE_URL;

class CustomerSupport extends Component {
    render() {
        return (
            <div id="call-us">
                <a href={WEBSITE_URL + "my-account/contact-support/"}><FontAwesomeIcon icon={"phone"} />
                    Need help? - <span>call us at 877-609-6203</span>
                </a>
            </div>
        );
    }
}

export default withRouter(CustomerSupport);
