import React, { Component } from 'react';
import {
    Redirect,
    Route,
    Switch,
} from 'react-router-dom'
import { withRouter } from 'react-router'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Fuse from 'fuse.js';
import 'whatwg-fetch'
import './common/Polyfills';
import * as Sentry from '@sentry/browser';

import AvatarOverlay from "./header/AvatarOverlay";
import ChildNavigation from "./common/ChildNavigation";
import ChildSetup from './dashboards/ChildSetup';
import Enrichment from "./parent-resources/Enrichment";
import Games from './sba-games/Games';
import GamesIndex from './sba-games/GamesIndex';
import Header from './header/Header';
import Hero from './header/Hero';
import HowToPrep from "./test-prep/HowToPrep";
import Link from "./common/Link";
import ParentDashboard from "./dashboards/ParentDashboard";
import ParentResources from "./parent-resources/ParentResources";
import SbaIndex from "./sba-games/SbaIndex";
import SbaProgram from "./sba-games/SbaProgram";
import SidebarLeft from './common/SidebarLeft';
import SidebarRight from "./common/SidebarRight";
import SiteTour from './common/SiteTour';
import StatesCommonCore from './test-directory/StatesCommonCore';
import TestDashboard from "./dashboards/TestDashboard";
import TestDirectory from "./test-directory/TestDirectory";
import {version} from '../package.json';


/* FontAwesome Icons must first be imported here and added to the library before using in Components */
import {
    faAppleAlt,
    faArrowLeft,
    faArrowRight,
    faArrowCircleLeft,
    faBars,
    faBook,
    faCamera,
    faCaretDown,
    faCaretUp,
    faChartLine,
    faChevronCircleLeft,
    faChevronCircleRight,
    faClipboardListCheck,
    faCog,
    faDesktop,
    faEdit,
    faEnvelope,
    faExclamationTriangle,
    faExternalLinkAlt,
    faFileAlt,
    faFileCheck,
    faFileSearch,
    faFlowerDaffodil,
    faFolders,
    faFrown,
    faGameBoardAlt,
    faGamepad,
    faHeadSideBrain,
    faHome,
    faInfoCircle,
    faLeafMaple,
    faPhone,
    faPhoneLaptop,
    faPlusCircle,
    faPollH,
    faPrint,
    faPuzzlePiece,
    faRedo,
    faStar as faStarSolid,
    faSearch,
    faSmile,
    faSnowflake,
    faStoreAlt,
    faSyncAlt,
    faTimes,
    faTimesCircle,
    faTrophyAlt,
    faUmbrellaBeach,
    faUserCircle,
    faUsersClass,
    faUserMdChat,
    faVideo,
} from '@fortawesome/pro-solid-svg-icons';
import CustomerSupport from "./common/CustomerSupport";
import {
    faCheckCircle,
    faCheckSquare,
    faCircle,
    faClipboardListCheck as faClipCheckReg,
    faComputerClassic,
    faSquare,
    faStar,
    faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';
import PodsModal from "./dashboards/PodsModal";
import ScrollToTop from "./common/ScrollToTop";

library.add(
    faAppleAlt,
    faArrowLeft,
    faArrowRight,
    faArrowCircleLeft,
    faBars,
    faBook,
    faCamera,
    faCaretDown,
    faCaretUp,
    faCheckCircle,
    faChartLine,
    faCheckSquare,
    faChevronCircleLeft,
    faChevronCircleRight,
    faCircle,
    faClipboardListCheck,
    faClipCheckReg,
    faCog,
    faComputerClassic,
    faDesktop,
    faEdit,
    faEnvelope,
    faExclamationTriangle,
    faExternalLinkAlt,
    faFileAlt,
    faFileCheck,
    faFileSearch,
    faFlowerDaffodil,
    faFolders,
    faFrown,
    faGameBoardAlt,
    faGamepad,
    faHeadSideBrain,
    faHome,
    faInfoCircle,
    faLeafMaple,
    faPhone,
    faPhoneLaptop,
    faPlusCircle,
    faPollH,
    faPrint,
    faPuzzlePiece,
    faRedo,
    faSearch,
    faSmile,
    faSnowflake,
    faSquare,
    faStar,
    faStarSolid,
    faStoreAlt,
    faSyncAlt,
    faTimes,
    faTimesCircle,
    faTrashAlt,
    faTrophyAlt,
    faUmbrellaBeach,
    faUserCircle,
    faUsersClass,
    faUserMdChat,
    faVideo,
);
config.searchPseudoElements = true;

const ApiFetch = require('./common/ApiFetch').ApiFetch;
const Four04 = require('./common/Four04').Four04;
const Loading = require('./common/Loading').Loading;
const moment = require('moment-timezone');
const WEBSITE_URL = require('./common/Urls').WEBSITE_URL;
const MEMBERS_URL = require('./common/Urls').MEMBERS_URL;


class App extends Component {
    constructor(props) {
        super(props);
        let isIE = /*@cc_on!@*/false || !!document.documentMode;
        if (isIE) {
            this.state = {
                isIE: true
            };
            return;
        }
        this.state = {
            avatar_overlay_showing: false,
            dt_config: {},
            games: [],
            grades: {},
            grades_by_id: {},
            onlineClasses: [],
            onlineClassCategories: [],
            online_tutoring_cta: '',
            regions: [],
            resources: [],
            searchLinks: [],
            siteTourVideoId: '380754676',
            showSiteTour: false,
            tests: [],
            tests_by_grade: {},
            tests_by_id: {},
            test_pages: {},
            test_pages_by_slug: {},
            tutoring_cta: '',
            user: {
                'paid_access': false,
                'active_child': 0,
                'active_test': {},
                'children': [],
                'child_setup': false,
                'email': '',
                'first_name': '',
                'grades': {},
                'last_name': '',
                'subjects': {},
                'pk': 0,
                'tests': {},
                'tutoring_client': true
            },
            userClasses: {},
        };


        this.initialX = 0;
        this.initialY = 0;

        this.activeTestSet = false;
        this.checkAddStudent = this.checkAddStudent.bind(this);
        this.deleteChild = this.deleteChild.bind(this);
        this.deleteCookie = this.deleteCookie.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);
        this.hideAvatars = this.hideAvatars.bind(this);
        this.hideSearch = this.hideSearch.bind(this);
        this.hideSiteTour = this.hideSiteTour.bind(this);
        this.search = this.search.bind(this);
        this.setChildAssessmentsByTest = this.setChildAssessmentsByTest.bind(this);
        this.setPrintables = this.setPrintables.bind(this);
        this.setPrintablesByTestPage = this.setPrintablesByTestPage.bind(this);
        this.setResources = this.setResources.bind(this);
        this.showAvatars = this.showAvatars.bind(this);
        this.showSiteTour = this.showSiteTour.bind(this);
        this.shuffle = this.shuffle.bind(this);
        this.startSwipeSidebar = this.startSwipeSidebar.bind(this);
        this.swipeSidebar = this.swipeSidebar.bind(this);
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.updateChild = this.updateChild.bind(this);
        this.updateTest = this.updateTest.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.viewport = this.viewport.bind(this);

        let self = this;
        this.avatars = [
            'ava_logo',
            'ava_blue1',
            'ava_blue2',
            'ava_cartoon_1',
            'ava_cartoon_10',
            'ava_cartoon_11',
            'ava_cartoon_2',
            'ava_cartoon_3',
            'ava_cartoon_4',
            'ava_cartoon_5',
            'ava_cartoon_6',
            'ava_cartoon_7',
            'ava_cartoon_8',
            'ava_cartoon_9',
            'ava_green1',
            'ava_green2',
            'ava_orange1',
            'ava_orange2',
            'ava_pink1',
            'ava_pink2',
            'ava_pink3',
            'ava_purple1',
            'ava_purp2',
            'ava_yellow1',
            'ava_yellow2',
            'ava_yellow3',
        ];
        this.themes = [
            'bnnr_blueLines_tile',
            'bnnr_booksWhitebg_tile',
            'bnnr_clouds_tile',
            'bnnr_colorBooksDark_tile',
            'bnnr_greenLines_tile',
            'bnnr_purpHearts_tile',
            'bnnr_turqBooksClouds_tile',
            'bnnr_turqLines_tile',
        ];
        this.base_price = 0;
        this.packages = {};
        this.referer = document.referrer;
        this.ip = '';

        window.addEventListener("unhandledrejection", event => {
            console.warn(`UNHANDLED PROMISE REJECTION: ${event.reason}`);
        });

        var script = document.createElement('script');
        script.src = "https://items.learnosity.com/?v2024.1.lts";
        document.head.appendChild(script);

        ApiFetch('package/?limit=20', 'GET').then(function(res) {
            self.packages = res['results'];
        });
        ApiFetch('user/?app=1').then(function(res) {
            if (
                typeof res !== 'undefined' &&
                res.hasOwnProperty('results') &&
                res['results'].length
            ) {
                let user = res['results'][0];
                if (!user['use_app'] && !user['is_staff']) {
                    let path = MEMBERS_URL + window.location.pathname.substr(1);
                    if (window.location.search) {
                        path += window.location.search;
                    }
                    window.location.href = path;
                    return;
                }
                if (user['city']) {
                    user['is_nyc'] = user['city'].toLowerCase().indexOf('new york') > -1;
                }
                user['active_test'] = {};

                let has_active = false;
                if (user['children'] && user['children'].length) {
                    for (let child in user['children']) {
                        if (user['children'].hasOwnProperty(child)) {
                            if (user['children'][child]['grade'] !== null) {
                                user['child_setup'] = true;
                            }
                            if (user['children'][child]['active'] === true) {
                                has_active = true;
                                user['active_child'] = child;
                                user['children'][child]['active'] = true;
                            }
                            if (user['children'][child].hasOwnProperty('secure_documents')) {
                                user['children'][child]['secure_documents'] =
                                    self.setChildDocuments(user['children'][child]['secure_documents'])
                            } else {
                                user['children'][child]['secure_documents'] = {};
                            }
                            if (user['children'][child].hasOwnProperty('assessments')) {
                                user['children'][child]['assessments_by_test'] =
                                    self.setChildAssessmentsByTest(user['children'][child]['assessments'])
                            } else {
                                user['children'][child]['assessments_by_test'] = {};
                            }
                        }
                    }
                }

                if (!has_active && res['results'][0]['children'].length) {
                    user['active_child'] = 0;
                    user['children'][0]['active'] = true;
                }

                user['pk'] = res['results'][0]['id'];

                let dataLayer = {
                    'event': 'pageview',
                    'testingmom_email': user['email'],
                    'testingmom_userId': user['pk'],
                    'app_version': 'app'
                };

                Sentry.setUser({"id": user['pk'], "email": user['email']});

                if (res['results'][0]['active_subscription']) {
                    dataLayer['testingmom_subscriptionPackage'] = res['results'][0]['active_subscription']['sub_level'];
                    dataLayer['testingmom_orderId'] = res['results'][0]['active_subscription']['id'];
                    dataLayer['testingmom_subscriptionAmount'] = res['results'][0]['active_subscription']['amount'];
                    dataLayer['testingmom_duration'] = res['results'][0]['active_subscription']['duration'];
                }

                // Set attribution datalayer and delete cookies
                if (self.getCookie('paid_sign_up')) {
                    dataLayer['paid_sign_up'] = 1;
                    self.deleteCookie('paid_sign_up', '/', '.testingmom.com');
                }
                if (self.getCookie('tutoring_upsell')) {
                    dataLayer['tutoring_upsell'] = 1;
                    self.deleteCookie('tutoring_upsell', '/', '.testingmom.com');
                }
                if (self.getCookie('gogo_upsell')) {
                    dataLayer['gogo_upsell'] = 1;
                    self.deleteCookie('gogo_upsell', '/', '.testingmom.com');
                }
                if (self.getCookie('coding_upsell')) {
                    dataLayer['coding_upsell'] = 1;
                    self.deleteCookie('coding_upsell', '/', '.testingmom.com');
                }

                window.dataLayer.push(dataLayer);

                if (!user['paid_access']) {
                    let date_joined = Math.round(new Date(user['date_joined']).getTime() / 1000);
                    let now = Math.round(new Date().getTime() / 1000);
                    let sale = now - date_joined > (14 * 24 * 3600);
                    for (let pack in self.packages) {
                        if (self.packages.hasOwnProperty(pack) &&
                            self.packages[pack]['package']['title'] === 'Top 1% Fast Track Membership' &&
                            self.packages[pack]['duration'] === 12
                        ) {
                            if (sale) {
                                self.base_price = (self.packages[pack]['saleprice'] / 12).toFixed(2)
                            } else {
                                self.base_price = (self.packages[pack]['price'] / 12).toFixed(2)
                            }
                        }

                    }
                }
                self.setState({
                    user: user,
                }, self.setSearch);

            }
        }).catch(function() {
            window.location.href = WEBSITE_URL;
        });
        ApiFetch('test/?limit=100&status=1').then(function(res) {
            if (typeof res !==  'undefined' && res.hasOwnProperty('results')) {
                let grades = {};
                let grades_by_id = {};
                let tests_by_grade = {};
                let tests_by_id = {};
                let tests = {};
                let games = {};
                for (let i in res['results']) {
                    if (res['results'].hasOwnProperty(i)) {
                        tests[res['results'][i]['slug']] = res['results'][i];
                        tests_by_id[res['results'][i]['id']] = res['results'][i];
                        for (let g in res['results'][i]['grades']) {
                            if (res['results'][i]['grades'].hasOwnProperty(g)) {
                                if (
                                    res['results'][i] &&
                                    res['results'][i]['grades'][g]['numeric'] < 9 &&
                                    res['results'][i]['grades'][g]['numeric'] > -2
                                ) {
                                    if (tests_by_grade[res['results'][i]['grades'][g]['id']] === undefined) {
                                        tests_by_grade[res['results'][i]['grades'][g]['id']] = {
                                            'recommended': [],
                                            'other': [],
                                            'all': []
                                        };
                                        grades[res['results'][i]['grades'][g]['order']] = res['results'][i]['grades'][g];
                                        grades_by_id[res['results'][i]['grades'][g]['id']] = res['results'][i]['grades'][g];
                                    }
                                    if (res['results'][i]['recommended']) {
                                        tests_by_grade[res['results'][i]['grades'][g]['id']]['recommended'].push(res['results'][i])
                                    } else {
                                        tests_by_grade[res['results'][i]['grades'][g]['id']]['other'].push(res['results'][i])
                                    }
                                    tests_by_grade[res['results'][i]['grades'][g]['id']]['all'].push(res['results'][i]);
                                }
                            }
                        }
                        let apps = res['results'][i]['apps'];
                        for (let a in apps) {
                            if (apps.hasOwnProperty(a) && !games.hasOwnProperty(apps['order'])) {
                                games[apps[a]['order']] = res['results'][i]['apps'][a];
                            }
                        }
                    }
                }
                self.setState({
                    games: games,
                    tests: tests,
                    tests_by_grade: tests_by_grade,
                    tests_by_id: tests_by_id,
                    grades: grades,
                    grades_by_id: grades_by_id
                });
            }
        });
        ApiFetch('test/page/?limit=100&app=1&status=1&ordering=title&min_count=0').then(function(res) {
            if (res) {
                if (res.hasOwnProperty('results') && res['results'].length) {
                    let test_pages_by_slug = {};
                    for (let page in res['results']) {
                        if (res['results'].hasOwnProperty(page)) {
                            test_pages_by_slug[res['results'][page]['slug']] = res['results'][page];
                        }
                    }
                    self.setState({
                        test_pages_by_slug: test_pages_by_slug,
                        test_pages: res['results']
                    });
                }
            }
        });
        ApiFetch('country/?country=USA&app=1').then(function (res) {
            if (typeof res !== 'undefined' && res.hasOwnProperty('results') && res['results'].length) {
                let regions = {};
                for (let region in res['results'][0]['regions']) {
                    if (res['results'][0]['regions'].hasOwnProperty(region)) {
                        regions[res['results'][0]['regions'][region]['id']] = res['results'][0]['regions'][region];
                    }
                }
                self.setState({
                    'regions': regions
                });
            }
        });
        ApiFetch('option/?name=tutoring_cta').then(function(res) {
            if (typeof res !== 'undefined' && res.hasOwnProperty('results') && res['results'].length) {
                self.setState({
                    tutoring_cta: res['results'][0]['option_value']
                });
            }
        });
        ApiFetch('option/?name=online_tutoring_cta').then(function(res) {
            if (typeof res !== 'undefined' && res.hasOwnProperty('results') && res['results'].length) {
                self.setState({
                    online_tutoring_cta: res['results'][0]['option_value']
                });
            }
        });
        ApiFetch('digital-tutor/config/?limit=1000', 'GET').then(function(res) {
            let dt_config = {};
            if (typeof res !== 'undefined' && res.hasOwnProperty('results') && res['results'].length) {
                for (let r in res['results']) {
                    if (res['results'].hasOwnProperty(r)) {
                        if (!dt_config.hasOwnProperty(res['results'][r]['test'])) {
                            dt_config[res['results'][r]['test']] = {};
                        }
                        dt_config[res['results'][r]['test']][res['results'][r]['grade']['id']] = res['results'][r];
                    }
                }
                self.setState({
                    dt_config: dt_config
                });
            }
        });
        ApiFetch("lms/online-class-roster", 'GET').then(function(results) {
            let classData = {};
            if (results.results) {
                let now = new Date();
                let one_week = new Date();
                one_week.setDate(one_week.getDate() + 7);
                for (let i in results.results) {
                    if (results.results.hasOwnProperty(i)) {
                        let cls = results.results[i];
                        if (cls.session) {
                            if (!classData.hasOwnProperty(cls.session.sessiongroup)) {
                                if (!classData.hasOwnProperty(cls.session.sessiongroup) &&
                                    moment(cls.session.end_time) > now &&
                                    moment(cls.session.start_time) < one_week
                                ) {
                                    classData[cls.session.sessiongroup] = {
                                        'sessions': [cls.session],
                                        'class': cls.onlineclass
                                    }
                                }
                            }
                        } else if (cls.sessiongroup && cls.sessiongroup.sessions.length) {
                            if (moment(cls.sessiongroup.sessions[cls.sessiongroup.sessions.length - 1].end_time) > now &&
                                moment(cls.sessiongroup.sessions[cls.sessiongroup.sessions.length - 1].start_time) < one_week
                            ) {
                                classData[cls.sessiongroup.id] = {
                                    'sessions': cls.sessiongroup.sessions,
                                    'class': cls.onlineclass
                                }
                            }
                        }
                    }
                }
            }
            if (Object.keys(classData).length === 0) {
                classData = {
                    'noclasses': 1
                }
            }
            self.setState({
                'userClasses': classData
            });
        });
        ApiFetch("online-class-camp/?promoted=true", 'GET').then(function(results){
            let allClassData = [];
            for (let i in results.results) {
                if (results.results.hasOwnProperty(i)) {
                    let classData = {
                        id: results.results[i].id,
                        title: results.results[i].title,
                        thumbnail: results.results[i].thumbnail,
                        calendly_link: results.results[i].calendly_link,
                        tests: results.results[i].tests,
                        grades: results.results[i].grades,
                    };
                    allClassData.push(classData);
                }
            }
            if (allClassData) {
                self.shuffle(allClassData);
                self.setState({
                    'onlineClasses': allClassData
                });
            }
        });
        ApiFetch("lms/online-class-category?limit=100&ordering=title", 'GET').then(function(results){
            let allClassData = [];
            for (let i in results.results) {
                if (results.results.hasOwnProperty(i)) {
                    let classData = {
                        id: results.results[i].id,
                        title: results.results[i].title,
                    };
                    allClassData.push(classData);
                }
            }
            if (allClassData) {
                self.setState({
                    'onlineClassCategories': allClassData
                });
            }
        });
    }

    componentDidMount() {
        document.body.addEventListener('touchstart', this.startSwipeSidebar);
        document.body.addEventListener('touchmove', this.swipeSidebar);
    }

    componentWillMount() {
        let self = this;
        if (typeof(this.state.isIE) === 'undefined') {
            fetch("https://api.ipify.org?format=json")
                .then((response) => response.json())
                .then((responseData) => {
                    this.ip = responseData['ip'];
                    self.tracking(window.location.pathname);
                })
                .catch(error => console.warn(error));
            this.unlisten = this.props.history.listen((location, action) => {
                self.tracking(location.pathname);
            });
        }
    }

    componentWillUnmount() {
        this.unlisten();
    }

    getCookie(name) {
        let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : "";
    }

    deleteCookie( name, path, domain ) {
        if(this.getCookie( name ) ) {
            document.cookie = name + "=" +
                ((path) ? ";path="+path:"")+
                ((domain)?";domain="+domain:"") +
                ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
        }
    }

    tracking(location) {
        let tracking_data = {
            "request_uri": location,
            "session_id": this.getCookie("tm_sess_id", ""),
            "query_string": window.location.search,
            "source": 0,
            "campaign": "",
            "ip": this.ip,
            "http_referer": this.http_referer,
            "user_agent": navigator.userAgent,
            "server": 'app',
            "track_id": this.getCookie("tm_track", "")
        };
        this.http_referer = window.location.hostname + '/' + location;
        ApiFetch('tracking/', 'POST', tracking_data);
    }

    setSearch()
    {
        let self = this;
        let paid = (this.state.user.paid_access) ? '0,1' : '0,2';
        window.Beacon('init', '7d97db9c-aefe-4c35-848e-6c959a587a3c');
        ApiFetch('search-terms/?paid=' + paid).then(
            function(searchTerms) {
                for (let term in searchTerms) {
                    if (searchTerms.hasOwnProperty(term)) {
                        if (searchTerms[term].hasOwnProperty('url')) {
                            searchTerms[term]['url'] = searchTerms[term]['url'].replace('https://members.testingmom.com/', '/');
                            searchTerms[term]['url'] = searchTerms[term]['url'].replace(MEMBERS_URL, '/');
                        }
                    }
                }
                if (searchTerms) {
                    var options = {
                        shouldSort: true,
                        matchAllTokens: true,
                        threshold: 0.4,
                        location: 0,
                        distance: 100,
                        maxPatternLength: 32,
                        minMatchCharLength: 1,
                        keys: [{
                            name: 'title',
                            weight: 0.4
                        }, {
                            name: 'keywords',
                            weight: 0.8
                        }]
                    };
                    self.fuse = new Fuse(searchTerms, options);
                }
            }
        );
    }

    shuffle(array) {
       array.sort(function(){
           return Math.random() - 0.5
       });
    }

    isDescendant(parent, child) {
        while(child.parentNode ){
            if (child.parentNode === parent) {
                return true;
            } else {
                child = child.parentNode;
            }
        }
        return parent === child;
    }

    hideSearch(e)
    {
        let sr = document.getElementById('search-results');
        if (sr) {
            if (!this.isDescendant(sr, e.target)) {
                sr.style.display = 'none';
            }
            let menu = document.getElementById('menu');
            let menuButton = document.getElementById('menuButton');
            if (!this.isDescendant(menu, e.target) && !this.isDescendant(menuButton, e.target)) {
                menu.style.height = 0;
                menu.style.borderBottomWidth = 0;
            }
        }
    }

    search(e)
    {
        let sr = document.getElementById('search-results');
        if (sr.childNodes.hasOwnProperty(0)) {
            if(e.target.value.length > 2) {
                let results = this.fuse.search(e.target.value);
                let links = [];
                if (results.length) {
                    for (let result in results) {
                        if (results.hasOwnProperty(result) && result < 10) {
                            let url = results[result]['url'];
                            if (url.indexOf('?') !== -1) {
                                url += '&search=' + e.target.value;
                            } else {
                                url += '?search=' + e.target.value;
                            }
                            links.push(<li key={result}><Link to={url}>{results[result]['title']}</Link></li>);
                        }
                    }
                } else {
                    links.push(<li>Sorry there are no results.</li>);
                }
                this.setState({
                    searchLinks: links
                });
                sr.style.display = 'block';
                sr.childNodes[0].style.borderBottom = '1px solid #ccc';
            } else {
                sr.style.display = 'none';
            }
        }
    }

    componentDidUpdate(prevProps) {
        let has_properties = false;
        for (let test in this.state.tests) {
            if (this.state.tests.hasOwnProperty(test)) {
                has_properties = true;
            }
        }

        if (!this.activeTestSet &&
            has_properties &&
            Object.keys(this.state.user.active_test).length === 0 &&
            this.state.user.active_child >= 0 &&
            this.state.user.children.length &&
            this.state.user.children[this.state.user.active_child].tests.length) {
            for (let t in this.state.user.children[this.state.user.active_child].tests) {
                if (
                    this.state.user.children[this.state.user.active_child].tests.hasOwnProperty(t) &&
                    this.state.user.children[this.state.user.active_child].tests[t]['primary']
                ) {
                    this.activeTestSet = true;
                    this.updateUser({active_test: this.state.tests[this.state.user.children[this.state.user.active_child].tests[t]['test']]})
                }
            }
        }
    }

    updateUser(userData, callback) {
        let user = this.state.user;
        for (let key in userData) {
            if (userData.hasOwnProperty(key)) {
                user[key] = userData[key];
            }
        }

        if (user['children'].length > 0) {
            for (let child in user['children']) {
                if (user['children'].hasOwnProperty(child)) {
                    if (user['children'][child].grade !== null && user['children'].name !== null) {
                        user['child_setup'] = true;
                    }
                }
            }
        }

        this.setState({
            user: user
        }, callback);
    }

    deleteDocument(child_doc_id, doc_id)
    {
        ApiFetch('user-child-document/' + child_doc_id + '/', 'DELETE');
        let user = this.state.user;
        let documents = user.children[user.active_child].secure_documents;
        for (let id in documents) {
            if (documents.hasOwnProperty(id) && id === doc_id) {
                let key = user.children[user.active_child].secure_documents[id].length - 1;
                user.children[user.active_child].secure_documents[id].pop();
                if (key === 0) {
                    delete user.children[user.active_child].secure_documents[id];
                }
            }
        }
        this.setState({
            user: user
        });
    }

    setChildDocuments(documents) {
        let docs = {};
        for (let d in documents) {
            if (documents.hasOwnProperty(d)) {
                if (!docs.hasOwnProperty(documents[d]['secure_document'])) {
                    docs[documents[d]['secure_document']] = [];
                }
                docs[documents[d]['secure_document']].push(documents[d])
            }
        }
        return docs;
    }

    setChildAssessmentsByTest(assessments) {
        let assess = {};
        if (typeof assessments === 'undefined') {
            assessments = this.state.user.children[this.state.user.active_child]['assessments'];
        }
        for (let a in assessments) {
            if (assessments.hasOwnProperty(a)) {
                if (assessments[a].learnosity) {
                    let assess_date = new Date(assessments[a]['created_at']);
                    let break_date = new Date('2020-03-11T00:00:00.00000Z');
                    let fix_date = new Date('2020-03-12T23:00:00.0000Z');
                    if (break_date < assess_date && fix_date > assess_date) {
                        continue;
                    }
                }
                if (!assess.hasOwnProperty(assessments[a]['test'])) {
                    assess[assessments[a]['test']] = {
                        'questions': [],
                        'ids': [assessments[a]['id']],
                        'keys': [parseInt(a)],
                    }
                } else {
                    assess[assessments[a]['test']]['ids'].push(assessments[a]['id']);
                    assess[assessments[a]['test']]['keys'].push(parseInt(a));
                }
                for (let q in assessments[a]['questions']) {
                    if (assessments[a]['questions'].hasOwnProperty(q)) {
                        assess[assessments[a]['test']]['questions'].push(
                            assessments[a]['questions'][q]['question']
                        )
                    }
                }
            }
        }
        return assess;
    }

    updateTest(slug, data) {
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                let tests = this.state.tests;
                if (slug in tests) {
                    tests[slug][key] = data[key];

                    let user = this.state.user;
                    if (user.active_test[slug] === slug) {
                        user.active_test[key] = data[key]
                    }
                    let tests_by_id = this.state.tests_by_id;
                    tests_by_id[tests[slug].id][key] = data[key];
                    this.setState({
                        tests: tests,
                        tests_by_id: tests_by_id,
                        user: user
                    });
                }
            }
        }
    }

    updateChild(child_id, childData, callback) {
        let self = this;
        ApiFetch('user-child/' + child_id + '/', 'PATCH', childData).then(function (response) {
            // Set secure documents in a better way to work with
            if (response) {
                if (response.hasOwnProperty('secure_documents')) {
                    response['secure_documents'] = self.setChildDocuments(response['secure_documents']);
                }

                let userData = {};
                let children = self.state.user.children.slice();

                for (let c in children) {
                    if (children.hasOwnProperty(c)) {
                        if (children[c].id === child_id) {
                            if (childData['active'] === true) {
                                userData['active_child'] = c;
                            }
                            for (let prop in response) {
                                children[c][prop] = response[prop];
                            }
                            if (!children[c].hasOwnProperty('assessments')) {
                                children[c]['assessments'] = [];
                                children[c]['assessments_by_test'] = {}
                            }
                        }
                    }
                }
                userData['children'] = children;
                self.updateUser(userData, callback);
            }
        });
    }

    deleteChild(child_id, child_name, user, history, callback) {
        let self = this;
        document.documentElement.style.overflowY = 'scroll';
        confirmAlert({
            title: 'Remove ' + child_name + '?',
            message: 'Are you sure you want to remove this student from your account?  Please note that ' +
                'all data for this student will be permanently deleted if you select "Yes".',
            buttons: [
            {
                label: 'Yes',
                onClick: function() {
                    let children = user['children'].slice();
                    for (let child in children) {
                        if (children.hasOwnProperty(child)) {
                            if (child_id === children[child]['id']) {
                                children.splice(child, 1);
                            }
                        }
                    }
                    history.push('/');
                    let userData = {'children': children, 'active_child': -1};
                    if (children.length === 0) {
                        userData['child_setup'] = false;
                    }

                    self.updateUser(userData, function(){
                        ApiFetch('user-child/' + child_id + '/', 'DELETE').then(function () {
                            document.documentElement.style.overflowY = 'auto';
                            callback();
                            if (self.state.user.children.length === 0) {
                                userData['child_setup'] = false;
                                self.setState({
                                    'child_setup': false
                                });
                            }
                        });
                    });


                }
            },
            {
                label: 'No',
                onClick: () => {
                    document.documentElement.style.overflowY = 'auto';
                }
            }
            ]
        });
    }

    checkAddStudent(e) {
        document.documentElement.style.overflowY = 'scroll';
        if (this.state.user.children.length >= this.state.user.allowed_children) {
            confirmAlert({
                message: 'You have reached the maximum number of students per account.  ' +
                    'Please remove an existing student in order to add a new student.',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: function() {
                            document.documentElement.style.overflowY = 'auto';
                        }
                    },
                ]
            });
            e.stopPropagation();
            e.preventDefault();
        }
    }

    setResources(resources) {
        this.setState({
            resources: resources,
        })
    }

    setPrintablesByTestPage(test_page)
    {
        if (test_page && test_page.hasOwnProperty('tests')) {
            for (let t in test_page['tests']) {
                let test = this.state.tests_by_id[test_page['tests'][t]['id']];
                if (test !== undefined) {
                    if (test_page['tests'][t].show_practice_questions) {
                        if (!test.hasOwnProperty('documents') || test['documents'].length === 0) {
                            this.setPrintables(test);
                        }
                    }
                }
            }
        }
    }

    setPrintables(test, state)
    {
        let self = this;
        if (test.slug && test.slug in this.state.tests &&
            (
                !this.state.tests[test.slug].hasOwnProperty('documents') ||
                Object.keys(this.state.tests[test.slug]['documents']).length === 0
            )
        ) {
            ApiFetch('document/?limit=1000&test=' + test.slug).then(function (res) {
                if (typeof res !== 'undefined' && res.hasOwnProperty('results') && res['results'].length) {
                    let documents = {'': []};
                    let grades = [];
                    let results = res['results'];
                    let total_questions = 0;
                    for (let doc in results) {
                        if (results.hasOwnProperty(doc)) {
                            for (let grade in results[doc]['grades']) {
                                if (results[doc]['grades'].hasOwnProperty(grade)) {
                                    if (!(results[doc]['grades'][grade]['name'] in documents)) {
                                        documents[results[doc]['grades'][grade]['name']] = [];
                                        grades[results[doc]['grades'][grade]['order']] = results[doc]['grades'][grade]['name'];
                                    }
                                    documents[results[doc]['grades'][grade]['name']].push(results[doc])
                                }
                            }
                            total_questions += results[doc]['question_count'];
                            documents[''].push(results[doc]);
                        }
                    }
                    let docs = {
                        'documents': documents,
                        'grades': grades,
                        'total_questions': total_questions
                    };
                    self.updateTest(test.slug, {'documents': docs});
                    if (state && docs['grades'].length) {
                        state.setState({
                            documents: docs,
                        });
                    }
                }
            });
        }
    }

    viewport()
    {
        var e = window
            , a = 'inner';
        if ( !( 'innerWidth' in window ) )
        {
            a = 'client';
            e = document.documentElement || document.body;
        }
        return { width : e[ a+'Width' ] , height : e[ a+'Height' ] }
    }

    toggleSidebar(dir)
    {
        let sidebar = document.getElementById("sidebarLeft");
        if (sidebar && this.viewport().width <= 800) {
            if (dir === "left") {
                sidebar.classList.remove("open");
                sidebar.style.left = '-260px';
            } else {
                sidebar.classList.add("open");
                sidebar.style.left = '0';
            }
        }
    }

    startSwipeSidebar(e)
    {
        this.initialX = e.touches[0].clientX;
        this.initialY = e.touches[0].clientY;
    }

    swipeSidebar(e)
    {
        if (this.initialX === 0 || this.initialY === 0) {
            return;
        }
        let halfScreen = this.viewport().width / 2;
        if (e.touches.hasOwnProperty(0)) {
            let currentX = e.touches[0].clientX;
            let currentY = e.touches[0].clientY;

            let diffX = this.initialX - currentX;
            let diffY = this.initialY - currentY;

            if (Math.abs(diffX) > Math.abs(diffY)) {
                if (diffX > 0) {
                    this.toggleSidebar('left');
                } else if (diffX <= 0 && this.initialX < halfScreen) {
                    this.toggleSidebar('right');
                }
            }
        }
    }

    showAvatars()
    {
        this.setState({
            avatar_overlay_showing: true
        });
    }

    hideAvatars()
    {
        this.setState({
            avatar_overlay_showing: false
        });
    }

    showSiteTour(e, tour) {
        let videoId = tour === 'sba' ? '519100916' : '380754676';
        this.setState({
            showSiteTour: true,
            siteTourVideoId: videoId
        });
        if (e) {
            e.preventDefault();
        }
    }

    hideSiteTour() {
        this.setState({
            showSiteTour: false
        })
    }

    defaultComponent(props, Component, extraProps) {
        if (this.state.user.pk  && Object.keys(this.state.grades).length > 0) {
            let showEditButton = false;
            if ((Component === TestDashboard || Component === ChildSetup) && this.state.user.active_child >= 0) {
                showEditButton = true;
            }
            let tutoring_cta = this.state.online_tutoring_cta;
            return (
                <div>
                    <Header
                        isDescendant={this.isDescendant}
                        user={this.state.user}
                        search={this.search}
                        searchLinks={this.state.searchLinks}
                    />
                    <Hero
                        appProps={props}
                        avatars={this.avatars}
                        avatarShowing={this.showAvatars}
                        extraProps={extraProps}
                        showEditButton={showEditButton}
                        tests={this.state.tests}
                        themes={this.themes}
                        updateChild={this.updateChild}
                        updateUser={this.updateUser}
                        user={this.state.user}
                    />
                    <AvatarOverlay
                        appProps={props}
                        avatars={this.avatars}
                        avatarHide={this.hideAvatars}
                        avatarShowing={this.state.avatar_overlay_showing}
                        themes={this.themes}
                        updateChild={this.updateChild}
                        user={this.state.user}
                    />
                    <SidebarLeft
                        appProps={props}
                        child_setup={this.state.child_setup}
                        showSiteTour={this.showSiteTour}
                        user={this.state.user}
                        tests={this.state.test_pages_by_slug}
                    />
                    <CustomerSupport />
                    <div className="page content wide">
                        {(Component === ChildSetup || Component === TestDashboard) &&
                            <ChildNavigation
                                appProps={props}
                                extraProps={extraProps}
                                tests={this.state.tests}
                                updateUser={this.updateUser}
                                user={this.state.user}
                            />
                        }
                        <Component
                            appProps={props}
                            deleteDocument={this.deleteDocument}
                            doc={this.state.doc}
                            dt_config={this.state.dt_config}
                            extraProps={extraProps}
                            grades={this.state.grades}
                            grades_by_id={this.state.grades_by_id}
                            isDescendant={this.isDescendant}
                            regions={this.state.regions}
                            test_pages={this.state.test_pages}
                            test_pages_by_slug={this.state.test_pages_by_slug}
                            tests={this.state.tests}
                            tests_by_grade={this.state.tests_by_grade}
                            tests_by_id={this.state.tests_by_id}
                            toggleSidebar={this.toggleSidebar}
                            updateChild={this.updateChild}
                            updateTest={this.updateTest}
                            updateUser={this.updateUser}
                            user={this.state.user}
                        />
                        <SidebarRight toggleSidebar={this.props.toggleSidebar}
                                      tutoring_cta={tutoring_cta}/>
                    </div>
                    {window.location.pathname === '/' && this.state.user && !this.state.user.tutoring_client &&
                    <PodsModal
                        isDescendant={this.isDescendant}
                    />
                    }
                    <SiteTour show={this.state.showSiteTour} hide={this.hideSiteTour} videoId={this.state.siteTourVideoId} />
                </div>
            );
        } else {
            return <Loading />
        }
    }

    render() {
        if (this.state.isIE) {
            return (
                <div style={{padding: '100px', textAlign: 'center', fontSize: '20px'}}>
                    Sorry Internet Explorer is not supported.  Please try a different browser.
                </div>
            )
        }

        let ChildDashboard = ChildSetup;
        if (this.state.user.child_setup) {
            ChildDashboard = TestDashboard;
        }

        let Home = ParentDashboard;

        return (
            <ScrollToTop>
                <Switch>
                    <Route path="/" exact render={(props) => (
                        this.defaultComponent(props, Home, {
                            'addChild': true,
                            'editChild': false,
                            'firstChild': true,
                            'checkAddStudent': this.checkAddStudent,
                            'base_price': this.base_price,
                            'onlineClasses': this.state.onlineClasses,
                            'userClasses': this.state.userClasses,
                            'showSiteTour': this.showSiteTour,
                        })
                    )}/>
                    <Route path="/add-child" exact render={(props) => (
                        this.defaultComponent(props, ChildSetup, {
                            'addChild': true,
                            'editChild': false,
                            'firstChild': false,
                            'checkAddStudent': this.checkAddStudent,
                            'onlineClasses': this.state.onlineClasses,
                            'onlineClassCategories': this.state.onlineClassCategories,
                        })
                    )}/>
                    <Route path="/dashboard/:child_id" exact render={(props) => (
                        this.defaultComponent(
                            props,
                            ChildDashboard,
                            {
                                setPrintablesByTestPage: this.setPrintablesByTestPage,
                                setChildAssessmentsByTest: this.setChildAssessmentsByTest,
                                setPrintables: this.setPrintables,
                                addChild: false,
                                editChild: true,
                                firstChild: false,
                                deleteChild: this.deleteChild,
                                onlineClasses: this.state.onlineClasses,
                                userClasses: this.state.userClasses,
                            }
                        )
                    )}/>
                    <Route path="/dashboard/:child_id/practice/" exact render={(props) => (
                        this.defaultComponent(
                            props,
                            ChildDashboard,
                            {
                                setPrintablesByTestPage: this.setPrintablesByTestPage,
                                setChildAssessmentsByTest: this.setChildAssessmentsByTest,
                                setPrintables: this.setPrintables,
                                onlineClasses: this.state.onlineClasses,
                                userClasses: this.state.userClasses,
                            }
                        )
                    )}/>
                    <Route path="/dashboard/:child_id/practice/:skill_id" exact render={(props) => (
                        this.defaultComponent(
                            props,
                            ChildDashboard,
                            {
                                setPrintablesByTestPage: this.setPrintablesByTestPage,
                                setChildAssessmentsByTest: this.setChildAssessmentsByTest,
                                setPrintables: this.setPrintables,
                                onlineClasses: this.state.onlineClasses,
                                userClasses: this.state.userClasses,
                            }
                        )
                    )}/>
                    <Route path="/dashboard/:child_id/edit-child" exact render={(props) => (
                        this.defaultComponent(props, ChildSetup, {
                            'addChild': false,
                            'editChild': true,
                            'editChildPage': true,
                            'firstChild': false,
                            'checkAddStudent': this.checkAddStudent,
                            'deleteChild': this.deleteChild,
                            'onlineClasses': this.state.onlineClasses,
                            'onlineClassCategories': this.state.onlineClassCategories,
                        })
                    )}/>
                    <Route path="/dashboard/:child_id/document/:document" exact render={(props) => (
                        this.defaultComponent(
                            props,
                            ChildDashboard,
                            {
                                setPrintablesByTestPage: this.setPrintablesByTestPage,
                                setChildAssessmentsByTest: this.setChildAssessmentsByTest,
                                setPrintables: this.setPrintables,
                                online_tutoring_cta: this.state.online_tutoring_cta,
                                tutoring_cta: this.state.tutoring_cta,
                                onlineClasses: this.state.onlineClasses,
                                userClasses: this.state.userClasses,
                            }
                        )
                    )}/>
                    <Route path="/dashboard/:child_id/:section/" exact render={(props) => (
                        this.defaultComponent(
                            props,
                            ChildDashboard,
                            {
                                setPrintablesByTestPage: this.setPrintablesByTestPage,
                                setChildAssessmentsByTest: this.setChildAssessmentsByTest,
                                setPrintables: this.setPrintables,
                                onlineClasses: this.state.onlineClasses,
                            }
                        )
                    )}/>
                    <Route path="/enrichment" exact render={(props) => (
                        this.defaultComponent(props, Enrichment)
                    )}/>
                    <Route path="/games" exact render={(props) => (
                        this.defaultComponent(props, GamesIndex, {
                            'games': this.state.games,
                        })
                    )}/>
                    <Route path="/games/:game" exact render={(props) => (
                        this.defaultComponent(props, Games, {
                            'games': this.state.games,
                        })
                    )}/>
                    <Route path="/parent-resources" exact render={(props) => (
                        this.defaultComponent(props, ParentResources, {
                            setResources: this.setResources,
                            resources: this.state.resources,
                            showSiteTour: this.showSiteTour,
                        })
                    )}/>
                    <Route path="/site-tour" exact render={(props) => (
                        this.defaultComponent(props, SiteTour)
                    )}/>
                    <Route path="/skill-building-academy" exact render={(props) => (
                        this.defaultComponent(props, SbaIndex,
                            {
                                showSiteTour: this.showSiteTour,
                            })
                    )}/>
                    <Route path="/skill-building-academy/:program" exact render={(props) => (
                        this.defaultComponent(props, SbaProgram)
                    )}/>
                    <Route path="/test-prep" exact render={(props) => (
                        this.defaultComponent(props, TestDirectory)
                    )}/>
                    <Route path="/test-prep/states-common-core/:state" exact render={(props) => (
                        this.defaultComponent(props, StatesCommonCore)
                    )}/>
                    <Route path="/test-prep/:test" exact render={(props) => (
                        this.defaultComponent(props, HowToPrep, {
                            setPrintablesByTestPage: this.setPrintablesByTestPage,
                        })
                    )}/>
                    <Route path="/test-prep/:test/:view" exact render={(props) => (
                        this.defaultComponent(props, HowToPrep, {
                            setPrintablesByTestPage: this.setPrintablesByTestPage,
                        })
                    )}/>
                    <Route path="/tests" exact render={(props) => (
                        this.defaultComponent(props, TestDirectory)
                    )}/>
                    <Route path="/tests/:view" exact render={(props) => (
                        this.defaultComponent(props, TestDirectory)
                    )}/>
                    <Route path="/version/" render={(props) => (
                        <div>Version: {version}</div>
                    )}/>
                    <Redirect from='/apps/digital-tutor' to={'/dashboard/0'} />
                    <Route path="/" render={(props) => (
                        this.defaultComponent(props, Four04, {four04: true})
                    )}/>
                </Switch>
            </ScrollToTop>
        )
    }
}
export default withRouter(App);
