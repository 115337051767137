const hostname = window && window.location && window.location.hostname;
let website_url = '';
let api_url = '';
let members_url = '';

if (hostname === 'app.dev.testingmom.com') {
    website_url = 'https://dev.testingmom.com/';
    members_url = 'https://members.dev.testingmom.com/';
    api_url = 'https://api.dev.testingmom.com/';
} else if (hostname === 'staging.app.testingmom.com' || hostname === 'app.staging.testingmom.com') {
    website_url = 'https://staging.testingmom.com/';
    members_url = 'https://members.staging.testingmom.com/';
    api_url = 'https://api.staging.testingmom.com/';
} else {
    website_url = 'https://www.testingmom.com/';
    members_url = 'https://members.testingmom.com/';
    api_url = 'https://api.testingmom.com/';
}

export const WEBSITE_URL = website_url;
export const MEMBERS_URL = members_url;
export const API_URL = api_url;
