import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ApiFetch = require('../common/ApiFetch').ApiFetch;

class ReportedError extends Component {
    constructor(props){
        super(props);
        this.submitError = this.submitError.bind(this);
        this.close = this.close.bind(this);
    }

    submitError() {
        let re = document.getElementById('reportedError');
        let postdata = {
            'notes': document.querySelector('textarea[name=message]').value,
            'email_user': document.getElementById('email_me').checked
        };

        let endpoint = 'question/reported-error/';

        if (this.props.learnosity === true) {
            endpoint = 'learnosity-reported-error/';
            if (this.props.reference) {
                postdata['reference'] = this.props.reference;
            } else {
                postdata['reference'] = re.getAttribute('data-learnosity_ref');
            }
        } else {
            if (this.props.question_id) {
                postdata['question'] = this.props.question_id;
            } else {
                postdata['question'] = re.getAttribute('data-question_id');
            }
        }

        ApiFetch(endpoint, 'POST', postdata).then(function(res){
            let re = document.getElementById('reportedError');
            re.getElementsByClassName('form')[0].className = 'form hide';
            re.getElementsByClassName('submitted')[0].className = 'submitted';
        });
    }

    close() {
        let re = document.getElementById('reportedError');
        re.style.display = 'none';
        re.getElementsByClassName('message')[0].value = '';
    }

    render() {
        return (
            <div id={'reportedError'}>
                <div className={'container'}>
                    <div className={'close'} onClick={this.close}>
                        <FontAwesomeIcon icon={'times-circle'} />
                    </div>
                    <div className={'form'}>
                        <p>Please describe the issue with this question in a few words.</p>
                        <textarea placeholder='Message' name='message' className={'message'}/>
                        <button onClick={this.submitError}>Submit</button>
                        <label htmlFor="email_me">
                            <input type="checkbox" name="email_me" id="email_me" />&nbsp;Email me once reviewed/resolved
                        </label>
                    </div>
                    <div className={'submitted hide'}>
                        <p>Thank you! The error you found has been submitted.</p>
                        <button onClick={this.close}>Close</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReportedError;
