import React, {Component} from 'react';
import ThumbnailLink from "../common/ThumbnailLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Loading = require('../common/Loading').Loading;
const WEBSITE_URL = require('../common/Urls').WEBSITE_URL;

class HowToPrepInteractive extends Component {

    componentDidMount() {
        if (this.props.user.accesss_tp === false) {
            window.location.href = WEBSITE_URL + 'buy-now/?redirected=1';
        }
    }

    render() {
        if (this.props.tests.length === 0) {
            return <Loading />
        }

        let self = this;
        let grade_name = '';
        for (let g in this.props.grades) {
            if (this.props.grades.hasOwnProperty(g) && this.props.user.children.hasOwnProperty(this.props.user.active_child)) {
                if (this.props.grades[g].id === this.props.user.children[this.props.user.active_child].grade) {
                    grade_name = this.props.grades[g].name;
                }
            }
        }
        let i = 0;
        let apps = {};
        let sbas = {};
        let used_sbas = [];
        for (let test in this.props.tests) {
            if (this.props.tests.hasOwnProperty(test)) {
                for (let app in this.props.tests[test]['apps']) {
                    if (this.props.tests[test]['apps'].hasOwnProperty(app) && !apps.hasOwnProperty(this.props.tests[test]['apps'][app]['id'])) {
                        apps[this.props.tests[test]['apps'][app]['id']] = this.props.tests[test]['apps'][app];
                    }
                }
                for (let sba in this.props.tests[test]['sbas']) {
                    if (this.props.tests[test]['sbas'].hasOwnProperty(sba) && !sbas.hasOwnProperty(this.props.tests[test]['sbas'][sba]['id'])) {
                        sbas[this.props.tests[test]['sbas'][sba]['id']] = this.props.tests[test]['sbas'][sba];
                    }
                }
            }
        }

        return (
            <section className={"htp-section"}>
                <h2><FontAwesomeIcon icon={'gamepad'} />Interactive Practice and Games</h2>
                <p>Here you can find interactive assessments, practice, and games for your child to
                    work on. The digital tutor gives you powerful reporting to guide instruction after
                    the assessment. See your results on your child's dashboard. Or allow your student
                    to have fun and learn in our games area.</p>
                <h3 className="orange-text">Recommended for {grade_name}</h3>
                <div className="programs">
                    {
                        Object.keys(apps).map(function (app) {
                            i++;
                            let safeDisplayName = <span dangerouslySetInnerHTML={{__html: apps[app].short_title}}/>;
                            let link = apps[app].url;
                            if (apps[app].app_url !== null && self.props.user.paid_access === true) {
                                link = apps[app].app_url;
                            }
                            else if (self.props.user.paid_access === false) {
                                link = WEBSITE_URL + 'buy-now/?redirected=1';
                            }
                            if (apps[app].short_title === 'Digital Tutor') {
                                return false;
                            }
                            return <ThumbnailLink
                                key={i}
                                link={link}
                                alt={apps[app].short_title}
                                thumbnail={apps[app].thumbnail}
                                title={apps[app].title}
                                display_name={safeDisplayName}
                                description={apps[app].description}
                            />
                        })
                    }
                    {
                        Object.keys(sbas).map(function(sba){
                            i++;
                            let show = false;
                            for (let g in sbas[sba].grades) {
                                if (sbas[sba].grades.hasOwnProperty(g)) {
                                    if (self.props.user.children[self.props.user.active_child]) {
                                        if (sbas[sba].grades[g].id ===
                                            self.props.user.children[self.props.user.active_child].grade
                                        ) {
                                            show = true;
                                            used_sbas.push(sba);
                                        }
                                    } else {
                                        show = true;
                                        used_sbas.push(sba);
                                    }
                                }
                            }
                            if (show) {
                                let url = '/skill-building-academy/' + sbas[sba].url;
                                if (self.props.user.paid_access === false) {
                                    url = WEBSITE_URL + 'buy-now/?redirected=1';
                                }
                                return <ThumbnailLink
                                    key={i}
                                    link={url}
                                    alt={sbas[sba].tooltip_text}
                                    thumbnail={sbas[sba].thumbnail}
                                    title={sbas[sba].display_name}
                                    display_name={<span dangerouslySetInnerHTML={{__html: sbas[sba].program_name}}/>}
                                    description={sbas[sba].display_name}
                                />
                            } else {
                                return false;
                            }
                        })
                    }
                </div>
                {used_sbas.length !== Object.keys(sbas).length &&
                <div>
                    <h3>All Other Educational Games for {this.props.test_page.title}</h3>
                    <div className="interactive-games">
                        {
                            Object.keys(sbas).map(function (sba) {
                                i++;
                                let url = '/skill-building-academy/' + sbas[sba].url;
                                if (self.props.user.paid_access === false) {
                                    url = WEBSITE_URL + 'buy-now/?redirected=1';
                                }
                                if (!used_sbas.includes(sba)) {
                                    return <ThumbnailLink
                                        key={i}
                                        link={url}
                                        alt={sbas[sba].tooltip_text}
                                        thumbnail={sbas[sba].thumbnail}
                                        title={sbas[sba].display_name}
                                        display_name={<span
                                            dangerouslySetInnerHTML={{__html: sbas[sba].program_name}}/>}
                                        description={sbas[sba].display_name}
                                    />
                                } else {
                                    return false;
                                }
                            })
                        }
                    </div>
                </div>
                }
            </section>
        )
    }
}

export default HowToPrepInteractive;
