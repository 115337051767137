import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import YouTube from 'react-youtube';

import './Popup.css';

export default class Popup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            player: null,
            contents: ''
        };
        this.hidePopup = this.hidePopup.bind(this);
        this.youtubeReady = this.youtubeReady.bind(this);
    }

    componentDidUpdate() {
        if (this.props.contents !== '' && this.props.contents !== this.state.contents) {
            document.getElementById('popup').style.display = 'block';
            this.setState({contents: this.props.contents})
        }
    }

    youtubeReady(event) {
        this.setState({
            player: event.target
        });
    }

    hidePopup(e) {
        if (e.target.matches('#popup') ||
            e.target.matches('.close') ||
            e.target.matches('.container') ||
            e.target.closest('.close')
        ) {
            document.getElementById('popup').style.display = 'none';
            if (this.state.player) {
                this.state.player.pauseVideo();
            }
            this.setState({contents: ''});
            if (typeof this.props.hideCallback === 'function') {
                this.props.hideCallback();
            }
        }
    }

    render() {
        let className = 'popup ' + this.props.className;
        let contents = this.state.contents;
        if (typeof this.props.contents === 'string' && this.props.contents.indexOf('youtube.com')) {
            let query = this.props.contents.split('?');
            let params = {};
            if (query[1]) {
                query[1].split('&').forEach(function (param) {
                    let value = param.split('=');
                    params[value[0]] = decodeURIComponent(value[1]);
                });
                if (params.hasOwnProperty('v')) {
                    contents = <YouTube
                        videoId={params['v']}
                        containerClassName={'youtube'}
                        onReady={this.youtubeReady}
                        opts={{
                            height: null,
                            width: null,
                            playerVars: {
                                modestbranding: 1,
                                rel: 0,
                            }
                        }}
                    />;
                }
            }
        }

        return (
            <div className={className} id="popup" onClick={this.hidePopup}>
                <div className="container">
                    <div className="contents" onClick={function(){return false}}>
                        <FontAwesomeIcon icon={"times-circle"} className={"close"} onClick={this.hidePopup}/>
                        {contents}
                    </div>
                </div>
            </div>
        );
    }
}
