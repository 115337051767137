import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import 'whatwg-fetch'

import './Document.css';

const ApiFetch = require('../common/ApiFetch').ApiFetch;
const WEBSITE_URL = require('../common/Urls').WEBSITE_URL;
const Loading = require('../common/Loading').Loading;

class Document extends Component {
    constructor(props) {
        super(props);
        let docNumber = "";
        if (this.props.hasOwnProperty('appProps')) {
            docNumber = this.props.appProps.match.params.document;
        } else {
            docNumber = this.props.doc;
        }
        this.state = {
            doc: docNumber,
            title: ''
        };

        this.setContentSize = this.setContentSize.bind(this);
        this.viewport = this.viewport.bind(this);
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
        window.removeEventListener('resize', this.setContentSize, true);
    }

    componentDidMount() {
        if (this.props.user.accesss_tp === false) {
            window.location.href = WEBSITE_URL + 'buy-now/?redirected=1';
        }
        this.checkAccess(this.props);
        let self = this;
        document.getElementById('document').oncontextmenu =
            document.getElementById('document').onselectstart =
                document.getElementById('document').oncopy =
                    document.getElementById('document').oncut = function() {
            return false;
        };
        if (window.location.hostname.indexOf("testingmom.com") === -1 || !window.fetch) {
            document.getElementById('document').innerHTML = '';
        }

        let preloadHeader = document.getElementById("preload-header");
        if (this.state.doc) {
            ApiFetch('document/?id=' + this.state.doc).then(function(response) {
                if (response['results'].hasOwnProperty(0)) {
                    self.setState({
                        title: response['results'][0]['title']
                    });
                    fetch(response['results'][0]['contents'].replace('s3.amazonaws.com/', ''))
                        .then(
                            function (response) {
                                if (response.status >= 300) {
                                    return '';
                                }

                                return response.text();
                            },
                            function (error) {
                                return '';
                            }
                        ).then(function (content) {
                            if (content === '' && preloadHeader) {
                                preloadHeader.innerText = "Content Unavailable";
                                return;
                            }

                            self.timeout = window.setTimeout(function () {
                                let contentDiv = document.getElementById("content");
                                if (contentDiv) {
                                    contentDiv.innerHTML = '<div id="content-frame" />';
                                    document.getElementById("content-frame").innerHTML = content;
                                    self.setContentSize();
                                }
                            }, 200);
                            window.addEventListener('resize', self.setContentSize, true);
                        });
                } else if (preloadHeader) {
                    preloadHeader.innerText = "Content Unavailable";
                }
            });
        } else {
            preloadHeader.innerText = "Content Unavailable";
        }
    }

    viewport()
    {
        var e = window
            , a = 'inner';
        if ( !( 'innerWidth' in window ) )
        {
            a = 'client';
            e = document.documentElement || document.body;
        }
        return { width : e[ a+'Width' ] , height : e[ a+'Height' ] }
    }

    setContentSize()
    {
        let vw = this.viewport().width;
        let scale = 1;
        if (vw < 1000) {
            scale = vw / 1000;
        }

        let frame = document.getElementById('content-frame');
        if (frame) {
            frame.style.transform = 'scale(' + scale + ')';
            frame.style.height = ((1 / scale) * 100) + '%';
            frame.style.width = ((1 / scale) * 100) + '%';
        }
        let header = document.getElementById('header');
        let content = document.getElementById('content');
        if (content && header) {
            content.style.height = 'calc(100% - ' + header.offsetHeight + 'px)';
            content.style.top = header.offsetHeight + 'px';
        }
    }

    checkAccess(props) {
        if (props.user.paid_access ===  false) {
            window.location.href = WEBSITE_URL + 'buy-now/?redirected=1';
            return;
        }
    }

    render() {
        return (
            <div id="document">
                <div id="header">
                    <p className="left" onClick={this.props.history.goBack}>Go Back</p>
                    <h1 id="name">TestingMom.com - {this.state.title}</h1>
                    <div id="toolbox">
                        <p onClick={window.print}>
                            <img alt="print"
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfgCx4QCBzdeNWoAAABFklEQVQoz43RsUpcYRAF4O/f+7urRkXSqNiIkiiBPICtb6BiqYJgZSP4Br5A2F7sfIMoCFvEQhtJJ+w2IoIiplBR0F2WvTfFvS4BhXiqYc4Mc+Yc/oMAGSRK//RTnZwMBf3dmoG8RPBoR50gZIOWfTVirEvnuPZHw140a8WtjlHTbwTcq0e9Pktkht5R2KMvSo37ouVKo0uUjL4uxOLyD1Vp/hNSi6rKMml0Zt+kQy/mlD34pYVTNx7UNaJLqyqeDRtWkQp6RAOObXrSjEH2om3db1FZyYwNn0w5d6cTCvcSsxI1h07MO/dTZlcnF/nqXdMFqEgs2FbLrc4H2u4tmFCS+WbaloMipm4WU5b0Fx4cqcmCD+IvX79LPZdmBBcAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTYtMTEtMzBUMTY6MDg6MjgtMDU6MDBX58G/AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE2LTExLTMwVDE2OjA4OjI4LTA1OjAwJrp5AwAAAABJRU5ErkJggg==" />
                                <span className="print-button">Print</span>
                        </p>
                    </div>
                </div>

                <div id="content">
                    <div id="preload">
                        <Loading />
                        <p id="preload-copyright">Copyright &copy; TestingMom.com. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Document);
