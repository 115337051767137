import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Printables from '../common/Printables';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ApiFetch = require('../common/ApiFetch').ApiFetch;
const Loading = require('../common/Loading').Loading;
const WEBSITE_URL = require('../common/Urls').WEBSITE_URL;

class HowToPrepPrintable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current_grade: '',
            documents: {},
            grades: [],
            total_questions: 0,
            loaded: false,
            sections: []
        };
        this.changeGrade = this.changeGrade.bind(this);
        this.togglePrintableTest = this.togglePrintableTest.bind(this);
        this.setGrade = true;
    }

    componentDidMount()
    {
        let self = this;

        if (this.props.user.accesss_tp === false) {
            window.location.href = WEBSITE_URL + 'buy-now/?redirected=1';
            return;
        }
        this.props.extraProps.setPrintablesByTestPage(this.props.test_page);
        if (this.props.test_page && this.props.test_page.display_mode === 'section') {
            ApiFetch('test/page/sections?page=' + this.props.test_page.slug).then(function(response) {
                if (response.hasOwnProperty('results')) {
                    self.setState({
                        sections: response['results']
                    });
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.test_page !== prevProps.test_page) {
            this.props.extraProps.setPrintablesByTestPage(this.props.test_page);
        }
    }

    componentWillReceiveProps(props, prevProps) {
        if (props.user.children.length > 0 &&
            Object.keys(props.grades).length > 0 &&
            this.setGrade
        ) {
            this.setGrade = false;
            if (props.user.active_child >= 0 && props.user.children.length > 0 &&
                props.user.children[props.user.active_child].grade
            ) {
                if (
                    props.grades.hasOwnProperty(props.user.children[props.user.active_child]) &&
                    props.user.children[props.user.active_child].grade
                ) {
                    this.setState({
                        'current_grade': props.grades[props.user.children[props.user.active_child].grade]['name']
                    });
                }
            }
        }
    }

    togglePrintableTest(el) {
        let table = el.currentTarget.parentElement;
        if (table.className === 'printable-test open') {
            table.className = 'printable-test closed';
        } else {
            table.className = 'printable-test open';
        }
    }

    changeGrade(e)
    {
        this.setState({
            current_grade: e.target.value
        });
    }

    render() {
        let self = this;
        let has_docs = 0;
        let total_tests = 0;
        let test_grades = [];
        for (let t in this.props.test_page.tests) {
            let test = this.props.tests_by_id[this.props.test_page.tests[t]['id']];
            if (this.props.test_page.tests[t].show_practice_questions && !test.title.startsWith('Video')) {
                total_tests++;
            }
            if (test !== undefined) {
                if (test.hasOwnProperty('documents') &&
                    test['documents'].hasOwnProperty('documents') &&
                    Object.keys(test['documents']['documents']).length > 0
                ) {
                    has_docs++;
                    for (let g in test['documents']['documents']) {
                        if (g !== '') test_grades.push(g);
                    }
                }
            }
        }
        let grades = JSON.parse(JSON.stringify(this.props.grades));
        for (let grade in grades) {
            if (!test_grades.find(function(g) {
                return g === grades[grade].name;
            })) {
                delete grades[grade];
            }
        }
        if (total_tests !== has_docs) {
            return <div style={{position: 'relative', height: '300px'}}><Loading /></div>
        } else {
            let categories = false;
            if (this.props.test_page.display_mode.indexOf('category') !== -1) {
                categories = true;
            }
            let i = 0;
            let test_number = 0;
            let firstDoc = true;
            return (
                <section className={"htp-section printables"}>
                    <div>
                        <h2><FontAwesomeIcon icon={"print"}/>Printable Practice</h2>
                        <div dangerouslySetInnerHTML={{__html: this.props.test_page['top_text']}}/>
                        {Object.keys(grades).length > 1 &&
                        <div className="resources">
                            <div className="line"/>
                            <div className="left">
                                <p>Select a grade from the drop down to see associated resources.</p>
                                <div className="styled-select">
                                    <select name="grades" id="resources" defaultValue={self.state.current_grade}
                                            onChange={self.changeGrade}>
                                        <option value={''}>All Grade Resouces</option>
                                        {Object.keys(grades).map(function (g) {
                                            i++;
                                            return <option key={i}
                                                           value={grades[g].name}>{grades[g].name} Resources</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <p className="right goToAll" onClick={function () {
                                self.setState({current_grade: ''});
                                document.getElementById('resources').value = '';
                            }}>
                                Go to all Grade Level Resources</p>
                            <div className="clear"/>
                        </div>
                        }
                        {this.props.test_page.display_mode === 'section' &&
                            this.state.sections.length > 0 &&
                            this.state.sections.map(function (section) {
                            let documents = {'documents': {}};
                            documents['documents'][self.state.current_grade] = section.documents;
                            test_number++;
                            return (
                                <div key={section.id} className={'section'}>
                                    <h2 className="header-test">Practice Questions for {section.title}</h2>
                                    <div dangerouslySetInnerHTML={{__html: section.intro}}/>
                                    {section.documents.length > 0 &&
                                    <div>
                                        {section.document_title !== '' &&
                                        <h4 className="header-category"
                                            dangerouslySetInnerHTML={{__html: section.document_title}}/>
                                        }
                                        <Printables
                                            active_test={self.props.test_page}
                                            appProps={self.props.appProps}
                                            child={self.props.user.children[self.props.user.active_child]}
                                            current_grade={self.state.current_grade}
                                            deleteDocument={self.props.deleteDocument}
                                            documents={documents}
                                            page={'how-to-prep'}
                                            updateChild={self.props.updateChild}
                                            updateUser={self.props.updateUser}
                                            user={self.props.user}
                                            test_number={test_number}
                                        />
                                    </div>
                                    }
                                    {section.outro !== '' && <div dangerouslySetInnerHTML={{__html: section.outro}}/>}
                                </div>

                            )
                        })}
                        {this.props.test_page.display_mode === 'section' &&
                            this.state.sections.length > 0 &&
                        <div style={{position: 'relative', height: '300px'}}><Loading/></div>}

                        {this.props.test_page.display_mode !== 'section' && this.props.test_page.tests.map(function (t) {
                            let test = self.props.tests_by_id[t['id']];
                            if (t.show_practice_questions) {
                                if (test.documents) {
                                    test_number++;
                                    let testClass = 'open';
                                    let caret = 'caret-up';
                                    if (!firstDoc) {
                                        testClass = 'closed';
                                        caret = 'caret-down';
                                    }
                                    firstDoc = false;
                                    return (
                                        <div key={t['id']} className={"printable-test " + testClass}>
                                            <h3 onClick={self.togglePrintableTest}>
                                                { test.title } - { test.documents.total_questions } Questions
                                                <FontAwesomeIcon className={"caret test-name"}
                                                                 icon={caret}/>
                                            </h3>
                                            <Printables
                                                active_test={test}
                                                appProps={self.props.appProps}
                                                categories={categories}
                                                child={self.props.user.children[self.props.user.active_child]}
                                                current_grade={self.state.current_grade}
                                                deleteDocument={self.props.deleteDocument}
                                                documents={test.documents}
                                                page={'how-to-prep'}
                                                updateChild={self.props.updateChild}
                                                updateUser={self.props.updateUser}
                                                user={self.props.user}
                                                test_number={test_number}
                                            />
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={t['id']}>
                                            <h3 dangerouslySetInnerHTML={
                                                {__html: test.title}
                                            }/>
                                            <div dangerouslySetInnerHTML={{'__html': t['intro'] }}/>
                                        </div>
                                    );
                                }
                            } else {
                                return false;
                            }
                        })
                        }
                        <div dangerouslySetInnerHTML={{__html: this.props.test_page['bottom_text']}}/>
                    </div>
                </section>
            )
        }
    }
}

export default withRouter(HowToPrepPrintable);
