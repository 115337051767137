import React, {Component} from 'react';

import Link from '../common/Link';
import './ParentResources.css'

const ApiFetch = require('../common/ApiFetch').ApiFetch;
const Loading = require('../common/Loading').Loading;

class ParentResources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popUpContents: '',
            popUpClass: ''
        };
    }

    componentDidMount() {
        let self = this;
        if (this.props.extraProps.resources.length === 0) {
            ApiFetch('parent-resources-category/', 'GET').then(function(response) {
                if (response.hasOwnProperty('results')) {
                    self.props.extraProps.setResources(response['results']);
                }
            });
        }
    }

    render() {
        let self = this;
        let i = 0;
        return (
            <div>
                <div id="parent-resources" className="wrapper">
                    <div className="dashboard">
                        <h1>Parent Resources</h1>
                        <p>We have a multitude of preparation resources for you and your child to tackle but you may be
                            wondering, where do I begin? We have conveniently assembled a number of resources for you and
                            your child to review and practice below.</p>
                        {this.props.extraProps.resources.map(function(category){
                            i++;
                            return (
                                <div className="resources" key={i}>
                                    <h2>{category.name}</h2>
                                    <div className="resource-cat">
                                        {category.resources.map(function(resource){
                                            i++;
                                            if (resource.title === "Site Orientation") {
                                                return (
                                                    <button className={"pr-thumb"} key={i}
                                                       onClick={function(){return self.props.extraProps.showSiteTour();}}>
                                                        <img src={"//cdn.testingmom.com" + resource.image}
                                                         alt={resource.title} />
                                                         <span className="text">
                                                             <span className="title">{resource.title}</span>
                                                         </span>
                                                    </button>
                                                )
                                            }
                                            return (
                                                <Link
                                                    to={resource.url}
                                                    className={"pr-thumb"}
                                                    title={resource.description}
                                                    key={i}
                                                >
                                                    <img src={"//cdn.testingmom.com" + resource.image}
                                                         alt={resource.title} />
                                                     <span className="text">
                                                         <span className="title">{resource.title}</span>
                                                     </span>
                                                </Link>
                                            )
                                        })}
                                    </div>
                                    <div className="clear"/>
                                </div>
                            );
                        })}
                        {this.props.extraProps.resources.length === 0 && <Loading />}
                    </div>
                </div>
            </div>
        )
    }
}

export default ParentResources;
