import React, {Component} from 'react';
import {Link} from 'react-router-dom';

export class ChildNavigation extends Component {
    updateSelectedTest(key)
    {
        let data = {
            active_test: this.props.tests[key]
        };
        if (
            this.props.appProps.location.pathname.indexOf('test-prep') !== -1 &&
            typeof this.props.appProps.match.params.test !== 'undefined' &&
            this.props.appProps.match.params.test !==  this.props.tests[key]['slug']
        ) {
            let view = this.props.appProps.match.params.view;
            let url = '/test-prep/' + this.props.tests[key]['slug'];
            if (view) {
                url += '/' + view;
            }
            this.props.appProps.history.push(url);
        } else if (window.location.pathname.indexOf('test-prep') === -1 &&
                window.location.pathname.indexOf('dashboard') === -1
        ) {
            this.props.appProps.history.push('/dashboard/' + this.props.user.active_child);
        } else if (window.location.pathname.indexOf('edit-child') !== -1) {
            this.props.appProps.history.push(window.location.pathname.replace('/edit-child', ''));
        }

        this.props.updateUser(data);
    }

    render() {
        let self = this;
        let tests = [];
        if (this.props.user.children.length && this.props.user.active_child >= 0) {
            tests = this.props.user.children[this.props.user.active_child].tests;
            tests.sort(function (a, b) {
                if(a.test < b.test) { return -1; }
                if(a.test > b.test) { return 1; }
                return 0;
            })
        }
        let i = 0;
        let active_test = this.props.user.active_test;
        if (!active_test) {
            for (let key in tests) {
                if (tests[key]['primary']) {
                    active_test = tests[key]['test'];
                }
            }
        }
        return (
            <div className="page-header child-navigation">
                {self.props.tests &&
                    tests.map(function(test){
                        i++;
                        if (self.props.tests.hasOwnProperty(test['test'])) {
                            let className = (test['test'] === active_test['slug']) ? 'active' : '';
                            return (
                                <p key={i}
                                   onClick={function(){self.updateSelectedTest(test['test'])}}
                                   className={className}
                                   data-key={i}
                                >
                                <span dangerouslySetInnerHTML={{__html: self.props.tests[test['test']].short_title}} />
                                </p>
                            );
                        } else {
                            return (<p key={i}/>);
                        }
                    })
                }
                {this.props.user.child_setup === true && !this.props.extraProps.addChild &&
                    (!this.props.extraProps.editChild || (
                        this.props.user.active_child >= 0 &&
                        this.props.user.children[this.props.user.active_child].tests.length > 0
                    )) && !this.props.extraProps.editChildPage &&
                <Link to={"/dashboard/" + this.props.user.active_child + "/edit-child"}>Edit Profile</Link>
                }
            </div>
        );
    }
}

export default ChildNavigation;
