const moment = require('moment-timezone');

export default function getSeason(week) {
    if (!week) {
        week = moment().week();
    }
    if (week <= 9 || week >= 49)
    {
        return "Winter";
    } else if (week >= 10 && week <= 21)
    {
        return "Spring";
    } else if (week >= 22 && week <= 36)
    {
        return "Summer";
    } else {
        return "Fall";
    }
}
