import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class AvatarOverlay extends Component {

    constructor(props)
    {
        super(props);
        let avatar = 'logo.png';
        let dashboard_theme = 'bnnr_turqBooksClouds_tile';
        if (this.props.user.children.length && this.props.user.children.hasOwnProperty(this.props.user.active_child)) {
            let children = this.props.user.children;
            let child = children[this.props.user.active_child];
            avatar = child.avatar;
            if (child.dashboard_theme !== null) {
                dashboard_theme = child.dashboard_theme;
            }
        }
        this.state = {
            avatar: avatar,
            dashboard_theme: dashboard_theme,
        };

        this.chooseAvatar = this.chooseAvatar.bind(this);
        this.chooseTheme = this.chooseTheme.bind(this);
        this.setThemes = this.setThemes.bind(this);
        this.togglePopups = this.togglePopups.bind(this);
    }

    chooseAvatar(e)
    {
        this.setState({
            avatar: e.target.getAttribute('value'),
        });
    }

    chooseTheme(e)
    {
        this.setState({
            dashboard_theme: e.target.getAttribute('value')
        });
    }

    setThemes()
    {
        let children = this.props.user.children;
        let child = children[this.props.user.active_child];
        this.props.updateChild(child.id, {
            avatar: this.state.avatar,
            dashboard_theme: this.state.dashboard_theme
            }
        );
        this.setState({
            avatarShowing: false
        }, this.props.avatarHide);
    }

    togglePopups()
    {
        let avatarPopup = document.getElementById('avatar-popup');
        let themePopup = document.getElementById('theme-popup');
        let avatarDisp = avatarPopup.currentStyle ? avatarPopup.currentStyle.display :
            getComputedStyle(avatarPopup, null).display;
        let themeDisp = themePopup.currentStyle ? themePopup.currentStyle.display :
            getComputedStyle(themePopup, null).display;
        if (themeDisp === 'none') {
            themePopup.style.display = 'block';
            avatarPopup.style.display = 'none';
        } else if (avatarDisp === 'none') {
            avatarPopup.style.display = 'block';
            themePopup.style.display = 'none';
        }
    }

    render() {
        let self = this;
        return (
            <div className={'highz'}>
            {this.props.avatarShowing &&
                <div id={"avatar-overlay"}>
                    <div id={"avatar-popup"} className="popup">
                        <FontAwesomeIcon icon={"times"} className={"close"} onClick={this.props.avatarHide} />
                        <h2>Edit Profile Theme</h2>
                        <p className={"switch-popup active"}>Pick an Avatar</p>
                        <p className={"switch-popup"} onClick={this.togglePopups}>Pick a Background</p>
                        <ul className={"avatar-select"}>
                            {this.props.avatars.map(function(avatar) {
                                let active = (self.state.avatar === avatar) ? 'active' : '';
                                return <li className={"avatar " + active} onClick={self.chooseAvatar} value={avatar}>
                                    <img src={'/img/avatars/' + avatar + '.png'} alt={""}/>
                                </li>
                            })}
                            <div className={"clear"} />
                        </ul>
                        <div className={"buttons"}>
                            <button className={"avatar-submit"} onClick={self.setThemes}>Save Theme</button>
                        </div>
                    </div>
                    <div id={"theme-popup"} className="popup hide">
                        <FontAwesomeIcon icon={"times"} className={"close"} onClick={this.props.avatarHide} />
                        <h2>Edit Profile Theme</h2>
                        <p className={"switch-popup"} onClick={this.togglePopups}>Pick a Profile Avatar</p>
                        <p className={"switch-popup active"}>Pick a Background</p>
                        <ul className={"avatar-select"}>
                            {this.props.themes.map(function(theme) {
                                let active = (self.state.dashboard_theme === theme) ? 'active' : '';
                                return <li className={"avatar " + active} onClick={self.chooseTheme} value={theme}
                                style={{backgroundImage: 'url(/img/themes/'+ theme +'.png)'}} />
                            })}
                        </ul>
                        <div className={"buttons"}>
                            <button className={"theme-submit"} onClick={self.setThemes}>Save Theme</button>
                        </div>
                    </div>
                </div>
            }
            </div>
        );
    }
}

export default AvatarOverlay;
