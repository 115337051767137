import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function AddTestButton(props) {
    if (props.active === 'active') {
        return (
            <button className={'checkbox ' + props.active + " " + props.test_id} onClick={props.clickCheck}>
               <FontAwesomeIcon icon={["far", "square"]} />
                <span className={"test-title"} dangerouslySetInnerHTML={{__html: props.title}}/>
            </button>
        )
    } else if (props.active !== 'active' && props.primary === props.test_id) {
        return (
            <div>
                <button className={'checkbox ' + props.test_id} onClick={props.clickCheck}>
                    <FontAwesomeIcon icon={["far", "check-square"]} />
                    <span className={"test-title"} dangerouslySetInnerHTML={{__html: props.title}}/>
                </button>
                <button className={'primaryBtn' + props.active + " " + props.test_id} onClick={props.clickStar}>
                    <FontAwesomeIcon icon={["fas", "star"]} />
                </button>
            </div>
        )
    } else {
        return (
            <div>
                <button className={'checkbox ' + props.test_id} onClick={props.clickCheck}>
                    <FontAwesomeIcon icon={["far", "check-square"]} />
                    <span className={"test-title"} dangerouslySetInnerHTML={{__html: props.title}}/>
                </button>
                <button className={'primaryBtn' + props.active + " " + props.test_id} onClick={props.clickStar}>
                    <FontAwesomeIcon icon={["far", "star"]} />
                </button>
            </div>
        )
    }
}

export function AddCategoryButton(props) {
    if (props.active) {
        return (
            <button className={'checkbox active ' + props.cat_id}  onClick={props.clickCheck}>
               <FontAwesomeIcon icon={["far", "check-square"]} />
                <span className={"test-title"} dangerouslySetInnerHTML={{__html: props.title}}/>
            </button>
        )
    } else {
        return (
            <div>
                <button className={'checkbox ' + props.cat_id}  onClick={props.clickCheck}>
                    <FontAwesomeIcon icon={["far", "square"]} />
                    <span className={"test-title"} dangerouslySetInnerHTML={{__html: props.title}}/>
                </button>
            </div>
        )
    }
}

AddTestButton.propTypes = {
    active: PropTypes.string,
    clickCheck: PropTypes.func.isRequired,
    clickStar: PropTypes.func.isRequired,
    primary: PropTypes.string,
    test_id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

AddCategoryButton.propTypes = {
    active: PropTypes.bool,
    clickCheck: PropTypes.func,
    cat_id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
};
