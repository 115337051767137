import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class SidebarRight extends Component {
    componentDidMount() {
        window.addEventListener('scroll', function(){
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            let sidebar = document.getElementById('sidebarRight');
            if (sidebar) {
                if (scrollTop > 250) {
                    sidebar.style.position = 'fixed';
                    sidebar.style.top = 0;
                    sidebar.style.right = 0;
                } else {
                    sidebar.style.position = 'absolute';
                    sidebar.style.top = '55px';
                    sidebar.style.right = '-210px';
                }
            }
        });
    }

    render() {
        return (
            <div>
                <div id="sidebarRight">
                    <div className="sidebar-section">
                        <h3 style={{paddingBottom: '3px'}}>
                            <FontAwesomeIcon icon="book" style={{margin: '2px 5px 2px 2px'}}/>
                            Tutoring
                        </h3>
                        <div dangerouslySetInnerHTML={{__html: this.props.tutoring_cta}}/>
                        <div className="sidebar-contact">
                            <p>Call or Email Us:</p>
                            <a href="tel:877-609-6203">877-609-6203</a>
                            <a href="mailto:online.tutoring@testingmom.com">online.tutoring@testingmom.com</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default SidebarRight;