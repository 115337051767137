import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './PodsModal.css'
const ApiFetch = require('../common/ApiFetch').ApiFetch;

export default class PodsModal extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            show_pods_modal: false,
            show_tutoring: 1,
            banner: {}
        }
        this.close = this.close.bind(this);
        this.setPodsModalDimensions = this.setPodsModalDimensions.bind(this);
    }

    componentDidMount() {
        let self = this;
        ApiFetch('banner?status=1', 'GET').then(function (response) {
            if (response && response.hasOwnProperty('results')) {
                let show_pods_modal = self.getCookie('banner');
                if (!show_pods_modal || parseInt(show_pods_modal) === 5) {
                    self.setCookie('banner', 1, 30);
                    var banner = Math.floor(Math.random() * response.results.length);

                    self.setState(
                        {
                            banner: response.results[banner]
                        },
                        self.setPodsModalDimensions
                    );
                    let pm = document.getElementById('pods-modal');
                    window.addEventListener('resize', self.setPodsModalDimensions);
                    pm.querySelector('a').addEventListener('click', function() {
                        window.dataLayer.push({
                            'dashboard_banner_click': response.results[banner].name,
                            'link': response.results[banner].link
                        });
                    });
                    pm.style.display = 'block';
                } else {
                    self.setCookie('banner', parseInt(show_pods_modal) + 1, 30);
                }
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setPodsModalDimensions);
    }

    viewport()
    {
        var e = window
            , a = 'inner';
        if ( !( 'innerWidth' in window ) )
        {
            a = 'client';
            e = document.documentElement || document.body;
        }
        return { width : e[ a+'Width' ] , height : e[ a+'Height' ] }
    }

    setPodsModalDimensions() {
        let pm = document.getElementById('pods-modal');
        if (pm && this.state.banner) {
            let vw = this.viewport();
            let pmc = pm.querySelector('.pod-content');
            if (!pmc) {
                return;
            }
            let width;
            let height;
            let img_w;
            let img_h;
            let mob = pmc.querySelector('.mob')
            let desk = pmc.querySelector('.desk');
            if (!desk || !mob) {
                return;
            }
            if (vw.width > 600) {
                desk.style.display = 'block';
                mob.style.display = 'none';
                width = this.state.banner.width;
                height = this.state.banner.height;
            } else {
                desk.style.display = 'none';
                mob.style.display = 'block';
                width = this.state.banner.m_width;
                height = this.state.banner.m_height;
            }
            if (vw.width > width + 50 && vw.height > height + 50) {
                img_w = width;
                img_h = height;
            } else {
                // Get window ratio and compare to image ratio
                let img_ratio = width / height;
                let win_ratio = vw.width / vw.height;
                if (img_ratio > win_ratio) {
                    img_w = vw.width - 50;
                    img_h = img_w * (1 / img_ratio);
                } else {
                    img_h = vw.height - 50;
                    img_w = img_h * img_ratio;
                }
            }
            pmc.style.width = img_w + 'px';
            pmc.style.height = img_h + 'px';
            pmc.style.marginLeft = '-' + img_w / 2 + 'px';
            pmc.style.marginTop = '-' + img_h / 2 + 'px';
        }
    }

    getCookie(name) {
        let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }

    setCookie(name, value, days) {
        let d = new Date();
        d.setTime(d.getTime() + 24*60*60*1000*days);
        document.cookie = name + "=" + value + ";path=/;domain=.testingmom.com;expires=" + d.toGMTString();
    }

    close(e)
    {
        let pods_modal = document.getElementById('pods-modal');
        let close = pods_modal.querySelector('.close');
        if (!this.props.isDescendant(pods_modal, e.target) || this.props.isDescendant(close, e.target)) {
            pods_modal.style.display = 'none';
            this.setState({
                show_pods_modal: false
            });
        }
    }

    render()
    {
        return (
            <div id="pods-modal" onClick={this.close}>
                <div className="pod-content">
                    <div className="desk">
                        {this.state.banner &&
                        <a href={this.state.banner.link}>
                            <img src={ this.state.banner.image}
                                 alt={ this.state.banner.alt_text}/>
                        </a>
                        }
                    </div>
                    <div className="mob">
                        {this.state.banner &&
                        <a href={this.state.banner.link}>
                            <img src={ this.state.banner.m_image}
                                 alt={ this.state.banner.alt_text}/>
                        </a>
                        }
                    </div>
                    <div className="close" onClick={this.close}><FontAwesomeIcon icon={'times-circle'}/></div>
                </div>
            </div>
        )
    }
}
