import React, {Component} from 'react';
import Link from '../common/Link';
import Printables from '../common/Printables';
import { NavLink } from "react-router-dom";
import { LineChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { confirmAlert } from 'react-confirm-alert';

import './TestDashboard.css'
import ThumbnailLink from "../common/ThumbnailLink";
import Lessons from "../common/Lessons";
import Assessment from "../assessment/Assessment";
import ReportedError from '../assessment/ReportedError';
import SkillResults from "../assessment/SkillResults";
import FreePractice from "../assessment/FreePractice";
import Document from "../test-prep/Document";
import LearningPods from "../common/LearningPods";
import TutoringModal from "./TutoringModal";

const moment = require('moment-timezone');
const ApiFetch = require('../common/ApiFetch').ApiFetch;
const Loading = require('../common/Loading').Loading;
const Question = require('../assessment/Question').Question;
const SetAnswerArea = require('../assessment/Question').SetAnswerArea;
const WEBSITE_URL = require('../common/Urls').WEBSITE_URL;

class TestDashboard extends Component {
    constructor(props) {
        super(props);
        let documents = {};
        if (this.props.user && this.props.user.active_test) {
            if (this.props.user.active_test.hasOwnProperty('documents') &&
                this.props.user.active_test['documents'].hasOwnProperty('documents')) {
                documents = this.props.user.active_test['documents']['documents'];
            }
        }
        this.state = {
            active_child_id: -1,
            active_test_id: 0,
            apps: {},
            current_grade: '',
            current_test: {},
            chartKey: 1,
            documents: documents,
            grades: [],
            high_score_key: -1,
            lessons: {},
            learnosity_items: [],
            learnosity_set: false,
            learnosityResultsKey: 0,
            loaded: false,
            most_correct_questions: 0,
            number_of_questions: 10,
            questions: [],
            question_results: {},
            sbas: {},
            selected_skill: null,
            showFreePractice: false,
            showResults: false,
            showTutoringModal: false,
            skills: {},
            test_page: {},
            tests: [],
            total_questions: 0,
            use_audio: true,
        };

        this.audio_players = {};
        this.itemsApp = null;
        this.skills_set = false;

        this.changeCurrentGrade = this.changeCurrentGrade.bind(this);
        this.changeCurrentTest = this.changeCurrentTest.bind(this);
        this.goToPractice = this.goToPractice.bind(this);
        this.handleUseAudio = this.handleUseAudio.bind(this);
        this.hideResults = this.hideResults.bind(this);
        this.hideQuestions = this.hideQuestions.bind(this);
        this.nextQuestion = this.nextQuestion.bind(this);
        this.prevQuestion =this.prevQuestion.bind(this);
        this.resetReportedError = this.resetReportedError.bind(this);
        this.setSkills = this.setSkills.bind(this);
        this.setTest = this.setTest.bind(this);
        this.showFreeQuickPractice = this.showFreeQuickPractice.bind(this);
        this.showQuestions = this.showQuestions.bind(this);
        this.showResults = this.showResults.bind(this);
        this.togglePrintableTest = this.togglePrintableTest.bind(this);
        this.updateNumberOfQuestions = this.updateNumberOfQuestions.bind(this);
        this.setActiveUser();
        let self = this;
        window.addEventListener('orientationchange', function(){
            SetAnswerArea('questions-results')
            self.setState({chartKey: window.innerWidth})
        });
        window.addEventListener('resize', function(){
            SetAnswerArea('questions-results')
            self.setState({chartKey: window.innerWidth})
        });
    }

    componentDidMount()
    {
        this.setTest(this.props);
        if (
            this.props.user.children[this.props.user.active_child].grade !== null &&
            this.props.grades.hasOwnProperty(this.props.user.children[this.props.user.active_child].grade)
        ) {
            if (this.state.current_grade === '') {
                this.setState({
                    'current_grade': this.props.grades[this.props.user.children[this.props.user.active_child].grade]['name']
                });
            }
        }
        if (window.location.pathname.indexOf('/practice') !== -1 && !this.props.user.paid_access) {
            window.location.href = WEBSITE_URL + 'buy-now/?redirected=1';
        }
    }

    componentDidUpdate() {
        this.setActiveUser();
        this.setTest(this.props);
    }

    updateNumberOfQuestions(num) {
        this.setState({
            number_of_questions: num
        })
    }

    setTest(props) {
        let self = this;
        if (!this.props.user.children[this.props.user.active_child].tests.length) {
            this.props.appProps.history.replace('/dashboard/' + this.props.user.active_child + '/edit-child');
        }
        if (
            (
                props.user.active_test &&
                props.user.active_test.hasOwnProperty('id') &&
                Object.keys(props.test_pages_by_slug).length > 0 &&
                (
                props.user.active_test['id'] !== this.state.active_test_id
                ||
                props.user.active_child !== this.state.active_child_id
                )
            )
        ) {
            let test_page = props.test_pages_by_slug[props.user.active_test.slug];
            self.props.extraProps.setPrintablesByTestPage(test_page);
            let tests = [];
            let apps = {};
            let sbas = {};
            let lessons = {};
            if (test_page.hasOwnProperty('tests') && test_page['tests'].length > 0) {
                for (let test in test_page['tests']) {
                    if (test_page['tests'].hasOwnProperty(test) &&
                        this.props.tests_by_id.hasOwnProperty(test_page['tests'][test]['id'])
                    ) {
                        tests.push(this.props.tests_by_id[test_page['tests'][test]['id']]);
                        for (let app in this.props.tests_by_id[test_page['tests'][test]['id']]['apps']) {
                            if (this.props.tests_by_id[test_page['tests'][test]['id']]['apps'].hasOwnProperty(app) &&
                                !apps.hasOwnProperty(app)) {
                                apps[app] = this.props.tests_by_id[test_page['tests'][test]['id']]['apps'][app];
                            }
                        }
                        for (let sba in this.props.tests_by_id[test_page['tests'][test]['id']]['sbas']) {
                            if (this.props.tests_by_id[test_page['tests'][test]['id']]['sbas'].hasOwnProperty(sba)
                                && !sbas.hasOwnProperty(sba)) {
                                sbas[sba] = this.props.tests_by_id[test_page['tests'][test]['id']]['sbas'][sba];
                            }
                        }
                        lessons[test_page['tests'][test]['id']] = this.props.tests_by_id[test_page['tests'][test]['id']]['lessons'];
                    }
                }
            } else {
                if (this.props.tests_by_id.hasOwnProperty(props.user.active_test['id'])) {
                    tests.push(this.props.tests_by_id[props.user.active_test['id']]);
                    for (let app in this.props.tests_by_id[props.user.active_test['id']]['apps']) {
                        if (this.props.tests_by_id[props.user.active_test['id']]['apps'].hasOwnProperty(app) &&
                            !apps.hasOwnProperty(app)) {
                            apps[app] = this.props.tests_by_id[props.user.active_test['id']]['apps'][app];
                        }
                    }
                    for (let sba in this.props.tests_by_id[props.user.active_test['id']]['sbas']) {
                        if (this.props.tests_by_id[props.user.active_test['id']]['sbas'].hasOwnProperty(sba)
                            && !sbas.hasOwnProperty(sba)) {
                            sbas[sba] = this.props.tests_by_id[props.user.active_test['id']]['sbas'][sba];
                        }
                    }
                    lessons[props.user.active_test['id']] = this.props.tests_by_id[props.user.active_test['id']]['lessons'];
                }
            }

            this.setState({
                active_child_id: props.user.active_child,
                active_test_id: props.user.active_test['id'],
                active_test_slug: props.user.active_test['slug'],
                current_test: {},
                apps: apps,
                high_score_key: -1,
                learnosity_set: false,
                learnosity_items: [],
                lessons: lessons,
                sbas: sbas,
                selected_skill: null,
                tests: tests,
                test_page: test_page,
            }, self.setSkills);
        }
    }

    setActiveUser() {
        if (typeof this.props.appProps.match.params.child_id !== 'undefined' &&
            parseInt(this.props.appProps.match.params.child_id) !== parseInt(this.props.user.active_child)) {
            let active_test = null;
            let child = this.props.user.children[this.props.appProps.match.params.child_id];
            if (child) {
                for (let t in child['tests']) {
                    if (child['tests'].hasOwnProperty(t) &&
                        child['tests'][t]['primary'] &&
                        this.props.tests.hasOwnProperty(
                            child['tests'][t]['test']
                        )
                    ) {
                        active_test = this.props.tests[
                            child['tests'][t]['test']
                            ];
                    }
                }
                this.setState({current_test: {}});
                let data = {
                    active_child: this.props.appProps.match.params.child_id
                };
                if (active_test) {
                    data['active_test'] = active_test;
                }
                child['active'] = true;
                this.props.updateChild(child['id'], child);
                this.props.updateUser(
                    data,
                    this.props.extraProps.setChildAssessmentsByTest
                );
            } else {
                window.location.href = '/';
            }
        }
    }

    goToPractice() {
        this.props.appProps.history.push('/dashboard/' + this.props.user.active_child + '/practice/');
    }

    showResults(key) {
        this.current_skill = 0;
        this.current_key = key;
        this.setState({
            showResults: true
        });
        if (this.state.skills[key].hasOwnProperty('question_ids') && this.state.skills[key].question_ids.length > 0) {
            this.showQuestions();
        }
    }

    showQuestions()
    {
        let question_id = this.state.skills[this.current_key].question_ids[this.current_skill];
        let questions = document.getElementsByClassName('ques_id' + question_id);
        if (questions.length > 0) {
            document.getElementById('assessment-results').style.display = 'block';
            questions[0].style.display = 'block';
            let arrows = document.getElementById('questions-results').getElementsByClassName('arrow');
            if (this.state.skills[this.current_key].question_ids.length > 1) {
                for (let a = 0; a < arrows.length; a++) {
                    arrows[a].style.display = 'block';
                }
            } else {
                for (let a = 0; a < arrows.length; a++) {
                    arrows[a].style.display = 'none';
                }
            }
        }
        SetAnswerArea('questions-results', true);
    }

    hideResults() {
        let questionsDiv = document.getElementById('assessment-results');
        questionsDiv.style.display = 'none';
        this.setState({
            showResults: false
        });
        let key = this.current_key;
        if (this.state.learnosity_items.length) {
            if (this.state.skills.hasOwnProperty(key) &&
                this.state.skills[key].hasOwnProperty('question_ids') &&
                this.state.skills[key].question_ids.length > 0) {
                let question_id = this.state.skills[key]['question_ids'][this.current_skill];
                for (let ref in this.state.question_results) {
                    if (this.state.question_results.hasOwnProperty(ref) &&
                        this.state.question_results[ref]['id'] === question_id &&
                        this.audio_players.hasOwnProperty(ref)
                    ) {
                        this.itemsApp.feature(this.audio_players[ref]).audio.stop();
                    }
                }
            }
        }
        this.hideQuestions();
    }

    hideQuestions()
    {
        let questions = document.getElementsByClassName('ques-cont');
        for (let q in questions) {
            if (questions.hasOwnProperty(q)) {
                questions[q].style.display = 'none';
            }
        }
        this.resetReportedError();
    }

    nextQuestion()
    {
        this.current_skill--;
        if (this.current_skill < 0) {
            this.current_skill = this.state.skills[this.current_key].question_ids.length - 1;
        }
        this.hideQuestions();
        this.resetReportedError();
        this.showQuestions();
    }

    prevQuestion()
    {
        this.current_skill++;
        if (this.current_skill > this.state.skills[this.current_key].question_ids.length) {
            this.current_skill = 0;
        }
        this.hideQuestions();
        this.resetReportedError();
        this.showQuestions();
    }

    initializeLearnosity() {
        let self = this;

        if (this.state.learnosity_set === false && this.props.dt_config !== undefined) {
            this.setState({
                learnosity_set: true
            }, function () {
                let child = this.props.user.children[this.props.user.active_child];
                let items = [];
                for (let item in this.state.current_test.learnosity_items) {
                    if (this.state.current_test.learnosity_items.hasOwnProperty(item)) {
                        if (this.state.current_test.learnosity_items[item].item) {
                            items.push(this.state.current_test.learnosity_items[item].item.reference);
                        }
                    }
                }
                let params = {
                    child: child.id,
                    session_id: this.state.current_test['session_id'],
                    activity_id: this.state.current_test['activity_id'],
                    items: items.join(',')
                };

                self.audio_players = [];
                if (window.LearnosityItems) {
                    ApiFetch('learnosity/app/item-by-references/?' +
                        Object.keys(params).map(key => key + '=' + params[key]).join('&')).then(function (res) {
                        if (self.props.user.is_staff) {
                            console.log(res);
                            console.log(window.LearnosityItems);
                        }
                        self.itemsApp = window.LearnosityItems.init(res['init'], {
                            errorListener: function (error) {
                                console.warn(error);
                            },
                            readyListener: function () {
                                if (self.props.user.is_staff) {
                                    console.log('ready');
                                }
                                let test = self.props.user.active_test;
                                let child = self.props.user.children[self.props.user.active_child];
                                let questions = self.itemsApp.questions();
                                let response_ids = [];

                                for (let [responseID, question] of Object.entries(questions)) {
                                    question.on("validated", function () {
                                        response_ids.push(responseID);
                                        let outputHTML = '';
                                        let map = question.mapValidationMetadata("distractor_rationale_response_level");
                                        if (map) {
                                            map.incorrect.forEach(function (i, data) {
                                                outputHTML += "<li>" + data.metadata + "</li>";
                                            });
                                            if (outputHTML !== "") {
                                                outputHTML = "<ul>" + outputHTML + "</ul>";
                                            }
                                        } else if (question.getMetadata().distractor_rationale) {
                                            outputHTML = "<span>" + question.getMetadata().distractor_rationale + "</span>";
                                        }
                                        if (outputHTML !== "") {
                                            outputHTML = '<span><strong>Explanation: </strong></span>' + outputHTML;
                                            let responseDiv = document.getElementById(responseID);
                                            let distractor = document.getElementById(responseID + "_distractor");
                                            if (distractor) {
                                                distractor.style.display = 'block';
                                            } else if (responseDiv) {
                                                let lrn_response = responseDiv.getElementsByClassName('lrn_response');
                                                if (lrn_response) {
                                                    outputHTML = '<div id="' + responseID + '_distractor" class="distractor">' +
                                                        outputHTML + '</div>';
                                                    if (!question.isValid()) {
                                                        outputHTML = '<div class="correct-answer">Correct Answer</div>' +
                                                            '<div class="wrong-answer">Your Answer</div>' + outputHTML;
                                                    }
                                                    lrn_response[0].insertAdjacentHTML(
                                                        'beforeend',
                                                        outputHTML
                                                    );
                                                }
                                            }
                                            if (distractor && responseDiv.getElementsByClassName('lrn_correctAnswers').length) {
                                                distractor.style.width = '100%';
                                            }
                                        }
                                    });
                                    question.disable();
                                    question.validate({showCorrectAnswers: true});
                                }

                                let items = self.itemsApp.getItems();
                                Object.values(items).forEach(function (item) {
                                    for (let feature in item['features']) {
                                        if (item['features'].hasOwnProperty(feature) && item['features'][feature]['type'] === 'audioplayer') {
                                            let f = self.itemsApp.feature(item['features'][feature]['feature_id']);
                                            if (!self.props.dt_config[test.id][child.grade]['use_audio'] && f !== undefined) {
                                                f.destroy();
                                            } else {
                                                self.audio_players[item['reference']] = item['features'][feature]['feature_id'];
                                            }
                                        }
                                    }
                                });

                                let playbackButton = document.getElementById('lrn-audio-overwrite-content');
                                if (playbackButton) {
                                    playbackButton.style.display = 'none';
                                }

                            }
                        });
                    })
                } else {
                    confirmAlert({
                        title: 'Sorry!',
                        message: "There is an issue connecting to our practice questions server please refresh and " +
                            "try again. If the issue persists please contact support by using" +
                            " the options on the lower right.",
                        buttons: [
                            {
                                label: 'Refresh Now',
                                onClick: function() {
                                    window.location.reload();
                                }
                            },
                            {
                                label: 'Continue',
                            }
                        ]
                    });
                }
            });
        }
    }

    setSkills(finished = false, assessmentItemsApp)
    {
        let self = this;
        let test = this.props.user.active_test;
        let child = this.props.user.children[this.props.user.active_child];
        let current_test = {};
        let question_results = {};
        let skills = {};
        let learnosity_items = [];
        let high_scores = [];
        if (finished) {
            if (assessmentItemsApp && typeof assessmentItemsApp.reset === 'function') {
                assessmentItemsApp.reset();
            }
            self.setState({
                'learnosity_set': false,
                'showTutoringModal': true,
            });
        }
        if (child.hasOwnProperty('assessments_by_test') && child['assessments_by_test'].hasOwnProperty(test.id)) {
            this.skills_set = true;
            if (Object.keys(self.state.current_test).length > 0 && self.state.current_test.test === test.id && !finished) {
                current_test = self.state.current_test;
            } else {
                let most_recent = child['assessments_by_test'][test.id]['keys'][child['assessments_by_test'][test.id]['keys'].length - 1];
                current_test = child['assessments'][most_recent];
            }
            let questions = current_test['questions'];
            if (current_test['learnosity']) {
                questions = current_test['learnosity_items'];
                for (let key in child['assessments_by_test'][test.id]['keys']) {
                    let items_key = child['assessments_by_test'][test.id]['keys'][key];
                    if (typeof child['assessments'][items_key] !== 'undefined' &&
                        child['assessments'][items_key].hasOwnProperty('learnosity_items')
                    ) {
                        for (let item in child['assessments'][items_key]['learnosity_items']) {
                            if (child['assessments'][items_key]['learnosity_items'].hasOwnProperty(item) &&
                                child['assessments'][items_key]['learnosity_items'][item].hasOwnProperty('item') &&
                                child['assessments'][items_key]['learnosity_items'][item]['item'] !== null &&
                                child['assessments'][items_key]['learnosity_items'][item]['item'].hasOwnProperty('reference')
                            ) {
                                question_results[child['assessments'][items_key]['learnosity_items'][item]['item']['reference']] =
                                    child['assessments'][items_key]['learnosity_items'][item]['item'];
                                learnosity_items.push(child['assessments'][items_key]['learnosity_items'][item]['item']['reference']);
                            }
                        }
                    }
                }
            }

            for (let q in questions) {
                if (questions.hasOwnProperty(q)) {
                    let question_skills = questions[q]['skills'];
                    if (current_test['learnosity']) {
                        if (questions[q]['item'] !== null) {
                            question_skills = questions[q]['item']['skills'];
                            question_results = current_test['learnosity_items'];
                        }
                    } else {
                        question_results[questions[q]['question']] = questions[q];
                    }
                    for (let s in question_skills) {
                        if (
                            question_skills.hasOwnProperty(s) &&
                            question_skills[s]['parent'] === null &&
                            test.skills.indexOf(question_skills[s]['id']) !== -1
                        ) {
                            if (!skills.hasOwnProperty(question_skills[s]['id'])) {
                                skills[question_skills[s]['id']] = {
                                    title: question_skills[s]['title'],
                                    correct: 0,
                                    questions: 0,
                                    score: 0,
                                    question_ids: [],
                                    description: question_skills[s]['description']
                                }
                            }
                            if (current_test['learnosity']) {
                                skills[question_skills[s]['id']]['question_ids'].push(questions[q]['item']['id']);
                            } else {
                                skills[question_skills[s]['id']]['question_ids'].push(questions[q]['question']);
                            }
                            if (questions[q]['correct']) {
                                skills[question_skills[s]['id']]['correct']++;
                            }
                            skills[question_skills[s]['id']]['questions']++;
                            let score = skills[question_skills[s]['id']]['correct'] /
                                skills[question_skills[s]['id']]['questions'];
                            skills[question_skills[s]['id']]['score'] = score;
                        }
                    }
                }
            }

            for (let key in skills) {
                high_scores.push({
                    'score': skills[key]['score'],
                    'correct': skills[key]['correct'],
                    'key': key,
                })
            }
            high_scores.sort((a, b) => a.score - b.score || a.correct - b.correct);

            if (current_test['learnosity']) {
                self.setState({
                    question_results: question_results,
                    learnosity_items: learnosity_items,
                    // learnosity_set: false
                }, self.initializeLearnosity);
            } else {
                ApiFetch('question/?limit=100&pk=' + Object.keys(question_results).join(',')).then(function (res) {
                    self.setState({
                        questions: res['results'],
                        question_results: question_results
                    });
                });
            }
            let high_score_key = -1;
            if (high_scores.length && high_scores[high_scores.length - 1].score > 0.5) {
                high_score_key = high_scores[high_scores.length - 1]['key']
            }
            this.setState({
                high_score_key: high_score_key,
                current_test: current_test,
                skills: skills,
            });
        }
    }

    replayAudio(key) {
        let audio = document.getElementById("question_audio" + key);
        audio.pause();
        audio.currentTime = 0;
        audio.play();
    }

    showReportedError(question_id, reference) {
        let re = document.getElementById('reportedError');
        re.style.display = 'block';
        re.setAttribute('data-question_id', question_id);
        re.setAttribute('data-learnosity_ref', reference);
    }

    resetReportedError()
    {
        let re = document.getElementById('reportedError');
        if (re) {
            re.getElementsByClassName('form')[0].className = 'form';
            re.getElementsByClassName('submitted')[0].className = 'submitted hide';
        }
    }

    changeSelectedSkill(key) {
        this.setState({
            selected_skill: key
        });
    }

    changeCurrentGrade(e) {
        let child = this.props.user.children[this.props.user.active_child];
        for (let g in this.props.grades) {
            if (this.props.grades.hasOwnProperty(g) && this.props.grades[g]['id'] === child['grade']) {
                this.setState({
                    current_grade: e.target.value
                });
            }
        }
    }

    changeCurrentTest(key)
    {
        let self = this;
        let child = this.props.user.children[this.props.user.active_child];
        if (this.itemsApp && typeof this.itemsApp.reset === 'function') {
            this.itemsApp.reset();
        }
        if (child.assessments.hasOwnProperty(key)) {
            this.setState({
                current_test: child.assessments[key],
                learnosityResultsKey: self.state.learnosityResultsKey + 100,
                learnosity_set: false
            }, self.setSkills);
        }
    }

    togglePrintableTest(el) {
        let table = el.currentTarget.parentElement;
        if (table.className === 'printable-test open') {
            table.className = 'printable-test closed';
        } else {
            table.className = 'printable-test open';
        }
    }

    showFreeQuickPractice()
    {
        // if (this.state.showFreePractice === false) {
        //     this.setState({
        //         'showFreePractice': true
        //     })
        // } else {
        //     this.setState({
        //         'showFreePractice': false
        //     })
        // }
        window.location.href = WEBSITE_URL + 'buy-now/?free-questions=1';
    }

    handleUseAudio(event)
    {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render () {
        let doc = this.props.appProps.match.params.document;
        if (doc !== undefined) {
            return <Document doc={doc} user={this.props.user}/>
        }
        let self = this;
        let gradeKey = 0;
        let skillsKey = 0;
        let pastResultsKey = 0;
        let sbaKey = 0;
        let gamesKey = 0;
        let lessonsKey = 0;
        let questionKey = 0;
        let child = this.props.user.children[this.props.user.active_child];

        let grades = [];
        let test = Object.assign({}, this.props.user.active_test);
        let test_assessment_keys = [];
        if (test !== undefined) {
            if (test.hasOwnProperty('documents') &&
                test['documents'].hasOwnProperty('documents') &&
                Object.keys(test['documents']['documents']).length > 0
            ) {
                grades = test['documents']['grades'];
            }
            if (test.hasOwnProperty('id') && child['assessments_by_test'].hasOwnProperty(test.id)) {
                test_assessment_keys = child['assessments_by_test'][test.id]['keys'].slice(0).reverse();
            }
        }
        let assessCount = 0;
        let lineData = [];
        let total_correct = 0;
        let total_questions = 0;
        let skillLineData = [];
        let skillAssessCount = 0;
        if (this.state.selected_skill !== null) {
            let assessments = child['assessments'];
            for (let assess in assessments) {
                total_questions = 0;
                total_correct = 0;
                if (skillAssessCount < 10 && assessments.hasOwnProperty(assess)) {
                    if (assessments[assess]['test'] === self.props.user.active_test.id) {
                        if (assessments[assess].learnosity) {
                             for (let question in assessments[assess]['learnosity_items']) {
                                if (assessments[assess]['learnosity_items'].hasOwnProperty(question) &&
                                    assessments[assess]['learnosity_items'][question].hasOwnProperty('item') &&
                                    assessments[assess]['learnosity_items'][question]['item'] &&
                                    assessments[assess]['learnosity_items'][question]['item'].hasOwnProperty('skills')
                                ) {
                                    let question_skills = assessments[assess]['learnosity_items'][question]['item']['skills'];
                                    for (let skill in question_skills) {
                                        if (question_skills.hasOwnProperty(skill)) {
                                            if (question_skills[skill].id === parseInt(this.state.selected_skill)) {
                                                total_questions++;
                                                if (assessments[assess]['learnosity_items'][question].correct) {
                                                    total_correct++;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            for (let question in assessments[assess]['questions']) {
                                if (assessments[assess]['questions'].hasOwnProperty(question)) {
                                    let question_skills = assessments[assess]['questions'][question]['skills'];
                                    for (let skill in question_skills) {
                                        if (question_skills.hasOwnProperty(skill)) {
                                            if (question_skills[skill].id === parseInt(this.state.selected_skill)) {
                                                total_questions++;
                                                if (assessments[assess]['questions'][question].correct) {
                                                    total_correct++;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        if (total_questions > 0) {
                            skillAssessCount++;
                            let time = moment.utc(assessments[assess]['created_at']).local().format('MM/DD h:mma');
                            skillLineData.push({
                                name: time,
                                Score: Math.round(total_correct / total_questions * 100),
                                total_questions: total_questions
                            });
                        }
                    }
                }
            }
        }

        if (this.props.user.email) {
            let has_audio = false;
            if (this.props.dt_config.hasOwnProperty(this.props.user.active_test.id) &&
                this.props.dt_config[this.props.user.active_test.id].hasOwnProperty(child.grade)
            ) {
                has_audio = this.props.dt_config[this.props.user.active_test.id][child.grade]['use_audio'];
            }

            let safeTestTitle = '';
            let testOverview = '';
            if (this.props.user.active_test) {
                safeTestTitle = <span dangerouslySetInnerHTML={{__html: this.props.user.active_test.title}} />;
                if (this.props.user.active_test.overview) {
                    testOverview = this.props.user.active_test.overview;
                }
            }

            // Set apps for current grade and all tests for test page
            let apps = [];
            if (Object.keys(self.state.apps).length) {
                for(let key in self.state.apps) {
                    if (self.state.apps.hasOwnProperty(key)) {
                        if (self.state.apps[key].short_title !== 'Digital Tutor' &&
                            self.state.apps[key].grades.indexOf(child.grade) !== -1) {
                            apps.push(self.state.apps[key]);
                        }
                    }
                }
            }

            let user_sbas = [];
            if (this.props.user.sbas) {
                for (let sba in this.props.user.sbas) {
                    if (this.props.user.sbas.hasOwnProperty(sba)) {
                        user_sbas.push(this.props.user.sbas[sba].program.id);
                    }
                }
            }

            // Set SBAs for current grade and all tests for test page
            let sbas = [];
            if (Object.keys(self.state.sbas).length) {
                for (let key in self.state.sbas) {
                    if (self.state.sbas.hasOwnProperty(key)) {
                        for (let grade in self.state.sbas[key].grades) {
                            if (self.state.sbas[key].grades[grade]['id'] === child.grade) {
                                sbas.push(self.state.sbas[key]);
                            }
                        }
                    }
                }
            }

            let showDocumentLoading = true;
            let amountOfPastAssessments = 5;
            let resultLinkClass = "result-links";
            let most_recent_test = null;
            let most_recent_time = null;
            if (child.hasOwnProperty(['assessments_by_test']) &&
                child['assessments_by_test'].hasOwnProperty(test.id) &&
                child['assessments_by_test'][test.id]['keys'].length > 1
            ) {
                resultLinkClass += ' col75';
                most_recent_test = child.assessments[
                    child.assessments_by_test[test.id]['keys'][child.assessments_by_test[test.id]['keys'].length - 1]
                    ];
                most_recent_time = moment.utc(most_recent_test['created_at']).local().format('dddd<br/>MMMM Do YYYY<br/>LT');
            }

            let oc = this.props.extraProps.onlineClasses;
            let onlineClasses = [];
            if (oc.length > 0) {
                for (let cl in oc) {
                    if (oc.hasOwnProperty(cl)) {
                        for (let classTest in oc[cl]['tests']) {
                            if (oc[cl]['tests'].hasOwnProperty(classTest) && oc[cl]['tests'][classTest] === this.props.user.active_test.id) {
                                onlineClasses.push(oc[cl])
                            }
                        }
                        for (let classGrade in oc[cl]['grades']) {
                            if (oc[cl]['grades'].hasOwnProperty(classGrade) && oc[cl]['grades'][classGrade] === child.grade) {
                                onlineClasses.push(oc[cl])
                            }
                        }
                    }
                }
            }

            let controlOne = document.querySelector('.control:first-of-type');
            let controlTwo = document.querySelector('.control:nth-of-type(2)');
            if (this.props.appProps.match.params.section == null && this.props.user.paid_access === true &&
                this.props.user.active_test.interactive_questions === false) {
                if (controlOne) {
                    controlOne.classList.add('active');
                }
            } else if (this.props.appProps.match.params.section !== null &&
                this.props.appProps.match.params.section !== "printables" && this.props.user.paid_access === true &&
                this.props.user.active_test.interactive_questions === true) {
                if (controlTwo) {
                    controlTwo.classList.remove('active');
                }
            }
            let firstDoc = true;

            return (
                <div>
                    <div id="test-dashboard" className="wrapper">
                        <div className="dashboard">
                            {onlineClasses.length > 0 &&
                            <div className={"section online-classes"}>
                                <h3>Check out our new Live Online Classes for {self.props.grades[child.grade].name} and&nbsp;
                                    <span dangerouslySetInnerHTML={{__html: this.props.user.active_test.short_title}}/>
                                    &nbsp;test prep!</h3>
                                <LearningPods onlineClasses={onlineClasses} />
                                <Link to={WEBSITE_URL + 'camps/'}>Show all Live Online Classes</Link>
                            </div>
                            }
                            <div className="section about">
                                <h2>About the Test</h2>
                                {child.grade >= 4 && child.state !== null &&
                                    Object.keys(this.props.regions).length > 0 &&
                                this.props.regions[child.state]['country'] !== null &&
                                    <Link className="state-link" to={"/test-prep/states-common-core/" +
                                        this.props.regions[child.state]['name']}>
                                        Go to {this.props.regions[child.state]['name']} State Testing</Link>
                                }
                                <p><b>{safeTestTitle}</b> {testOverview} <Link to={"/test-prep/" +
                                this.props.user.active_test.slug + "/practice-questions"}>
                                        Learn more.</Link></p>
                            </div>
                            <div className="prep-controls">
                                <NavLink exact to={"/dashboard/" + this.state.active_child_id}
                                  className={"control"} activeClassName="active">
                                    <FontAwesomeIcon icon={'chart-line'}/>Interactive Practice</NavLink>
                                <NavLink to={"/dashboard/" + this.state.active_child_id + "/printables/"}
                                      className={"control printable-control"} activeClassName="active">
                                    <FontAwesomeIcon icon={'print'}/>Printable Practice</NavLink>
                                <NavLink to={"/dashboard/" + this.state.active_child_id + "/lessons/"}
                                      className={"control "} activeClassName="active">
                                    <FontAwesomeIcon icon={'file-alt'}/>Lessons for Parents</NavLink>
                                <NavLink to={"/dashboard/" + this.state.active_child_id + "/skill-building/"}
                                      className="control " activeClassName="active">
                                    <FontAwesomeIcon icon={'puzzle-piece'}/>Skill Building</NavLink>
                                <NavLink to={"/dashboard/" + this.state.active_child_id + "/related-games/"}
                                         className="control" activeClassName="active">
                                    <FontAwesomeIcon icon={'gamepad'}/>Interactive Games</NavLink>
                            </div>
                            {this.props.appProps.match.params.section == null &&
                            <div className="prep-section" id="interactive-practice">
                                <div className={"prep-heading open"}>
                                    <img src={"/img/test-dashboard/icon-assessments.png"} alt=""
                                         className="heading-img"/>
                                    <h3><span>Practice Tests</span></h3>
                                    <p>The interactive practice questions are to help your child prepare for the
                                        test!</p>
                                </div>
                                {this.props.user.active_test.interactive_questions === true &&
                                <div>
                                    <div className={"prep-content open"}>
                                        {this.props.user.paid_access === true &&
                                        this.state.current_test.hasOwnProperty('questions') === true &&
                                        this.props.user.active_test &&
                                        this.props.user.active_test.interactive_questions === true &&
                                        this.props.dt_config.hasOwnProperty(this.props.user.active_test.id) &&
                                        this.props.dt_config[this.props.user.active_test.id][child.grade]['total_questions'] > 40 &&
                                        <div>
                                            <div className="recent-assessment">
                                                {child.hasOwnProperty(['assessments_by_test']) &&
                                                child['assessments_by_test'].hasOwnProperty(test.id) &&
                                                child['assessments_by_test'][test.id]['ids'][
                                                child['assessments_by_test'][test.id]['ids'].length - 1
                                                    ] === self.state.current_test.id &&
                                                <p className="bold">Most Recent Practice Test</p>
                                                }
                                                <span>Date Taken:</span>
                                                <span
                                                    className="green-text">{moment.utc(self.state.current_test['created_at']).local().format('MMMM Do YYYY, h:mm a')}</span>
                                                <br/>
                                                <span>Score:</span>
                                                <span>{this.state.current_test.total_correct} / {this.state.current_test.total_questions}</span>
                                            </div>
                                            <p className="italic-subtitle">
                                                Select a skill from the practice test results to see recent practice
                                                sessions information below.</p>
                                            <div style={{overflowX: "auto"}}>
                                                <div className="results">
                                                    <div className={"results-head"}>
                                                        <div>Skill / Category / Section</div>
                                                        <div>Results</div>
                                                        <div>Correct / Total</div>
                                                    </div>
                                                    <div className={"results-body"}>
                                                        {Object.keys(self.state.skills).map(function (key) {
                                                            skillsKey++;
                                                            let highScore = false;
                                                            if (self.state.high_score_key > 1 && key === self.state.high_score_key) {
                                                                highScore = true;
                                                            }
                                                            return (
                                                                <SkillResults
                                                                    key={skillsKey}
                                                                    skills={self.state.skills}
                                                                    highScore={highScore}
                                                                    keyNumber={key}
                                                                    changeSelectedSkill={function () {
                                                                        self.changeSelectedSkill(key)
                                                                    }}
                                                                    showResults={function () {
                                                                        self.showResults(key)
                                                                    }}
                                                                />
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            {child.hasOwnProperty(['assessments_by_test']) &&
                                            child['assessments_by_test'].hasOwnProperty(test.id) &&
                                            child['assessments_by_test'][test.id]['keys'].length > 1 &&
                                            <div className={"col25 past-results right"}>
                                                <h4>View Past Results</h4>
                                                {
                                                    test_assessment_keys.slice(0, amountOfPastAssessments).map(function (el) {
                                                        pastResultsKey++;
                                                        let assessment = child['assessments'][el];
                                                        let time = moment.utc(assessment['created_at']).local().format('lll');
                                                        if (assessment.id !== self.state.current_test.id) {
                                                            return <p key={pastResultsKey} className="date green-text"
                                                                      onClick={function () {
                                                                          self.changeCurrentTest(el)
                                                                      }}>{time}</p>;
                                                        }
                                                        return false;
                                                    })
                                                }
                                                {child.hasOwnProperty(['assessments_by_test']) &&
                                                child['assessments_by_test'].hasOwnProperty(test.id) &&
                                                child['assessments_by_test'][test.id]['keys'].length > amountOfPastAssessments &&
                                                <div className="styled-select">
                                                    <select defaultValue={""} onChange={function (e) {
                                                        self.changeCurrentTest(e.target.value)
                                                    }}>
                                                        <option disabled value={""}>More Past Results</option>
                                                        {
                                                            test_assessment_keys.slice(amountOfPastAssessments).map(function (el) {
                                                                pastResultsKey++;
                                                                let assessment = child['assessments'][el];
                                                                let time = moment.utc(assessment['created_at']).local().format('lll');
                                                                if (assessment.id !== self.state.current_test.id) {
                                                                    return <option key={pastResultsKey}
                                                                                   className="date green-text"
                                                                                   value={el}>{time}</option>;
                                                                }
                                                                return false;
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                }
                                            </div>
                                            }
                                            <div className={resultLinkClass}>
                                                <p className="italic-subtitle">Choose how many questions you want for the
                                                    practice test and then begin or you can practice from printable
                                                    documents.</p>
                                                {self.props.user.active_test.slug === 'cogat-form-7' &&
                                                <p className="italic-subtitle">
                                                    The CogAT&reg; Screener uses only the analogy questions
                                                    from each of the three batteries of the CogAT&reg;
                                                </p>
                                                }
                                                <StartPracticeLinks
                                                    slug={self.props.user.active_test.slug}
                                                    goToPractice={self.goToPractice}
                                                    updateNumberOfQuestions={self.updateNumberOfQuestions}
                                                    paid_access={self.props.user.paid_access}
                                                    student={self.props.user.active_child}
                                                    has_audio={has_audio}
                                                    use_audio={self.state.use_audio}
                                                    handleUseAudio={self.handleUseAudio}
                                                    number_of_questions={self.state.number_of_questions}
                                                />
                                            </div>
                                            <div className={"clear"}/>
                                        </div>
                                        }
                                        {this.props.user.paid_access === true &&
                                        self.state.current_test.hasOwnProperty('questions') === false &&
                                        this.props.user.active_test.interactive_questions === true &&
                                        this.props.dt_config.hasOwnProperty(this.props.user.active_test.id) &&
                                        this.props.user.active_test &&
                                        child.grade &&
                                        this.props.dt_config[this.props.user.active_test.id][child.grade] &&
                                        this.props.dt_config[this.props.user.active_test.id][child.grade]['total_questions'] > 40 &&
                                        this.props.user.paid_access &&
                                        <div className="result-links">
                                            <p className="italic-subtitle">You haven't taken any practice questions yet.
                                                Start now below.</p>
                                            <StartPracticeLinks
                                                slug={self.props.user.active_test.slug}
                                                goToPractice={self.goToPractice}
                                                updateNumberOfQuestions={self.updateNumberOfQuestions}
                                                paid_access={self.props.user.paid_access}
                                                student={self.props.user.active_child}
                                                has_audio={has_audio}
                                                use_audio={self.state.use_audio}
                                                handleUseAudio={self.handleUseAudio}
                                                number_of_questions={self.state.number_of_questions}
                                            />
                                        </div>
                                        }
                                        {this.props.user.paid_access === false &&
                                        <div style={{textAlign: 'center'}}>
                                            <div className="result-links">
                                                <p className="italic-subtitle">You haven't taken any practice questions yet.
                                                    Start now below.</p>
                                                <StartPracticeLinks
                                                    slug={self.props.user.active_test.slug}
                                                    goToPractice={self.goToPractice}
                                                    updateNumberOfQuestions={self.updateNumberOfQuestions}
                                                    paid_access={self.props.user.paid_access}
                                                    student={self.props.user.active_child}
                                                    has_audio={has_audio}
                                                    use_audio={self.state.use_audio}
                                                    handleUseAudio={self.handleUseAudio}
                                                    number_of_questions={self.state.number_of_questions}
                                                />
                                            </div>
                                        </div>
                                        }
                                        {this.props.user.paid_access === true &&
                                        this.state.current_test.hasOwnProperty('questions') === true &&
                                        <div className="section skill-specific">
                                            <h2>Skill Specific Resources
                                                {this.state.selected_skill !== null &&
                                                this.state.skills.hasOwnProperty(this.state.selected_skill) &&
                                                <span> - {this.state.skills[this.state.selected_skill].title}</span>}
                                            </h2>
                                            <div className="flex">
                                                <div className="info">
                                                    <div>
                                                        <h4>Most Recent Session:</h4>
                                                        <p className="date green-text"
                                                           dangerouslySetInnerHTML={{__html: most_recent_time}}/>
                                                        <p className="score">
                                                            Correct / Total<br/><span className="green-text">
                                                    {self.state.current_test.total_correct} /
                                                            {self.state.current_test.total_questions}</span></p>
                                                    </div>
                                                </div>
                                                <div className="chart-box">
                                                    {child['assessments'].length > 0 && self.props.tests_by_id.hasOwnProperty(1) &&
                                                    <div>
                                                        <h4 className={'chart-title'}>Past Overall Results</h4>
                                                        <div className={"scores"}>
                                                            {child['assessments'].slice(0).reverse().map(function (assess) {
                                                                if (assessCount < 10) {
                                                                    if (assess['test'] === self.props.user.active_test.id) {
                                                                        assessCount++;
                                                                        let time = moment.utc(assess['created_at']).local().format('MM/DD h:mma');
                                                                        let score = assess['total_correct'] / assess['total_questions'] * 100;
                                                                        if (score !== Math.floor(score)) {
                                                                            score = score.toFixed(1);
                                                                        }
                                                                        lineData.push({
                                                                            name: time,
                                                                            Score: score,
                                                                            total_questions: assess['total_questions']
                                                                        });
                                                                    }
                                                                }
                                                                return false;
                                                            })}
                                                        </div>
                                                        <div className="chart">
                                                            {lineData.length > 0 &&
                                                            <ResponsiveContainer width='100%' aspect={4.0 / 2.0}
                                                                                 key={this.state.chartKey}>
                                                                <LineChart data={lineData} margin={{
                                                                    top: 20,
                                                                    right: 20,
                                                                    left: 20,
                                                                    bottom: 25
                                                                }} key={this.state.chartKey}>
                                                                    <Line type="linear" dataKey="Score"
                                                                          isAnimationActive={false}
                                                                          dot={{stroke: "#8884d8", strokeWidth: 3}}
                                                                          label={false}
                                                                    />
                                                                    <XAxis dataKey="name"
                                                                           label={<CustomLabel value='Date'/>}
                                                                           tick={false}/>
                                                                    <YAxis domain={[0, 100]} minTickGap={25}
                                                                           label={<CustomLabel value='% Correct'
                                                                                               vertical={true}/>}/>
                                                                    <Tooltip content={<CustomTooltip/>} allowEscapeViewBox={{ x: true, y: true }}/>
                                                                </LineChart>
                                                            </ResponsiveContainer>
                                                            }
                                                        </div>
                                                        <div className="clear"/>
                                                    </div>
                                                    }
                                                </div>
                                                <div className="chart-box">
                                                    {this.state.selected_skill === null &&
                                                    <h4 className={"chart-title"}>Select a skill above to review past
                                                        results</h4>
                                                    }
                                                    {this.state.selected_skill !== null &&
                                                    <div>
                                                        {this.state.skills.hasOwnProperty(this.state.selected_skill) &&
                                                        <h4 className={"chart-title"}>
                                                            Past Results
                                                            for {this.state.skills[this.state.selected_skill].title}
                                                        </h4>
                                                        }
                                                        {skillLineData.length > 0 &&
                                                        <ResponsiveContainer width='100%' aspect={4.0 / 2.0}
                                                                             key={this.state.chartKey}>
                                                            <LineChart data={skillLineData.splice(0, 10).reverse()}
                                                                       margin={{
                                                                           top: 20,
                                                                           right: 20,
                                                                           left: 20,
                                                                           bottom: 25
                                                                       }} key={this.state.chartKey}>
                                                                <Line type="linear" dataKey="Score"
                                                                      isAnimationActive={false}
                                                                      dot={{stroke: "#8884d8", strokeWidth: 3}}
                                                                      label={false}/>
                                                                <XAxis dataKey="name"
                                                                       label={<CustomLabel value='Date'/>}
                                                                       tick={false}/>
                                                                <YAxis domain={[0, 100]} minTickGap={25}
                                                                       label={<CustomLabel value='% Correct'
                                                                                           vertical={true}/>}/>
                                                                <Tooltip content={<CustomTooltip />} allowEscapeViewBox={{ x: true, y: true }}/>
                                                            </LineChart>
                                                        </ResponsiveContainer>
                                                        }
                                                    </div>
                                                    }
                                                </div>
                                                <div className="clear"/>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                                }
                                {
                                    (!this.props.dt_config.hasOwnProperty(this.props.user.active_test.id) ||
                                        ( this.props.user.active_test &&
                                            child.grade &&
                                            this.props.dt_config[this.props.user.active_test.id][child.grade] &&
                                        this.props.dt_config[this.props.user.active_test.id][child.grade]['total_questions'] <= 40)
                                        ||
                                        this.props.user.active_test.interactive_questions === false
                                    ) &&
                                    <div>
                                    <p className={"noInteractiveP"}>We're sorry, but there are no interactive practice questions for
                                        {this.props.user.active_test.short_title} { this.props.grades_by_id[child.grade]['name'] }.</p>
                                    </div>
                                }
                                <div className={"babies"}>
                                    <img src={"/img/test-dashboard/space-baby-1.png"} style={{display: 'inline-block',
                                        width: '33%'}} alt="" />
                                    <img src={"/img/test-dashboard/space-baby-2.png"} style={{display: 'inline-block',
                                        width: '33%'}} alt="" />
                                    <img src={"/img/test-dashboard/space-baby-3.png"} style={{display: 'inline-block',
                                        width: '33%'}} alt="" />
                                </div>
                            </div>
                            }
                            {this.props.appProps.match.params.section === "printables" &&
                            <div className="prep-section printables" id="printables">
                                <div className={"prep-heading"}>
                                    <img src={"/img/test-dashboard/icon-printable-practice.png"} alt=""
                                         className="heading-img"/>
                                    <h3><span>Printable Practice</span></h3>
                                    <p>Worksheets and Practice questions that you can print.</p>
                                </div>
                                <div className="prep-content">
                                    {grades.length > 0 &&
                                    <div className="styled-select grade-select">
                                        <select name="grades" id="resources" value={this.state.current_grade}
                                                onChange={self.changeCurrentGrade}>
                                            <option value={''}>All Grade Resources</option>
                                            {grades.map(function (g) {
                                                gradeKey++;
                                                return <option key={gradeKey} value={g}>{g} Resources</option>
                                            })}
                                        </select>
                                    </div>
                                    }
                                    {
                                        Object.keys(self.state.test_page).length > 0 &&
                                        self.state.test_page.tests.map(function (t) {
                                            let test = self.props.tests_by_id[t['id']];
                                            let testClass = 'open';
                                            let caret = 'caret-down';
                                            if (!firstDoc) {
                                                testClass = 'closed';
                                                caret = 'caret-up';
                                            }
                                            firstDoc = false;
                                            if (t.show_practice_questions &&
                                                test.hasOwnProperty('documents') &&
                                                Object.keys(test.documents).length
                                            ) {
                                                let categories = false;
                                                if (self.state.test_page.display_mode.indexOf('category') !== -1) {
                                                    categories = true;
                                                }
                                                showDocumentLoading = false;
                                                return (
                                                    <div key={t['id']} className={"printable-test " + testClass}>
                                                        <h3 onClick={self.togglePrintableTest}>
                                                            {test.title}
                                                            <FontAwesomeIcon className={"caret test-name"}
                                                                             icon={caret}/>
                                                        </h3>
                                                        <Printables
                                                            active_test={test}
                                                            appProps={self.props.appProps}
                                                            categories={categories}
                                                            child={self.props.user.children[self.props.user.active_child]}
                                                            current_grade={self.state.current_grade}
                                                            deleteDocument={self.props.deleteDocument}
                                                            documents={test.documents}
                                                            page={'dashboard'}
                                                            updateChild={self.props.updateChild}
                                                            updateUser={self.props.updateUser}
                                                            user={self.props.user}
                                                        />
                                                    </div>
                                                )
                                            } else {
                                                return false;
                                            }
                                        })
                                    }
                                    {
                                        (Object.keys(self.state.test_page).length === 0 || showDocumentLoading) &&
                                        <div style={{position: 'relative', height: '300px'}}><Loading/></div>
                                    }
                                </div>
                                <div className={"babies"}>
                                    <img src={"/img/test-dashboard/printable-babies.jpg"} alt="" />
                                </div>
                            </div>
                            }
                            {this.props.appProps.match.params.section === "lessons" &&
                            <div className="prep-section">
                                <div className={"prep-heading"}>
                                    <img src={"/img/test-dashboard/icon-parent-resources.png"} alt=""
                                         className="heading-img"/>
                                    <h3><span>Lessons for Parents/Parent Resources</span></h3>
                                    <p>Articles, Videos, Test-prep Tips, and more!</p>
                                </div>
                                <div className="prep-content">
                                    <p style={{marginBottom: "40px"}}>To learn more about the&nbsp;
                                        <span dangerouslySetInnerHTML={{__html: this.props.user.active_test.short_title }}/>,
                                        click the down arrow to the right of the item OR click
                                        the item to expand the information.</p>
                                    {Object.keys(this.state.lessons).length > 0 &&
                                    Object.keys(this.state.lessons).map(function (key) {
                                        lessonsKey++;
                                        return (
                                            <Lessons
                                                key={lessonsKey}
                                                lesson_key={lessonsKey}
                                                lessons={self.state.lessons[key]}
                                                test_title={self.props.user.active_test.title}
                                                videos={self.state.test_page.videos}
                                                user={self.props.user}
                                            />
                                        )
                                    })
                                    }
                                </div>
                                <div className={"babies"}>
                                    <img src={"/img/test-dashboard/lessons-babies.jpg"} alt=""/>
                                </div>
                            </div>
                            }
                            {this.props.appProps.match.params.section === "skill-building" &&
                            <div className="prep-section sba">
                                <div className={"prep-heading"}>
                                    <img src={"/img/test-dashboard/icon-skill-building.png"} alt=""
                                         className="heading-img"/>
                                    <h3><span>Skill Building Academy</span></h3>
                                    <p>Educational partner programs to help build your child's skills
                                        in a variety of subjects.</p>
                                </div>
                                <div className="prep-content sbas">
                                    <Link className={"right"} to={"/skill-building-academy?grade=all"}>Go To All Skill
                                        Building Academy Programs</Link>
                                    {sbas.length > 0 &&
                                    <div>
                                        <div className="key">
                                            <div className="star-key">
                                                <div className="star orange"/>
                                                <span>Instant Access</span>
                                            </div>
                                            <div className="star-key">
                                                <div className="star green"/>
                                                <span>Click to request log-in</span>
                                            </div>
                                            <div className="star-key">
                                                <div className="star blue"/>
                                                <span>Access Granted</span>
                                            </div>
                                        </div>
                                        <div className="clear"/>
                                        {sbas.map(function (sba) {
                                            let grades = '';
                                            if (sba['grades'].length > 0) {
                                                grades = sba['grades'][0]['name'];
                                                if (sba['grades'].length > 1) {
                                                    grades += ' - ' + sba['grades'][sba['grades'].length - 1]['name'];
                                                }
                                            }
                                            let safeDisplayName = <span
                                                dangerouslySetInnerHTML={{__html: sba.program_name}}/>;
                                            sbaKey++;
                                            let link = '';
                                            let user_access = false;
                                            if (user_sbas.indexOf(sba['id']) !== -1) {
                                                user_access = true;
                                            }
                                            if (self.props.user.paid_access) {
                                                link = "/skill-building-academy/" + sba.url;
                                            } else {
                                                link = WEBSITE_URL + 'buy-now/?redirected=1';
                                            }
                                            return <ThumbnailLink
                                                key={sbaKey}
                                                alt={sba.display_name}
                                                display_name={safeDisplayName}
                                                grade={grades}
                                                grade_val={self.state.selected_grade}
                                                description={sba.tooltip_text}
                                                instant_access={sba.instant_access}
                                                link={link}
                                                thumbnail={sba.thumbnail}
                                                title={sba.tooltip_text}
                                                user_access={user_access}
                                            />
                                        })}
                                    </div>
                                    }
                                </div>
                                <div className={"babies"}>
                                    <img src={"/img/test-dashboard/skill-building-babies.jpg"} alt=""/>
                                </div>
                            </div>
                            }
                            {this.props.appProps.match.params.section === "related-games" &&
                            <div className="prep-section games">
                                <div className={"prep-heading"}>
                                    <img src={"/img/test-dashboard/icon-related-games.png"} alt=""
                                         className="heading-img"/>
                                    <h3><span>Interactive Games</span></h3>
                                    <p>Fun online games to help your child prepare for the test!</p>
                                </div>
                                <div className="prep-content">
                                    <Link className={"right"} to={"/games?grade=all"}>Go to All Games</Link>
                                    <div className={"clear"}/>
                                    {apps.length > 0 && apps.map(function (app) {
                                        gamesKey++;
                                        let link = app.url;
                                        if (app.app_url !== null && self.props.user.paid_access) {
                                            link = app.app_url;
                                        } else {
                                            link = WEBSITE_URL + 'buy-now/?redirected=1';
                                        }
                                        return <ThumbnailLink
                                            key={gamesKey}
                                            link={link}
                                            alt={app.title}
                                            thumbnail={app.thumbnail}
                                            title={app.description}
                                            display_name={app.short_title}
                                            grade_val={self.state.selected_grade}
                                            description={app.description}
                                        />
                                    })}
                                </div>
                                <div className={"babies"}>
                                    <img src={"/img/test-dashboard/games-babies.jpg"} alt=""/>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    {self.props.user.active_test &&
                    <TutoringModal
                        isDescendant={self.props.isDescendant}
                        child_name={self.props.user.children[self.props.user.active_child].name}
                        test_title={self.props.user.active_test.short_title}
                        showTutoringModal={self.state.showTutoringModal}
                    />
                    }
                    {window.location.pathname.indexOf('/practice') === -1 &&
                    !this.state.showFreePractice &&
                    this.props.user.paid_access &&
                    <div id="assessment-results" className="assessment results">
                        <ReportedError
                            learnosity={this.state.current_test.learnosity}
                        />
                        <div id="questions-results" className="questions">
                            <div className='container'>
                                <FontAwesomeIcon icon={"times-circle"} className={"close"}
                                                 onClick={this.hideResults}/>
                                <div className={"arrow left"} onClick={self.prevQuestion}>
                                    <FontAwesomeIcon icon={"arrow-left"}/>
                                </div>
                                {this.state.current_test.learnosity === false &&
                                this.state.questions.length > 0 &&
                                this.state.questions.map(function (question) {
                                    questionKey++;
                                    return (
                                        <Question
                                            key={questionKey}
                                            id={questionKey}
                                            ans_image={question.ans_image}
                                            answerArea={question.answerArea}
                                            audio={question.audio}
                                            can_play_audio={self.state.current_test['audio']}
                                            correct={self.correct}
                                            gradeAudio={self.state.gradeAudio}
                                            image={question.image}
                                            question={question.question}
                                            question_id={question.id}
                                            skills={question.taxonomies}
                                            replayAudio={self.replayAudio}
                                            reportError={function () {
                                                self.showReportedError(question.id, null)
                                            }}
                                            showResults={self.state.showResults}
                                            test={self.props.user.active_test.id}
                                            wrong={self.wrong}
                                            result={self.state.question_results[question.id]}
                                            explanation={question.explanation}
                                        />
                                    )
                                })}
                                {this.state.current_test.learnosity === true &&
                                Object.keys(this.state.question_results).length > 0 &&
                                    this.state.learnosity_items.length > 0 &&
                                    <LearnosityAssessmentResults
                                        keyStart={this.state.learnosityResultsKey}
                                        showReportedError={this.showReportedError}
                                        question_results={this.state.question_results}
                                    />
                                }
                                {this.state.question_results.length > 0 && <div className="report-error"/>}
                                <div className={"arrow right"} onClick={self.nextQuestion}>
                                    <FontAwesomeIcon icon={"arrow-left"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    {window.location.pathname.indexOf('/practice') !== -1 && this.props.user.paid_access &&
                        <Assessment
                            dt_config={this.props.dt_config}
                            grades={this.props.grades}
                            history={this.props.appProps.history}
                            number_of_questions={self.state.number_of_questions}
                            setSkills={this.setSkills}
                            skill_id={this.props.appProps.match.params.skill_id}
                            setChildAssessmentsByTest={this.props.extraProps.setChildAssessmentsByTest}
                            resetReportedError={this.resetReportedError}
                            updateUser={this.props.updateUser}
                            user={this.props.user}
                            use_audio={this.state.use_audio}
                        />
                    }
                    {!this.props.user.paid_access && this.props.user.children.hasOwnProperty(this.props.user.active_child) && this.state.showFreePractice &&
                        <FreePractice
                            key={0}
                            child_name={child.name}
                            grade_id={child.grade}
                            grades={this.props.grades}
                            grades_by_id={this.props.grades_by_id}
                            history={this.props.appProps.history}
                            number_of_questions={10}
                            setChildAssessmentsByTest={this.props.extraProps.setChildAssessmentsByTest}
                            showFreePractice={this.showFreeQuickPractice}
                            skill_id={this.props.appProps.match.params.skill_id}
                            updateUser={this.props.updateUser}
                            user={this.props.user}
                        />
                    }
                </div>
            )
        } else {
            return <h3 className={"loading"}>Loading...</h3>;
        }
    }
}

function LearnosityAssessmentResults(props) {
    let i = props.keyStart;

    return (
        Object.keys(props.question_results).map(function (question) {
            i++;
            if (!props.question_results[question].hasOwnProperty('item') ||
                props.question_results[question].item === null) {
                return false;
            }
            let difficulty = '';
            if (props.question_results[question].item.difficulties.length > 0) {
                for (let d in props.question_results[question].item.difficulties) {
                    if (props.question_results[question].item.difficulties.hasOwnProperty(d)) {
                        difficulty += props.question_results[question].item.difficulties[d]['title'] + ' ';
                    }
                }
            }
            return (
                <div key={i} id={"ques-" + props.question_results[question].item.id}
                         className={"ques-cont learnosity ques_id" + props.question_results[question].item.id}>
                    <div data-reference={props.question_results[question].item.reference}
                         className={'learnosity-item ' + props.question_results[question].item.reference}/>
                    <div className={'desc'}>
                        <div className="report-error" onClick={function () {
                            props.showReportedError(null, props.question_results[question].item.reference)
                        }}>
                            Something wrong with this question? Click here to notify us.
                        </div>
                    </div>
                    {difficulty &&
                    <div className={'difficulty'}>
                        <p>Difficulty: <strong>{difficulty}</strong></p>
                    </div>
                    }
                </div>
            );
        })
    );
}

function CustomLabel(props) {
    let { viewBox, value, vertical} = props;
    let x = (viewBox.width + viewBox.x) / 2;
    let y = viewBox.y + viewBox.height - 10;
    let transform = "";
    if (vertical) {
        transform = "rotate(-90)";
        y = (viewBox.width + viewBox.x) / 2;
        x = -((viewBox.y + viewBox.height + 71) / 2) - 10;
    }
    return <text x={x} transform={transform} y={y} textAnchor="start">{value}</text>
}

function CustomTooltip(props) {

    let { active } = props;

    if (active) {
        let { payload, label } = props;
        return (
            <div className="recharts-default-tooltip"
                style={{
                    margin: '15px 0 0 0',
                    padding: '10px',
                    backgroundColor: 'rgb(255, 255, 255)',
                    border: '1px solid rgb(204, 204, 204)',
                    whiteSpace: 'nowrap'
                }}>
                <p>Date: {`${label}`}</p>
                <p className="label">Score: {`${payload[0].value}`}%</p>
                <p className="intro">Total Questions: {`${payload[0].payload.total_questions}`}</p>
            </div>
        );
    }
    return <div/>;
}

function StartPracticeLinks(props) {
    let className = props.slug === 'cogat-form-7' ? 'cogat' : '';
    return (
        <div className={className + ' start-practice'}>
            <div>
                {props.slug === 'cogat-form-7' &&
                <div className="styled-select">
                    <select name='screener'>
                        <option value={'all'}>All CogAT&reg; Questions</option>
                        <option value={'screener'}>CogAT&reg; Screener Only</option>
                    </select>
                </div>
                }
                <div className="styled-select">
                    <select onChange={function(e){
                        props.updateNumberOfQuestions(e.target.value);
                    }} name={"total_questions"} value={props.number_of_questions}>
                        <option value={10}>10 Questions</option>
                        <option value={20}>20 Questions</option>
                        <option value={40}>40 Questions</option>
                    </select>
                </div>
                {props.paid_access === true &&
                <button onClick={props.goToPractice}>
                    <img src={"/img/test-dashboard/start-practice-test.jpg"}
                         className="start-btn" alt={"Start Practice"}/>
                </button>
                }
                {
                    props.slug !== 'cogat-form-7' && props.paid_access === true && props.has_audio === true &&
                    <div style={{padding: '15px'}}>
                        <label><input type="checkbox" name="use_audio" onChange={props.handleUseAudio} checked={props.use_audio} />&nbsp;
                            Enable question audio
                        </label>
                    </div>
                }
                {props.paid_access === false &&
                <a href={WEBSITE_URL + 'buy-now/?redirected=1'} className={"buyNow"}>
                    <img src={"/img/test-dashboard/start-practice-test.jpg"}
                         className="start-btn" alt={"Start Practice"}/>
                </a>
                }
                <div className="clear" />

            </div>
            {props.paid_access === true &&
            <div className={'links-right'}>
                <Link to={"/dashboard/" + props.student + "/printables"}>
                    <FontAwesomeIcon icon={"print"}/>
                    Printable Practice</Link>
                <div className="clear"/>
                <Link to={"/test-prep/" + props.slug + "/practice-questions"}>
                    <FontAwesomeIcon icon={"file-check"}/>
                    Learn How to Prep</Link>
                {
                    props.slug === 'cogat-form-7' && props.paid_access === true && props.has_audio === true &&
                    <div style={{padding: '15px'}}>
                        <label><input type="checkbox" name="use_audio" onChange={props.handleUseAudio} checked={props.use_audio} />&nbsp;
                            Enable question audio
                        </label>
                    </div>
                }
            </div>
            }
        </div>
    );
}

export default TestDashboard;
