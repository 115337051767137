import React, {Component} from 'react';
import './Hero.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Hero extends Component {
    constructor(props)
    {
        super(props);
        this.changeChild = this.changeChild.bind(this);
    }

    changeChild(child_id)
    {
        let self = this;
        this.props.updateChild(child_id, {'active': true}, function(){
            let active_test = false;
            let tests = self.props.user.children[self.props.user.active_child].tests;
            for (let test in tests) {
                if (tests.hasOwnProperty(test)) {
                    if (tests[test]['primary']) {
                        active_test = self.props.tests[tests[test]['test']];
                    }
                }
            }
            self.props.updateUser({'active_test': active_test});
        });
        let ul = document.getElementById('changeDashboard');
        ul.style.display = 'none';
        this.props.appProps.history.push('/');
    }

    render() {
        let children = this.props.user.children;
        let avatar_url = '/img/avatars/ava_logo.png';
        let dash_bg_url = 'url(/img/themes/bnnr_turqBooksClouds_tile.png)';
        let blue_heading = '';
        if (children.hasOwnProperty(this.props.user.active_child) && this.props.showEditButton) {
            let child = children[this.props.user.active_child];
            let avatar = child.avatar;
            if (avatar && avatar !== "") {
                avatar_url = '/img/avatars/' + avatar + '.png';
            }
            let dash_bg = child.dashboard_theme;
            if (dash_bg_url && dash_bg_url !== "") {
                dash_bg_url = 'url(/img/themes/' + dash_bg + '.png)';
            }
            if (dash_bg === null) {
                dash_bg_url = 'url(/img/themes/bnnr_turqBooksClouds_tile.png)';
            }
            if (dash_bg === 'bnnr_clouds_tile' || dash_bg === 'bnnr_booksWhitebg_tile') {
                blue_heading = ' blue-heading';
            }
        }
        let i = 0;

        return (
            <div id="hero" style={{backgroundImage: dash_bg_url}}>
                <div className="profile-pic">
                    <img className="small-logo" src={avatar_url} alt={""} />
                    {this.props.showEditButton === true &&
                    <FontAwesomeIcon icon={"edit"} className={"edit" + blue_heading}
                                     onClick={this.props.avatarShowing}/>
                    }
                </div>
                {this.props.showEditButton &&
                this.props.user.children.length > 0 &&
                this.props.user.active_child >= 0 &&
                <p className={'title' + blue_heading}>{this.props.user.children[this.props.user.active_child].name}'s Dashboard</p>
                }
                <div className={'top-babies'}>
                    <img src={"/img/home-baby-1.png"} alt={""} />
                    <img src={"/img/home-baby-2.png"} alt={""} />
                </div>
                <div className={"hide"}>
                    {this.props.avatars.map(function(avatar) {
                        i++;
                        return <img key={i} src={"/img/avatars/" + avatar + ".png"} alt={""} />;
                    })}
                    {this.props.themes.map(function(theme) {
                        i++;
                        return <img key={i} src={"/img/themes/" + theme + ".png"} alt={""} />;
                    })}
                </div>
            </div>
        );
    }
}

export default Hero;
