import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {MEMBERS_URL, WEBSITE_URL} from "../common/Urls";
const Four04 = require('../common/Four04').Four04;
const Loading = require('../common/Loading').Loading;

class Games extends Component {
    games = {
        'analogy-galaxy': {
            url: WEBSITE_URL + 'analogy-galaxy/',
            padding: '75%'
        },
        'iqfunpack': {
            url: WEBSITE_URL + 'iqfunpack/',
            padding: '48.5%'
        },
        'folding-questions': {
            url: MEMBERS_URL + 'apps/folding-questions?iframe=1',
            padding: '1860px',
            scroll: true
        },
        'gogobrain': {
            url: 'https://app.gogobrain.com',
            padding: '48.5%'
        },
        'iqfuntree': {
            url: WEBSITE_URL + 'iqfuntree/',
            padding: '48.5%'
        },
        'pattern-blast': {
            url: MEMBERS_URL + 'apps/pattern-completion?iframe=1',
            padding: '75%'
        },
        'pattern-puzzlers': {
            url: WEBSITE_URL + 'patterncompletion/step.html',
            padding: '75%'
        },
        'pattern-tiles-galaxy': {
            url: WEBSITE_URL + 'apps/Cordova27-1/PT/www/index.html',
            padding: '62.25%'
        },
        'planet-pattern-tiles': {
            url: MEMBERS_URL + 'apps/planet-pattern-tiles?iframe=1',
            padding: '1020px'
        },
        'space-baby-creator': {
            url: MEMBERS_URL + 'apps/space-baby-creator?iframe=1',
            padding: '1000px',
            scroll: true
        },
    };

    componentDidMount() {
        if (this.props.user.paid_access === false) {
            window.location.href = WEBSITE_URL + 'buy-now/?redirected=1';
        }
    }

    render() {
        let game = this.games[this.props.appProps.match.params.game];
        let scroll = game.scroll ? 'yes' : 'no';
        if (game) {
            return (
                <div className="content-container">
                    <div style={{
                        position: 'relative',
                        width: '100%',
                        paddingBottom: game.padding,
                        maxWidth: '1024px',
                        margin: '0 auto',
                        overflow: 'hidden'
                    }}>
                        <Loading/>
                        <iframe scrolling={scroll} title={this.props.appProps.match.params.game} src={game.url} style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                            border: 'none'
                        }}/>
                    </div>
                </div>
            )
        } else {
            return <Four04 />
        }
    }
}
export default withRouter(Games);
