import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router-dom'

import HowToPrepInteractive from "./HowToPrepInteractive";
import HowToPrepPrintable from "./HowToPrepPrintable";
import HowToPrepLessons from "./HowToPrepLessons";

import './HowToPrep.css';
import Document from "./Document";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Four04 = require('../common/Four04').Four04;
const WEBSITE_URL = require('../common/Urls').WEBSITE_URL;

class HowToPrep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tests: []
        };
        this.settingTest = false;
    }

    componentDidMount() {
        if (this.props.user.accesss_tp === false) {
            window.location.href = WEBSITE_URL + 'buy-now/?redirected=1';
        }
        window.scrollTo(0,0);
        this.setActiveTest();
    }

    componentDidUpdate()
    {
        if (
            this.props.user.active_test &&
            this.props.appProps.match.params.test !== this.props.user.active_test['slug'] &&
            this.props.test_pages_by_slug.hasOwnProperty(this.props.appProps.match.params.test)) {
            this.setActiveTest();
        } else if (Object.keys(this.props.test_pages_by_slug).length > 0 && this.state.tests.length === 0) {
            this.setActiveTest();
        }
    }

    setActiveTest()
    {
        if (Object.keys(this.props.test_pages_by_slug).length !== 0 && Object.keys(this.props.tests_by_id).length > 0 && !this.settingTest) {
            let test_page = this.props.test_pages_by_slug[this.props.appProps.match.params.test];
            let tests = [];
            if (test_page && test_page.hasOwnProperty('tests')) {
                for (let test in test_page['tests']) {
                    if (test_page['tests'].hasOwnProperty(test) &&
                        this.props.tests_by_id.hasOwnProperty(test_page['tests'][test]['id'])
                    ) {
                        tests.push(this.props.tests_by_id[test_page['tests'][test]['id']]);
                    }
                }
            }
            this.setState({
                tests: tests
            });
            this.settingTest = true;
        }
    }

    render() {
        let path = this.props.location.pathname;
        let overview = true;
        let Component = null;
        let isDocument = this.props.appProps.match.params.test === 'document' &&
                Number.isInteger(parseInt(this.props.appProps.match.params.view));
        if (isDocument) {
            return <Document doc={this.props.appProps.match.params.view} user={this.props.user}/>
        }
        if (this.props.appProps.match.params.view === 'practice-games') {
            Component = HowToPrepInteractive;
            overview = false;
        }
        if (this.props.appProps.match.params.view === 'practice-questions' || isDocument) {
            Component = HowToPrepPrintable;
            overview = false;
        }
        if (this.props.appProps.match.params.view === 'parent-lessons') {
            Component = HowToPrepLessons;
            overview = false;
        }
        
        if (this.props.appProps.match.params.test in this.props.test_pages_by_slug || isDocument) {
            let test_page = null;
            let disclaimer = '';
            let slug = '';
            let safeTestShortTitle = '';
            if (!isDocument) {
                test_page = this.props.test_pages_by_slug[this.props.appProps.match.params.test];

                for (let d in test_page['disclaimer']) {
                    if (test_page['disclaimer'].hasOwnProperty(d)) {
                        disclaimer += test_page['disclaimer'][d]['content'];
                    }
                }
                slug = this.props.appProps.match.params.test;
                safeTestShortTitle = <span dangerouslySetInnerHTML={{__html: test_page.title}}/>;
            }

            let backToDashboard = '/';
            if (this.props.user.active_child) {
                backToDashboard = '/dashboard/' + this.props.user.active_child;
            }

            return (
                <div>
                    <div id="test-prep">
                        <div className="wrapper content-container">
                            <div className="dashboard">
                                <Link className={"back-to-dash"} to={ backToDashboard }>
                                    <FontAwesomeIcon icon={'arrow-circle-left'} />Back to Dashboard</Link>
                                <h1 className="green-heading">How to Prep for the {safeTestShortTitle}</h1>
                                <p>We have a multitude of preparation resources for you and your child to tackle
                                    but you may be wondering, where do I begin? We have conveniently assembled
                                    a number of resources for you and your child to review and practice below. </p>
                                <div className="control">
                                    <Link to={"/test-prep/" + slug  + "/practice-questions"}
                                          className={path.indexOf('/practice-questions') !== -1 ? 'active' : ''}>
                                        <FontAwesomeIcon icon={'print'}/>Printable Practice</Link>
                                    <Link to={"/test-prep/" + slug} className={path.indexOf('test-prep')
                                    && path.indexOf('/practice-games') === -1
                                    && path.indexOf('/practice-questions') === -1
                                    && path.indexOf('/parent-lessons') === -1 ? 'active' : ''}>
                                        <FontAwesomeIcon icon={'bars'} />Overview</Link>
                                    <Link to={"/test-prep/" + slug + "/practice-games"}
                                          className={path.indexOf('/practice-games') !== -1 ? 'active' : ''}>
                                        <FontAwesomeIcon icon={'gamepad'}/>Interactive Games</Link>
                                    <Link to={"/test-prep/" + slug + "/parent-lessons"}
                                          className={path.indexOf('/parent-lessons') !== -1 ? 'active' : ''}>
                                        <FontAwesomeIcon icon={'file-alt'}/>Lessons for Parents</Link>
                                </div>
                                {overview === true &&
                                <section
                                    className="overview htp-section"
                                    dangerouslySetInnerHTML={{__html: test_page.overview}}
                                />
                                }
                                {overview === false &&
                                <Component
                                    appProps={this.props.appProps}
                                    deleteDocument={this.props.deleteDocument}
                                    extraProps={this.props.extraProps}
                                    grades={this.props.grades}
                                    tests={this.state.tests}
                                    test_page={test_page}
                                    tests_by_id={this.props.tests_by_id}
                                    user={this.props.user}
                                    updateChild={this.props.updateChild}
                                    updateTest={this.props.updateTest}
                                />
                                }
                                <div className="disclaimer" dangerouslySetInnerHTML={{__html: disclaimer}}/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return <Four04 />
        }
    }
}

export default withRouter(HowToPrep);
