import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Link from '../common/Link'
const WEBSITE_URL = require('../common/Urls').WEBSITE_URL;

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'searchOpened': false
        }
    };

    menuHeight = 0;
    menu;

    toggleMenu = () => {
        if (parseInt(this.menu.style.height) === 0) {
            if (window.innerWidth <= 1100) {
                this.menu.style.height = '240px';
            } else {
                this.menu.style.height = '195px';
            }
            this.menu.style.borderBottomWidth = '1px';
        } else {
            this.menu.style.height = 0;
            this.menu.style.borderBottomWidth = 0;
        }
    };

    componentDidMount() {
        let self = this;
        this.menu = document.getElementById("menu");
        this.menu.style.height = 0;
        this.menu.style.transition = 'height 500ms';

        this.form = document.getElementById("searchForm");
        this.form.addEventListener('submit', function(e){
            e.preventDefault();
        }, false);
        let input = this.form.children[0];
        let button = this.form.children[1];
        let opened = false;
        button.addEventListener('click', function(e) {
            if (!opened) {
                opened = true;
                self.setState({
                    searchOpened: true
                });
                input.style.display = 'inline-block';
                input.focus();
                self.form.style.width = '300px';
                document.body.addEventListener('click', function(e) {
                    let sr = document.getElementById('search-results');
                    if (!self.props.isDescendant(sr, e.target)) {
                        self.form.style.width = '52.6px';
                        input.style.display = 'none';
                        opened = false;
                        self.setState({
                            searchOpened: false
                        });
                        sr.style.display = 'none';
                    }
                }, false);
                this.form.addEventListener('click', function(e) {
                    e.stopPropagation();
                }, false);
            } else {
                //this.form.submit();
            }
        });
        let header = document.querySelector('header');
        if (header) {
            var newMessage = header.querySelector('.new-message');
            newMessage.addEventListener('mouseover', showNewMessage);
            newMessage.addEventListener('touchstart', showNewMessage);
            function showNewMessage() {
                this.querySelector('p').style.display = 'block';
                this.querySelector('p').style.cursor = 'pointer';
                setTimeout(function() {
                    newMessage.querySelector('p').style.display = 'none';
                }, 2000);
            }
            newMessage.addEventListener('click', function() {
                window.location.href = WEBSITE_URL + 'my-account/online-classes/';
            });
        }

    }

    render() {
        let opened = '';
        if (this.state.searchOpened) {
            opened = ' opened'
        } else {
            opened = ''
        }
        let camp_link = this.props.user.paid_access ? WEBSITE_URL + 'camps/' : WEBSITE_URL + 'buy-now/?redirected=1&camps=1';
        return (
            <header>
                <div className="logo left">
                    <Link to="/">
                        <img src={'//cdn.testingmom.com/img/seven/logo.png'} srcSet={'//cdn.testingmom.com/img/seven/logo.png'}
                             height={50} alt={'TestingMom.com'} className="logo-1" />
                        <img src={'//cdn.testingmom.com/img/seven/logo-3.png'} srcSet={'//cdn.testingmom.com/img/seven/logo-3.png'}
                             height={50} alt={'TestingMom.com'} className="logo-3 hide" />
                    </Link>
                </div>
                <menu className="right">
                    <div className="new-message">
                        <FontAwesomeIcon className={'star'} icon={'star'} />
                        <p>New Class Message</p>
                    </div>
                    <div id="menuButton" className="right" onClick={this.toggleMenu}>
                        <span>Menu</span>
                        <span>
                            <FontAwesomeIcon icon="bars" className="menu-bars" />
                        </span>
                    </div>
                    <ul id="menu">
                        <li>
                            <p>Hi{this.props.user.first_name && ' ' + this.props.user.first_name},</p>
                        </li>
                        <li>
                            <a href={WEBSITE_URL + 'login/?action=logout'}>Sign out: {this.props.user.email}</a>
                        </li>
                        <li>
                            <a href={WEBSITE_URL + 'my-account/contact-support/'}>Contact Us</a>
                        </li>
                        <li>
                            <a href={WEBSITE_URL + 'my-account/membership/'}>Change Password</a>
                        </li>
                        <li>
                            <a href={WEBSITE_URL + 'my-account/email-preferences/'}>Email Preferences</a>
                        </li>
                        <li>
                            <a href={WEBSITE_URL + 'my-account/membership/'}>My Membership</a>
                        </li>
                        <li>
                            <a href={WEBSITE_URL + "my-account/online-classes/"}>My Online Classes <span
                                className="new-message">
                                    <FontAwesomeIcon icon={"envelope"} className={'env'} />
                                    <FontAwesomeIcon icon={"star"} className={'star'} />
                                </span></a>
                        </li>
                        <li><Link to={camp_link} className={"right btn"}>
                            Live Online Classes</Link></li>
                    </ul>
                </menu>
                <div className="right questions-call">
                    <p><FontAwesomeIcon icon={"phone"} className={'env'} />
                    <span>Questions?</span>
                    <FontAwesomeIcon icon={"envelope"} className={'env'} />
                    </p>
                    <a href="tel:18776096203">877-609-6203</a>
                </div>
                <form className={"right" + opened} id="searchForm">
                    <input type="text" name="search" onKeyUp={this.props.search}/>
                    <button>
                        <FontAwesomeIcon icon="search" className="search" />
                    </button>
                </form>
                <Link to={WEBSITE_URL + "tutoring/"} className={"right btn tutoring"}>
                    Online Tutoring
                </Link>
                <Link to={WEBSITE_URL + 'camps'} className={"right btn"}>Live Online Classes</Link>
                <Link to={"/dashboard/0/"} className={"right btn interactive"}>
                    Interactive Questions
                </Link>
                <div id="search-results">
                    <ul>{this.props.searchLinks.map(function(link){
                        return link;
                    })}</ul>
                </div>
                <div className="clear"/>
            </header>
        );
    }
}

export default Header;
