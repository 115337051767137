import React, {Component} from 'react';
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class SkillResults extends Component {
    render () {
        let self = this;
        let squares = [];
        let correct = 0;
        for (let n = 0; n < self.props.skills[self.props.keyNumber].questions; n++) {
            let className = 'square';
            if (correct < self.props.skills[self.props.keyNumber].correct) {
                correct++;
                className += ' correct';
            }
            if (self.props.highScore) {
                className += ' yellow';
            }
            squares.push(<div key={n} className={className} />);
        }
        if (self.props.highScore) {
            squares.push(<div key={self.props.skills[self.props.keyNumber].questions} className={'trophy'}><FontAwesomeIcon icon="trophy-alt"/></div>);
        }

        return (
            <div key={self.props.keyNumber} className="results-row"
                 onClick={self.props.changeSelectedSkill}>
                <div>{self.props.skills[self.props.keyNumber].title}</div>
                <div className={'squares'}>{squares}</div>
                <div onClick={self.props.showResults}>
                    {self.props.skills[self.props.keyNumber].correct + '/'
                    +  self.props.skills[self.props.keyNumber].questions}
                    <FontAwesomeIcon icon="file-search" className={self.props.showFileSearch} />
                </div>
            </div>
        )
    }
}

SkillResults.propTypes = {
    skills: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.number
    ]),
    class: PropTypes.string,
    keyNumber: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.number
    ]),
    showFileSearch: PropTypes.string
};

export default SkillResults;
