import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './TestDirectory.css'
const ApiFetch = require('../common/ApiFetch').ApiFetch;
const Loading = require('../common/Loading').Loading;

class TestDirectoryStates extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lesson: 1,
            lessons: {}
        };
        this.changeLesson = this.changeLesson.bind(this);
    }

    componentDidMount() {
        let self = this;
        ApiFetch('option/?limit=1&name=state_lessons').then(function(res) {
            if (typeof res !== 'undefined' &&
                res.hasOwnProperty('results') &&
                res['results'].length
            ) {
                self.setState({
                    'lessons': JSON.parse(res['results'][0]['option_value'])
                });
            }
        });
    }

    changeLesson(e) {
        this.setState({
            lesson: parseInt(e.target.value)
        })
    }

    render() {
        return (
            <div className="about content-container">
                <h2>State Testing</h2>
                <p>Select a state to view tests related to that state.</p>
                <div className="vmap-states">
                    <ul className="col25">
                        <li><Link to="/test-prep/states-common-core/Alabama">Alabama</Link></li>
                        <li><Link to="/test-prep/states-common-core/Alaska">Alaska</Link></li>
                        <li><Link to="/test-prep/states-common-core/Arizona">Arizona</Link></li>
                        <li><Link to="/test-prep/states-common-core/Arkansas">Arkansas</Link></li>
                        <li><Link to="/test-prep/states-common-core/California">California</Link></li>
                        <li><Link to="/test-prep/states-common-core/Colorado">Colorado</Link></li>
                        <li><Link to="/test-prep/states-common-core/Connecticut">Connecticut</Link></li>
                        <li><Link to="/test-prep/states-common-core/Delaware">Delaware</Link></li>
                        <li><Link to="/test-prep/states-common-core/District Of Columbia">District Of
                            Columbia</Link></li>
                        <li><Link to="/test-prep/states-common-core/Florida">Florida</Link></li>
                        <li><Link to="/test-prep/states-common-core/Georgia">Georgia</Link></li>
                        <li><Link to="/test-prep/states-common-core/Hawaii">Hawaii</Link></li>
                        <li><Link to="/test-prep/states-common-core/Idaho">Idaho</Link></li>
                    </ul>
                    <ul className="col25">
                        <li><Link to="/test-prep/states-common-core/Illinois">Illinois</Link></li>
                        <li><Link to="/test-prep/states-common-core/Indiana">Indiana</Link></li>
                        <li><Link to="/test-prep/states-common-core/Iowa">Iowa</Link></li>
                        <li><Link to="/test-prep/states-common-core/Kansas">Kansas</Link></li>
                        <li><Link to="/test-prep/states-common-core/Kentucky">Kentucky</Link></li>
                        <li><Link to="/test-prep/states-common-core/Louisiana">Louisiana</Link></li>
                        <li><Link to="/test-prep/states-common-core/Maine">Maine</Link></li>
                        <li><Link to="/test-prep/states-common-core/Maryland">Maryland</Link></li>
                        <li><Link to="/test-prep/states-common-core/Massachusetts">Massachusetts</Link></li>
                        <li><Link to="/test-prep/states-common-core/Michigan">Michigan</Link></li>
                        <li><Link to="/test-prep/states-common-core/Minnesota">Minnesota</Link></li>
                        <li><Link to="/test-prep/states-common-core/Mississippi">Mississippi</Link></li>
                        <li><Link to="/test-prep/states-common-core/Missouri">Missouri</Link></li>
                    </ul>
                    <ul className="col25">
                        <li><Link to="/test-prep/states-common-core/Montana">Montana</Link></li>
                        <li><Link to="/test-prep/states-common-core/Nebraska">Nebraska</Link></li>
                        <li><Link to="/test-prep/states-common-core/Nevada">Nevada</Link></li>
                        <li><Link to="/test-prep/states-common-core/New Hampshire">New Hampshire</Link></li>
                        <li><Link to="/test-prep/states-common-core/New Jersey">New Jersey</Link></li>
                        <li><Link to="/test-prep/states-common-core/New Mexico">New Mexico</Link></li>
                        <li><Link to="/test-prep/states-common-core/New York">New York</Link></li>
                        <li><Link to="/test-prep/states-common-core/North Carolina">North Carolina</Link></li>
                        <li><Link to="/test-prep/states-common-core/North Dakota">North Dakota</Link></li>
                        <li><Link to="/test-prep/states-common-core/Ohio">Ohio</Link></li>
                        <li><Link to="/test-prep/states-common-core/Oklahoma">Oklahoma</Link></li>
                        <li><Link to="/test-prep/states-common-core/Oregon">Oregon</Link></li>
                        <li><Link to="/test-prep/states-common-core/Pennsylvania">Pennsylvania</Link></li>
                    </ul>
                    <ul className="col25">
                        <li><Link to="/test-prep/states-common-core/Rhode Island">Rhode Island</Link></li>
                        <li><Link to="/test-prep/states-common-core/South Carolina">South Carolina</Link></li>
                        <li><Link to="/test-prep/states-common-core/South Dakota">South Dakota</Link></li>
                        <li><Link to="/test-prep/states-common-core/Tennessee">Tennessee</Link></li>
                        <li><Link to="/test-prep/states-common-core/Texas">Texas</Link></li>
                        <li><Link to="/test-prep/states-common-core/Utah">Utah</Link></li>
                        <li><Link to="/test-prep/states-common-core/Vermont">Vermont</Link></li>
                        <li><Link to="/test-prep/states-common-core/Virginia">Virginia</Link></li>
                        <li><Link to="/test-prep/states-common-core/Washington">Washington</Link></li>
                        <li><Link to="/test-prep/states-common-core/West Virginia">West Virginia</Link></li>
                        <li><Link to="/test-prep/states-common-core/Wisconsin">Wisconsin</Link></li>
                        <li><Link to="/test-prep/states-common-core/Wyoming">Wyoming</Link></li>
                    </ul>
                    <div className={"clear"} />
                </div>
                <h2>About State Standards and Achievement Tests</h2>
                {Object.keys(this.state.lessons).length > 0 &&
                <div>
                    <div className="lesson-nav" id="tabs">
                        <button value={1} onClick={this.changeLesson} className={this.state.lesson === 1 ? 'tablink active' : 'tablink'}>
                            Lesson 1 - { this.state.lessons.title_1 }</button>
                        <button value={2} onClick={this.changeLesson} className={this.state.lesson === 2 ? 'tablink active' : 'tablink'}>
                            Lesson 2 - { this.state.lessons.title_2 }</button>
                        <button value={3} onClick={this.changeLesson} className={this.state.lesson === 3 ? 'tablink active' : 'tablink'}>
                            Lesson 3 - { this.state.lessons.title_3 }</button>
                        <button value={4} onClick={this.changeLesson} className={this.state.lesson === 4 ? 'tablink active' : 'tablink'}>
                            Lesson 4 - { this.state.lessons.title_4 }</button>
                        {this.state.lessons.title_5 && this.state.lessons.content_5 &&
                        <button value={5} onClick={this.changeLesson}
                                className={this.state.lesson === 5 ? 'tablink active' : 'tablink'}>
                            Lesson 5 - {this.state.lessons.title_5}</button>
                        }
                    </div>
                    <div className="lesson-content">
                        {this.state.lesson === 1 && this.state.lessons.content_1 &&
                        <section id="lesson1" className="tab-pane active" role="tabpanel" aria-labelledby="">
                            <h3>Lesson 1 - { this.state.lessons.title_1 }</h3>
                            <div dangerouslySetInnerHTML={{__html: this.state.lessons.content_1}} />
                        </section>
                        }
                        {this.state.lesson === 2 && this.state.lessons.content_2 &&
                        <section id="lesson2" className="tab-pane" role="tabpanel" aria-labelledby="">
                            <h3>Lesson 2 - { this.state.lessons.title_2 }</h3>
                            <div dangerouslySetInnerHTML={{__html: this.state.lessons.content_2}} />
                        </section>
                        }
                        {this.state.lesson === 3 && this.state.lessons.content_3 &&
                        <section id="lesson3" className="tab-pane" role="tabpanel" aria-labelledby="">
                            <h3>Lesson 3 - { this.state.lessons.title_3 }</h3>
                            <div dangerouslySetInnerHTML={{__html: this.state.lessons.content_3}} />
                        </section>
                        }
                        {this.state.lesson === 4 && this.state.lessons.content_4 &&
                        <section id="lesson4" className="tab-pane" role="tabpanel" aria-labelledby="">
                            <h3>Lesson 4 - { this.state.lessons.title_4 }</h3>
                            <div dangerouslySetInnerHTML={{__html: this.state.lessons.content_4}} />
                        </section>
                        }
                        {this.state.lesson === 5 && this.state.lessons.content_5 &&
                        <section id="lesson5" className="tab-pane" role="tabpanel" aria-labelledby="">
                            <h3>Lesson 5 - { this.state.lessons.title_5 }</h3>
                            <div dangerouslySetInnerHTML={{__html: this.state.lessons.content_5}} />
                        </section>
                        }
                    </div>
                </div>
                }
                {Object.keys(this.state.lessons).length === 0 && <div className={"loading-container"}><Loading /></div>}
                <div className="clear" />
            </div>
        )
    }
}

export default TestDirectoryStates;