import React, {Component} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Loading} from "../common/Loading";
import DeviceOrientation, { Orientation } from '../common/DeviceOrientation';
import ReportedError from "./ReportedError";

const ApiFetch = require('../common/ApiFetch').ApiFetch;
const Question = require('./Question').Question;
const SetAnswerArea = require('./Question').SetAnswerArea;
const WEBSITE_URL = require('../common/Urls').WEBSITE_URL;

class FreePractice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            answers: {},
            answerCount: 1,
            answered_ids: [],
            correctCount: 0,
            currentQuestion: 0,
            finished: false,
            go_to_results: false,
            grade: this.props.user.children[this.props.user.active_child].grade,
            gradeAudio: {},
            highest_score: 0.5,
            highest_score_id: 0,
            questions: [],
            questionsFinished: false,
            question_results: {},
            results: [],
            selected_skill: null,
            show_results: false,
            skills: {},
            testimonials: [],
            testimonialsDisplayed: false,
            testimonialNumber: -1,
            time: '',
            totalCorrect: 0,
        };

        this.correct = this.correct.bind(this);
        this.displayTestimonials = this.displayTestimonials.bind(this);
        this.finishGame = this.finishGame.bind(this);
        this.finishQuestions = this.finishQuestions.bind(this);
        this.getTestimonials = this.getTestimonials.bind(this);
        this.getQuestions = this.getQuestions.bind(this);
        this.goToResults = this.goToResults.bind(this);
        this.installGame = this.installGame.bind(this);
        this.nextResult = this.nextResult.bind(this);
        this.prevResult = this.prevResult.bind(this);
        this.reloadQuestions = this.reloadQuestions.bind(this);
        this.resetQuestions = this.resetQuestions.bind(this);
        this.setSkills = this.setSkills.bind(this);
        this.wrong = this.wrong.bind(this);
        this.questionsLoaded = false;
    }

    componentDidMount()
    {
        this.getQuestions();
    }

    getQuestions()
    {
        let self = this;
        if (this.props.grades && this.state.grade && !this.questionsLoaded) {
            this.questionsLoaded = true;
            let childGrade = this.props.grades_by_id[this.state.grade].numeric;
            if (childGrade < 0) {
                childGrade = 0;
            }
            ApiFetch('question/?grade=' + childGrade + '&tm7=1&app=348&random=1&status=1' , 'GET').then(
                function (res) {
                    if (res['count']) {
                        let gradeAudio = {};
                        for (let i in res['results']) {
                            if (res['results'].hasOwnProperty(i)) {
                                if (res['results'][i]['grade'] === self.state.grade) {
                                    self.learnosity = res['results'][i]['learnosity'];
                                }
                                gradeAudio[res['results'][i]['grade']] = res['results'][i]['use_audio'];
                            }
                        }
                        self.installGame(res, gradeAudio);
                        self.starttime = new Date();
                        let newIds = self.state.answered_ids.concat(Object.keys(res['results']).map(key => res['results'][key]['id']).join(','));
                        self.setState({
                            'answered_ids': newIds
                        })
                    }
                }
            );
        }
    }

    reloadQuestions()
    {
        let self = this;
        let childGrade = 0;
        if (this.props.grades_by_id.hasOwnProperty(this.state.grade)) {
            childGrade = this.props.grades_by_id[this.state.grade].numeric;
        }
        if (childGrade < 0) {
            childGrade = 0;
        }
        let data = {
            'grade': childGrade,
            'limit': 10,
            'random': 1,
            'status': 1,
            'app': 348,
            'tm7': 1,
            'exclude': self.state.answered_ids,
        };
        let queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        ApiFetch('question/?' + queryString, 'GET').then(
            function (res2) {
                delete data['exclude'];
                if (res2['count']) {
                    let gradeAudio = {};
                    for (let i in res2['results']) {
                        if (res2['results'].hasOwnProperty(i)) {
                            if (res2['results'][i]['grade'] === self.state.grade) {
                                self.learnosity = res2['results'][i]['learnosity'];
                            }
                            gradeAudio[res2['results'][i]['grade']] = res2['results'][i]['use_audio'];
                        }
                    }
                    self.installGame(res2, gradeAudio);
                    self.starttime = new Date();
                    let newIds = self.state.answered_ids.concat(Object.keys(res2['results']).map(key => res2['results'][key]['id']).join(','));
                    self.setState({
                        'answered_ids': newIds,
                        'results': []
                    })
                }
            }
        );
    }

    installGame(results, gradeAudio)
    {
        this.setState({
            questions: results['results'],
            gradeAudio: gradeAudio
        }, function() {
            SetAnswerArea('questions');
        });
    }

    showReportedError() {
        let re = document.getElementById('reportedError');
        if (re) {
            re.style.display = 'block';
        }
    }

    correct(e)
    {
        let self = this;
        let question = document.getElementById('ques-' + this.state.currentQuestion);
        let correct_answers = question.getElementsByClassName('correct-answer');
        if (correct_answers.length > this.state.answerCount) {
            correct_answers[this.state.answerCount - 1].style.display = 'none';
            correct_answers[this.state.answerCount].style.display = 'block';
            this.setState({
                answerCount: this.state.answerCount + 1,
            });
        } else {
            let dims = e.target.getBoundingClientRect();
            let posx = e.clientX - dims.left;
            let posy = e.clientY - dims.top;
            this.state.results.push({
                'question': question.dataset.id,
                'correct': true,
                'answer_posx': (posx / dims.width) * 100,
                'answer_posy': (posy / dims.height) * 100,
            });
            if (this.state.currentQuestion < this.state.questions.length - 1) {
                this.setState({
                    currentQuestion: this.state.currentQuestion + 1,
                    correctCount: this.state.correctCount + 1,
                }, function(){
                    self.nextQuestion(question);
                });
            } else {
                this.setState({
                    currentQuestion: this.state.currentQuestion + 1,
                    correctCount: this.state.correctCount + 1,
                }, self.finishGame);
            }
        }
    }

    wrong(e)
    {
        let self = this;
        let question = document.getElementById('ques-' + this.state.currentQuestion);
        let dims = e.target.getBoundingClientRect();
        let posx = e.clientX - dims.left;
        let posy = e.clientY - dims.top;
        this.state.results.push({
            'question': question.dataset.id,
            'correct': false,
            'answer_posx': (posx / dims.width) * 100,
            'answer_posy': (posy / dims.height) * 100,
        });
        this.setState({
            currentQuestion: this.state.currentQuestion + 1
        }, function() {
            if (self.state.currentQuestion < self.state.questions.length) {
                self.nextQuestion(question);
            } else {
                self.finishGame();
            }
        });
    }

    nextQuestion(question)
    {
        if (question) {
            question.style.display = 'none';
            let current_audio = question.getElementsByTagName('audio');
            if (current_audio.length) {
                current_audio[0].pause();
                current_audio[0].currentTime = 0;
            }
            let next = document.getElementById('ques-' + (this.state.currentQuestion));
            if (next) {
                next.style.display = 'block';
            }
            let audio = next.getElementsByTagName('audio');
            if (audio.length) {
                audio[0].play();
            }
            SetAnswerArea('questions');
            let re = document.getElementById('reportedError');
            if (re) {
                re.getElementsByClassName('form')[0].className = 'form';
                re.getElementsByClassName('submitted')[0].className = 'submitted hide';
            }
        }
    }

    startGame()
    {
        let starts = document.getElementsByClassName('start');
        if (starts.length) {
            starts[0].style.display = 'none';
        }
        let overlays = document.getElementsByClassName('free-practice-overlay');
        if (overlays.length) {
            overlays[0].style.display = 'none';
        }
        let question = document.getElementById('ques-0');
        let audio = question.getElementsByTagName('audio');
        if (audio.length) {
            audio[0].play();
        }
        SetAnswerArea('questions');
    }

    finishGame() {
        let self = this;
        let current_audio = document.getElementById('ques-' + (this.state.currentQuestion - 1)).getElementsByTagName('audio');
        if (current_audio.length) {
            current_audio[0].pause();
            current_audio[0].currentTime = 0;
        }
        self.setState({
            finished: true,
            currentQuestion: 0
        }, function(){
            let overlays = document.getElementsByClassName('free-practice-overlay');
            if (overlays.length > 0) {
                overlays[0].style.display = 'block';
            }
        });

        let path = 'user-child-assessment/';

        for (let q in self.state.questions) {
            if (self.state.questions.hasOwnProperty(q)) {
                let skills = [];
                for (let n in self.state.results) {
                    if (parseInt(self.state.results[n]['question']) === parseInt(self.state.questions[q].id)) {
                        for (let t in self.state.questions[q].taxonomies) {
                            if (
                                self.state.questions[q].taxonomies.hasOwnProperty(t) &&
                                self.state.questions[q].taxonomies[t].taxonomy === 'skill'
                            ) {
                                skills.push(self.state.questions[q].taxonomies[t].id);
                            }
                        }
                        self.state.results[n]['skills'] = skills;
                    }
                }
            }
        }

        let data = {
            questions: this.state.results,
            child: this.props.user.children[this.props.user.active_child].id,
            grade: this.state.grade,
            total_questions: this.state.questions.length,
            total_correct: this.state.correctCount,
            activity_id: 'Free Questions'
        };

        ApiFetch(path, 'POST', data);

        let newTotalCorrect = self.state.totalCorrect + self.state.correctCount;
        self.setState({
            show_results: true,
            totalCorrect: newTotalCorrect
        }, function(){
            SetAnswerArea('questions-results');
        });
        // });
        self.showResults();
        self.getTestimonials();
    }

    goToResults()
    {
        this.setState({
            "go_to_results": true
        });
    }

    showResults(key) {
        this.current_key = key;
        this.showQuestions();
    }

    showQuestions()
    {
        let i = 0;
        i++;
        let questions = this.state.questions[i];
        let question_id = questions.id;
        let question_html = document.getElementsByClassName('ques_id' + question_id);
        if (questions) {
            question_html[0].style.display = 'block';
        }
    }

    setSkills()
    {
        let self = this;
        let question_results = {};
        let skills = {};
        let highest_score = this.state.highest_score;
        let highest_score_id = this.state.highest_score_id;
        let time = this.state.time;
        if (self.props.child_name && self.props.grade_id) {
            let questions = this.state.questions;
            for (let q in questions) {
                if (questions.hasOwnProperty(q)) {
                    let question_skills = questions[q]['taxonomies'];
                    self.state.results[questions[q]['question']] = questions[q];
                    for (let s in question_skills) {
                        if (
                            question_skills.hasOwnProperty(s) &&
                            question_skills[s]['parent'] === null &&
                            question_skills[s]['taxonomy'] === 'skill'
                        ) {
                            if (!skills.hasOwnProperty(question_skills[s]['id'])) {
                                skills[question_skills[s]['id']] = {
                                    title: question_skills[s]['title'],
                                    correct: 0,
                                    questions: 0,
                                    score: 0,
                                    highest_score: false,
                                    question_ids: [],
                                    description: question_skills[s]['description']
                                }
                            }
                            skills[question_skills[s]['id']]['question_ids'].push(questions[q]['question']);
                            if (self.state.results[q]['correct']) {
                                skills[question_skills[s]['id']]['correct']++;
                            }
                            skills[question_skills[s]['id']]['questions']++;
                            skills[question_skills[s]['id']]['score'] =
                                skills[question_skills[s]['id']]['correct'] /
                                skills[question_skills[s]['id']]['questions'];
                        }
                    }
                }
            }
            ApiFetch('question/?limit=100&pk=' + Object.keys(self.state.results).join(',')).then(function (res) {
                self.setState({
                    question_results: question_results
                });
            });
        }
        for (let s in skills) {
            if (skills.hasOwnProperty(s) && skills[s]['score'] > highest_score) {
                highest_score = skills[s]['score'];
                highest_score_id = s;
            }
        }
        if (skills.hasOwnProperty(highest_score_id)) {
            skills[highest_score_id]['highest_score'] = true;
        }
        this.setState({
            highest_score: highest_score,
            highest_score_id: highest_score_id,
            skills: skills,
            time: time,
        });
    }

    resetQuestions()
    {
        this.questionsLoaded = false;
        this.reloadQuestions();
        this.setState({
            answers: {},
            answerCount: 1,
            correctCount: 0,
            currentQuestion: 0,
            finished: false,
            go_to_results: false,
            highest_score: 0.5,
            highest_score_id: 0,
            questions: [],
            question_results: {},
            results: [],
            selected_skill: null,
            show_results: false,
            skills: {},
            time: '',
            testimonialsDisplayed: false
        })
    }

    finishQuestions()
    {
        this.setState({
            'questionsFinished': true
        })
    }

    getTestimonials()
    {
        let self = this;
        let i = self.state.testimonialNumber;
        ApiFetch('free-questions/' , 'GET').then(
            function (res) {
                i++;
                self.setState({
                    'testimonials': res['testimonials'][i],
                    'testimonialNumber': i
                });
            }
        );
    }

    displayTestimonials()
    {
        this.setState({
            testimonialsDisplayed: true
        })
    }

    nextResult()
    {
        let question = document.getElementById('result-' + this.state.currentQuestion);
        if (question) {
            question.style.display = 'none';

            let current_audio = question.getElementsByTagName('audio');
            if (current_audio.length) {
                current_audio[0].pause();
                current_audio[0].currentTime = 0;
            }
        }
        let next = {};
        if (this.state.currentQuestion < this.state.questions.length - 1) {
            this.setState({
                currentQuestion: this.state.currentQuestion + 1
            });
            next = document.getElementById('result-' + (this.state.currentQuestion + 1));
        } else {
            this.setState({
                currentQuestion: 0
            });
            next = document.getElementById('result-0');
        }
        if (next) {
            next.style.display = 'block';
        }
        SetAnswerArea('questions-results');
        let re = document.getElementById('reportedError');
        if (re) {
            re.getElementsByClassName('form')[0].className = 'form';
            re.getElementsByClassName('submitted')[0].className = 'submitted hide';
        }
    }

    prevResult()
    {
        let question = document.getElementById('result-' + this.state.currentQuestion);
        if (question) {
            question.style.display = 'none';
        }
        let current_audio = question.getElementsByTagName('audio');
        if (current_audio.length) {
            current_audio[0].pause();
            current_audio[0].currentTime = 0;
        }
        let prev = {};
        if (this.state.currentQuestion === 0) {
            this.setState({
                currentQuestion: 9
            });
            prev = document.getElementById('result-9');
        } else {
            this.setState({
                currentQuestion: this.state.currentQuestion - 1
            });
            prev = document.getElementById('result-' + (this.state.currentQuestion - 1));
        }
        if (prev) {
            prev.style.display = 'block';
        }
        SetAnswerArea('questions-results');
        let re = document.getElementById('reportedError');
        if (re) {
            re.getElementsByClassName('form')[0].className = 'form';
            re.getElementsByClassName('submitted')[0].className = 'submitted hide';
        }
    }

    replayAudio(key) {
        let audio = document.getElementById("question_audio" + key);
        audio.pause();
        audio.currentTime = 0;
        audio.play();
    }

    render () {
        let self = this;
        let i = -1;
        let question_id = 0;
        if (this.state.questions.length > 0 && this.state.questions.hasOwnProperty(this.state.currentQuestion)) {
            question_id = this.state.questions[this.state.currentQuestion].id;
        }
        let play_audio = false;
        if (this.props.grades_by_id.hasOwnProperty(this.state.grade)) {
            play_audio = this.props.grades_by_id[this.state.grade].numeric < 3;
        }
        return (
            <div id="assessment" className="assessment free-practice">
                <DeviceOrientation lockOrientation={'landscape'}>
                    <Orientation orientation='landscape' alwaysRender={true}>
                        {this.state.show_results === false &&
                        <div id="questions" className="questions">
                            <div className='container'>
                                <FontAwesomeIcon icon={"times-circle"} className={"close"}
                                                 onClick={this.props.showFreePractice}/>
                                {this.state.questions.length > 0 && this.state.finished === false
                                && this.state.questions.map(function (question) {
                                    i++;
                                    return (
                                        <Question
                                            key={i}
                                            id={i}
                                            ans_image={question.ans_image}
                                            answerArea={question.answerArea}
                                            audio={question.audio}
                                            can_play_audio={play_audio}
                                            correct={self.correct}
                                            gradeAudio={self.state.gradeAudio}
                                            image={question.image}
                                            question={question.question}
                                            question_id={question.id}
                                            replayAudio={self.replayAudio}
                                            reportError={self.showReportedError}
                                            skills={question.taxonomies}
                                            test={self.props.user.active_test.id}
                                            wrong={self.wrong}
                                        />
                                    )
                                })}
                            </div>
                            <div className="free-practice-overlay">
                                {this.state.finished === true && <Loading/>}
                                {this.state.questions.length === 0 && <Loading/>}
                                {this.state.questions.length > 0 &&
                                <div className="message-overlay start">
                                    <section>
                                        <h2 className="green-heading">General Instructions</h2>
                                        <p>Read each question carefully and select an answer.
                                            Note that you cannot move forward or backwards in the practice.</p>
                                    </section>
                                    <p onClick={this.startGame}>Start</p>
                                </div>
                                }
                            </div>
                        </div>
                        }
                        {this.state.show_results === true && this.state.questionsFinished === false &&
                        <div id="assessment-results" className="results">
                            <div id="questions-results" className="questions">
                                <div className='container'>
                                    <FontAwesomeIcon icon={"times-circle"} className={"close"}
                                                     onClick={this.props.showFreePractice} />
                                    <p className="correct-total">You got {this.state.correctCount} out of 10 correct!</p>
                                    {this.state.answered_ids.length < 10 && this.state.testimonialsDisplayed === false &&
                                    <button className="next10" onClick={this.displayTestimonials}>Next</button>
                                    }
                                    {this.state.answered_ids.length < 10 && this.state.testimonialsDisplayed === true &&
                                    <button className="next10" onClick={this.resetQuestions}>Next</button>
                                    }
                                    {this.state.answered_ids.length >= 10 &&
                                    <button className="next10" onClick={this.finishQuestions}>Finish</button>
                                    }
                                    <div style={{overflowX: "auto"}}>
                                        <div>
                                            {self.state.testimonialsDisplayed === false &&
                                            <div className={"arrow left"} onClick={self.prevResult}>
                                                <FontAwesomeIcon icon={"arrow-left"}/>
                                            </div>
                                            }
                                            {self.state.testimonialsDisplayed === false &&
                                            <div style={{backgroundColor: '#fff'}}>
                                                {self.state.questions.map(function (key) {
                                                    i++;
                                                    return (
                                                        <Question
                                                            key={i}
                                                            id={i}
                                                            ans_image={key.ans_image}
                                                            answerArea={key.answerArea}
                                                            audio={key.audio}
                                                            can_play_audio={play_audio}
                                                            freeQuestions={true}
                                                            image={key.image}
                                                            question={key.question}
                                                            question_id={key.id}
                                                            result={self.state.results[i]}
                                                            replayAudio={self.replayAudio}
                                                            reportError={self.showReportedError}
                                                        />
                                                    )
                                                })}
                                            </div>
                                            }
                                            {self.state.testimonialsDisplayed === false &&
                                            <div className={"arrow right"} onClick={self.nextResult}>
                                                <FontAwesomeIcon icon={"arrow-left"}/>
                                            </div>
                                            }
                                            {self.state.testimonialsDisplayed === true &&
                                                <div id="testimonial">
                                                    <div dangerouslySetInnerHTML={{__html: self.state.testimonials.text}}/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {this.state.show_results === true && this.state.questionsFinished === true &&
                        <div id="assessment-results" className="assessment results">
                            <div id="questions-results" className="questions">
                                <div className='container finished100'>
                                    <FontAwesomeIcon icon={"times-circle"} className={"close"}
                                                     onClick={this.props.showFreePractice} />
                                    <p className="correct-total">You got {this.state.totalCorrect} out of 100 correct!</p>
                                    <div className="complete">
                                        <p>You've completed the free 100 questions!</p>
                                        <p>Sign up for Testing Mom today</p>
                                    </div>
                                    <a href={WEBSITE_URL + 'buy-now/'} className="green-btn btn buy-now">Buy Now </a>
                                </div>
                            </div>
                        </div>
                        }
                    </Orientation>
                    <Orientation orientation='portrait' alwaysRender={false}>
                        <div className="overlay">
                            <div className="message-overlay" style={{textAlign: 'center'}}>
                                <FontAwesomeIcon icon={'sync-alt'} style={{fontSize: '40px'}}/>
                                <br/><br/>
                                <p>Practice works best in landscape mode please rotate your device.</p>
                            </div>
                        </div>
                    </Orientation>
                </DeviceOrientation>
                <ReportedError
                    reference=''
                    question_id={question_id}
                />
            </div>
        )
    }
}

export default FreePractice;
