import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './Lessons.css';

const WEBSITE_URL = require('../common/Urls').WEBSITE_URL;

function Videos(props) {
    let i = 0;
    return (
        props.videos &&
        <div className="collapsible lesson" id={"collapsible1000"} key={i}>
            <div className="collapsible-header" onClick={function () {
                    props.openLesson(1000)
                }}>
                {props.test_title} Webinars
                <span>
                    <FontAwesomeIcon icon={"caret-down"}/>
                </span>
            </div>
            <div className="collapsible-content videos">
                {props.videos.map(function(video) {
                    i++;
                    if (video.video_id !== null) {
                        return (
                        <div key={i}>
                            <p>{video.title}</p>
                            <div className="videoWrapper">
                                {video.video_type === 'vimeo' &&
                                <iframe
                                    src={"https://player.vimeo.com/video/" + video.video_id}
                                    frameBorder="0" width="720" height="400" allow="autoplay"
                                    allowFullScreen title={ video.title }></iframe>
                                }
                                {video.video_type === 'youtube' &&
                                <iframe frameBorder="0" allowFullScreen
                                        title={ video.title }
                                        src={"https://www.youtube.com/embed/" + video.video_id +
                                        "?enablejsapi=1&amp;origin=http%3A%2F%2Fdev.testingmom.com&amp;widgetid=1"}>
                                </iframe>
                                }
                            </div>
                        </div>
                        )
                    } else {
                        return null
                    }
                })}
            </div>
        </div>
    )
}

class Lessons extends Component {
    openLesson(i)
    {
        let item = document.getElementById('collapsible'+i);
        if (item.className.indexOf('open') === -1) {
            item.className = item.className + ' open';
        } else {
            item.className = 'collapsible lesson';
        }
    }

    lessonBody(lesson, i, j) {
        let self = this;
        return (
            <div className="collapsible lesson" id={"collapsible" + lesson.id} key={i}>
                <div className="collapsible-header" onClick={function () {
                    self.openLesson(lesson.id)
                }}>{lesson.title}
                    <span>
                                        <FontAwesomeIcon icon={"caret-down"}/>
                                    </span>
                </div>
                <div className="collapsible-content">
                    {(self.props.user.paid_access === true || i === 1) &&
                    <div>
                        {lesson.quiz_questions.map(function (question) {
                            j++;
                            return (
                                <div className="question cont" key={j}>
                                    <div className="col50 green-text">
                                        <p dangerouslySetInnerHTML={{__html: question.question}}/>
                                    </div>
                                    <div className="col50">
                                        <img
                                            src={"//cdn.testingmom.com/uploads/quizzes/" + question.image}
                                            alt=""/>
                                    </div>
                                    <div className="clear"/>
                                </div>
                            )
                        })}
                        <div className="lesson-content" style={{width: '96%'}}
                             dangerouslySetInnerHTML={{__html: lesson.content}}/>
                    </div>
                    }
                    {(!self.props.user.paid_access && i > 1) &&
                    <div className="lesson-content" style={{width: '96%'}}>
                        <p className="error">This content is reserved for Testingmom.com top
                            members.</p>
                        <a className="btn bkg_org col25 clear" href={WEBSITE_URL + "/buy-now/"}>Buy
                            Now</a>
                    </div>
                    }
                </div>
            </div>
        )
    }

    render() {
        let self = this;
        let i = 0;
        let j = 0;
        let has_overview = false;
        for (let lesson in this.props.lessons) {
            if (this.props.lessons.hasOwnProperty(lesson)) {
                if (this.props.lessons[lesson].title.indexOf('Overview') !== -1) {
                    has_overview = true;
                }
            }
        }
        let displayed_video = false;
        if (typeof this.props.lessons === 'object' && this.props.lessons.length > 0) {
            return (
                <div>
                    {
                        has_overview === false && this.props.videos.length > 0 &&
                        <Videos videos={this.props.videos} openLesson={self.openLesson} test_title={this.props.test_title}/>
                    }
                    {
                        this.props.lessons.map(function (lesson) {
                        i++;
                        if (i >= 2 && has_overview === true && parseInt(self.props.lesson_key) === 1
                            && !displayed_video && self.props.videos.length > 0) {
                            displayed_video = true;
                            return (
                                <div key={i}>
                                    <Videos videos={self.props.videos} openLesson={self.openLesson} test_title={self.props.test_title}/>
                                    { self.lessonBody(lesson, i, j) }
                                </div>
                            )
                        }
                        return (
                            self.lessonBody(lesson, i, j)
                        )
                        })
                    }
                </div>
            )
        } else {
            return false
        }
    }
}

export default Lessons;
