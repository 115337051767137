import React, {Component} from 'react';
import {WEBSITE_URL} from "../common/Urls";

const ApiFetch = require('../common/ApiFetch').ApiFetch;
const Loading = require('../common/Loading').Loading;
const ModuleWide = require('../common/Modules').ModuleWide;

class SbaProgram extends Component {
    constructor(props) {
        super(props);
        let self = this;
        this.state = {
            program: null,
            user: null,
            active_count: 0,
            has_requested: false,
            has_activated: false,
            has_deactivated: false,
        };

        this.sba_submit = this.sba_submit.bind(this);

        ApiFetch('sba/program/?url=' + this.props.appProps.match.params.program, 'GET').then(function(res){
            if (res['results'].hasOwnProperty(0)) {
                let program = res['results'][0];
                ApiFetch('sba/user/?user=' + self.props.user.pk + '&program=' + res['results'][0]['id'] +
                    '&ordering=-submit_date', 'GET').then(function(res){
                    if (res['results'].hasOwnProperty(0)) {
                        let sbaUser = res['results'][0];
                        let active_count = 0;
                        let has_activated = false;
                        if (sbaUser["status"] === 1) {
                            has_activated = true;
                            active_count += 1;
                            ApiFetch('sba/user/' + sbaUser['id'], 'PATCH', {});
                        }
                        self.setState({
                            program: program,
                            user: sbaUser,
                            active_count: active_count,
                            has_requested: (sbaUser['status'] === 0),
                            has_activated: has_activated,
                            has_deactivated: (sbaUser['status'] === 2),
                        });
                    } else {
                        self.setState({
                            program: program,
                            user: null
                        });
                    }
                });
            } else {
                self.setState({
                    error: true
                })
            }
        });
    }

    sba_submit(event){
        let data = {
            'child_first': document.getElementById('child_first').value,
            'email': document.getElementById('email').value,
            'grade': document.getElementById('grade').value,
            'user': document.getElementById('user').value,
            'program': document.getElementById('program').value
        };

        let class_code = document.querySelector('select[name=class_code]');
        if (class_code) {
            data['notes'] = class_code.value;
        }

        let stateerror = false;
        let stateSelect = document.getElementById('state');
        if (document.contains(stateSelect)) {
            data.state = stateSelect.value;
            if (data.state == null) {
                stateerror = true;
            }
        }

        if (data.child_first === '' || data.email === '' || data.grade === null
            || data.grade === '' || stateerror){
            window.alert('You must fill out all available form fields to get an account.  Please try again.');
            return false;
        }

        this.setState({
            has_requested: true,
            has_deactivated: false,
        });

        ApiFetch('sba/user/', 'POST', data).then(function (response) {
            document.getElementById("round3").style.display = "block";
            document.getElementById("round2").style.display = "none";
        });
        event.preventDefault();
        return false;
    }

    stateOptions()
    {
        let i = 0;
        let self = this;
        if (this.state.program.url === 'study-island') {
            return (
                <select name="state" id="state" defaultValue="">
                    <option disabled="disabled" value="">Select Child's State</option>
                    {
                        Object.keys(self.props.regions).map(function (region) {
                            i++;
                            return <option value={self.props.regions[region]['id']}
                                           key={i}>{self.props.regions[region]['name']}</option>;
                        })
                    }
                </select>
            )
        }
    }


    do_round2()
    {
        document.getElementById("round1").style.display = "none";
        document.getElementById("round2").style.display = "block";
    }
    sba_try_again()
    {
        document.getElementById("round4").style.display = "none";
        document.getElementById("round2").style.display = "block";
    }
    sba_sign_up()
    {
        document.getElementById("sba-sign-up").style.display = "block";
    }
    close_sba_sign_up()
    {
        document.getElementById("sba-sign-up").style.display = "none";
    }

    render () {
        let i = 0;
        let self = this;
        let default_password_posts = [28955, 28564, 28750, 28756, 22932, 16101, 15779, 15325, 13955, 13947];
        let school_class_code = [14418, 14416, 14416];

        if (this.props.user.paid_access) {
            if (this.state.program) {
                return (
                    <div id="skill-building">
                        <div className="dashboard content-container">
                            <div className="sba-single content-container">
                                {this.state.program.sub_needed && this.state.has_activated &&
                                    <div className="btn bkg_dk_bl clear login-info">
                                        <p>Your log-in to {this.state.program.program_name}</p>
                                        {this.state.user && <div>
                                            {this.state.user.status && <div>
                                                {this.state.active_count > 1 && <p>Account #{this.state.active_count}</p>}
                                                {this.state.program.wp_post_id === 19376 &&
                                                <strong>IMPORTANT: Sign-in as "STUDENT" (not Parent)</strong>
                                                }
                                                {this.state.user.username && <p>Log-in: {this.state.user.username} </p>}
                                                {this.state.program.wp_post_id === 28741 && <p>Username: testingmom</p>}
                                                {this.state.user.passwd && <p>Password: {this.state.user.passwd}</p>}
                                                {this.state.user.passwd === '' &&
                                                default_password_posts.indexOf(this.state.program.wp_post_id) !== -1 &&
                                                <p>Password: testingmom123</p>}
                                                {this.state.program.wp_post_id === 28564 && <p>Teacher Code: 9H52ZL</p>}
                                                {this.state.user.classcode &&
                                                <p>School/Class Code: {this.state.user.classcode}</p>
                                                }
                                                {this.state.user.classcode === '' &&
                                                school_class_code.indexOf(this.state.program.wp_post_id) !== -1 &&
                                                <p>School/Class Code: TestingMom1</p>}

                                                {this.state.program.wp_post_id === 19369 &&
                                                <p><strong>Classroom Code:</strong><br/><br/>
                                                'Kindergarten:  578340<br/>
                                                'Grade 1:           745191<br/>
                                                'Grade 2:           501278<br/>
                                                'Grade 3:           003870<br/>
                                                'Grade 4:           183517<br/>
                                                'Grade 5:           840366<br/>
                                                'Grade 6:           262257<br/>
                                                'Grade 7:           976560<br/>
                                                'Grade 8:           670885</p>
                                                }
                                                {this.state.program.wp_post_id === 15325 &&
                                                <p>School: Testing Mom - Testing Mom</p>}
                                                {this.state.program.wp_post_id === 13955 && <p>School ID: 119827</p>}
                                            </div>}
                                        </div>}
                                    </div>
                                }
                                {this.state.program.sub_needed && !this.state.has_activated && this.state.has_requested &&
                                <div className="btn bkg_grn" onClick={self.sba_sign_up} >
                                    <p style={{ fontSize: "1.3em" }}><strong>NOTICE:</strong> You have a
                                        pending request for {this.state.program.program_name}. Please allow
                                        1-2 business days for your request to be activated. If you are having
                                        issues with activation please <a href={WEBSITE_URL + "/my-account/contact-support/"} style={{color: "#ffff00"}}>
                                             contact support</a>.
                                    </p>
                                </div>}
                                {this.state.program.sub_needed && this.state.has_deactivated &&
                                <div className="btn bkg_org sba-sign-up"  onClick={self.sba_sign_up} >
                                    <p style={{ fontSize: "1.3em" }}>NOTICE: We de-activated your log-in to&nbsp;
                                        {this.state.program.program_name} due to inactivity. All of your
                                        previous data was erased from {this.state.program.program_name}.</p>
                                    <p style={{fontSize: "1.3em"}}>Please click here to request a NEW log-in.</p>
                                    <p>(Takes 1 to 2 Business Days)</p>
                                </div>}
                                {this.state.program.sub_needed && !this.state.has_activated && !this.state.has_requested &&
                                <div className="btn bkg_org sba-sign-up"
                                     onClick={self.sba_sign_up} style={{ padding: "1em", maxWidth: "300px", width: "90%" }}>
                                    <p style={{ fontSize: "1.3em" }}>CLICK HERE TO REQUEST LOGIN</p>
                                    <p>(Takes 1 to 2 Business Days)</p>
                                </div>
                                }
                                {
                                    this.state.program.sub_needed && <p className={"account-note"}><b>
                                        IMPORTANT NOTE: Your {this.state.program.program_name} account will be deactivated
                                        and removed from our system if you do not access your&nbsp;
                                        {this.state.program.program_name} account through this page
                                        within a {this.state.program.url === 'spelling-ninja' && '14'}
                                        {this.state.program.url !== 'spelling-ninja' && '21'} day period. To keep your&nbsp;
                                        {this.state.program.program_name} account active you MUST access&nbsp;
                                        {this.state.program.program_name} via the TestingMom.com website.</b>
                                    </p>
                                }
                                {
                                    this.state.program.sub_needed &&
                                    <p className={'lgray-text'}>Note: By using and submitting information to&nbsp;
                                        {this.state.program.program_name} you agree to be bound by&nbsp;
                                        {this.state.program.program_name}'s Terms and Conditions and Privacy
                                        Policy, available at their website.</p>
                                }
                                {
                                    this.state.program.url === 'hearbuilder' && <p className="error" style={{ fontWeight: "bold"}}>
                                        To our Users: Please note that HearBuilder is not functional on your iPad or
                                        using Safari. We invite our members to use their laptop or desktop computer
                                        using Chrome or Firefox to utilize this resource. Email help@testingmom.com
                                        if you need further guidance.</p>
                                }

                                <div className="cont">
                                    {
                                        !this.state.program.support_https &&
                                        <a id="sba-link" className="col50 clear" href={this.state.program.partner_url}
                                           target="_blank norefferer noopener">
                                            <img src={this.state.program.thumbnail} alt={this.state.program.program_name}/>
                                            <span className="bkg_dk_bl ">Launch {this.state.program.program_name} &raquo;</span>
                                        </a>
                                    }
                                    <br/><br/>
                                    <div dangerouslySetInnerHTML={{__html: this.state.program.display_text}}/>
                                </div>
                            </div>
                            <div id="sba-sign-up">
                                <div id="bkg" onClick={self.close_sba_sign_up}/>
                                <div className="round">
                                    <div id="round2">
                                        <form action="" id="sba-user-form" onSubmit={self.sba_submit} method="post">
                                            <p className="col50 clear">
                                                <img src={this.state.program.thumbnail}
                                                     alt={this.state.program.program_name} />
                                            </p>
                                            <span className="italic">Note: One account per family!</span><br/>
                                            <input type="text" id="child_first" name="child_first" placeholder="Child's First Name"/>
                                            <input type="hidden" id="email" name="email" value={ this.props.user.email } />
                                            <select name="grade" id="grade" defaultValue="">
                                                <option disabled="disabled" value="">Select Child's Grade</option>
                                                {
                                                    Object.keys(self.state.program.grades).map(function (grade) {
                                                        i++;
                                                        return <option value={self.state.program.grades[grade]['id']}
                                                                       key={i}>{self.state.program.grades[grade].name}</option>;
                                                    })
                                                }
                                            </select>
                                            {this.state.program.url === 'reflex-math' &&
                                            <select name="class_code">
                                                <option disabled selected>Select Difficulty</option>
                                                <option>Addition/Subtraction (0 - 10)</option>
                                                <option>Multiplication/Division (0 - 10)</option>
                                                <option>Multiplication/Division (0 - 12)</option>
                                            </select>
                                            }
                                            {this.stateOptions()}
                                            <input type="submit" value="Get Account"
                                                   className="bkg_dk_bl btn col50 clear"/>
                                            <input type="hidden" id="user" value={ this.props.user.pk } name="user"/>
                                            <input type="hidden" id="program" value={ this.state.program.id } name="program"/>
                                        </form>
                                    </div>
                                    <div id="round1">
                                        <strong>Important Message!</strong><br/>
                                        <strong>We HIGHLY recommend you read.</strong><br/><br/>
                                        <p>You will receive your log-in to our partner program within 1-2 days. You
                                            MUST log-in to the partner program within a 14 day period or your account
                                            will be de-activated.</p><br/>
                                        <p>To keep your account active you MUST access {this.state.program.program_name}
                                        &nbsp;via the TestingMom.com website.</p>
                                        <p onClick={self.do_round2}>Yes, I understand</p>
                                    </div>
                                    <div id="round3">
                                        <strong>Success!</strong><br/>
                                        <strong>We have received your request.</strong><br/><br/>
                                        <p>You will receive your log-in to our partner program within 1-2 days. You
                                            MUST log-in to the partner program within a 14 day period or your account
                                            will be de-activated.</p>
                                    </div>
                                    <div id="round4">
                                        <strong>There was an error with your request.</strong><br/><br/>
                                        <p>This email has been submitted, please enter another email address for more
                                            than one account set-up.</p>
                                        <br/><br/>
                                        <p onClick={self.sba_try_again}>Click here to try again</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else if (this.state.error) {
                return (
                    <ModuleWide content="Sorry that page cannot be found"/>
                )
            }  else {
                return <Loading/>
            }
        } else {
            window.location.href = WEBSITE_URL + 'buy-now/?redirected=1';
            return '';
        }
    } 
}

export default SbaProgram;
