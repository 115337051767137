import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './PodsModal.css'

export default class TutoringModal extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            show_tutoring_modal: false
        }
        this.close = this.close.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let self = this;
        if (self.props.showTutoringModal) {
            let show_tutoring_modal = this.getCookie('tutoring-modal');
            if (!show_tutoring_modal || parseInt(show_tutoring_modal) === 3) {
                this.setCookie('tutoring-modal', 1, 30);
                setTimeout(function () {
                    self.setState({
                        show_tutoring_modal: true
                    });
                }, 10000);
            } else {
                this.setCookie('tutoring-modal', parseInt(show_tutoring_modal) + 1, 30);
                return false;
            }
        }
    }

    getCookie(name) {
        let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }

    setCookie(name, value, days) {
        let d = new Date();
        d.setTime(d.getTime() + 24*60*60*1000*days);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
    }

    close(e)
    {
        let pods_modal = document.getElementById('pods-modal');
        let close = pods_modal.querySelector('.close');
        if (!this.props.isDescendant(pods_modal, e.target) || this.props.isDescendant(close, e.target)) {
            pods_modal.style.display = 'none';
            this.setState({
                show_pods_modal: false
            });
        }
    }

    render()
    {
        if (this.state.show_tutoring_modal) {
            return (
                <div id="pods-modal" onClick={this.close}>
                    <div className="pod-content">
                        <div>
                            <p>Does { this.props.child_name } need extra help with { this.props.test_title }?
                                Discover how our one-to-one online tutoring works. We
                                have { this.props.test_title } experts ready to help.&nbsp;
                                <strong><a href="https://www.testingmom.com/tutoring/?app=1">Schedule FREE 30 minute
                                    call</a></strong>
                                to speak to one of our educational advisors to learn more.</p>
                        </div>
                        <div className="close" onClick={this.close}><FontAwesomeIcon icon={'times-circle'}/></div>
                        <img src="https://cdn.testingmom.com/img/space-babies/spacebaby-3.png" alt=""/>
                    </div>
                </div>
            )
        } else {
            return false;
        }
    }
}
