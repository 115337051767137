import React from "react";
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function AnswerAreas(props) {
    let i = 0;
    return (
        props.answerArea.map(function(aa) {
            i++;
            return (
                <div className={'answerAreas'} key={props.id.toString() + (i - 1).toString()}>
                    {aa.ansAreaH !== 0 && aa.ansAreaH !== null &&
                        <div className={'wrong-answer'}
                             data-height={aa.ansAreaH}
                             data-width={aa.ansAreaW}
                             data-posy={aa.ansAreaY}
                             data-posx={aa.ansAreaX}
                             data-answer={i - 1}
                             onClick={props.wrong}
                        >
                            {props.result !== undefined && props.result.correct === false &&
                            <div className={"user-response"}
                                 style={{
                                     top: 'calc(' + props.result.answer_posy + '% - 8px)',
                                     left: 'calc(' + props.result.answer_posx + '% - 8px)'
                                 }}
                            />
                            }
                        </div>
                    }
                    <div className={'correct-answer ans' + i}
                         data-height={aa.ansH}
                         data-width={aa.ansW}
                         data-posy={aa.ansY}
                         data-posx={aa.ansX}
                         data-answer={i}
                         data-key={props.id.toString() + i.toString()}
                         onClick={props.correct}
                    >
                        {props.result !== undefined && props.result.correct === true &&
                            <div className={"user-response"}
                                 style={{
                                     top: 'calc(' + props.result.answer_posy + '% - 8px)',
                                     left: 'calc(' + props.result.answer_posx + '% - 8px)'
                                 }}
                            />
                        }
                    </div>
                </div>
            )
        })
    )
}

export function Question(props) {
    //let i = 0;
    let id = (props.result !== undefined) ? 'result-' + props.id : 'ques-' + props.id;
    let breakTag = '<br />';
    let replaceStr = '$1'+ breakTag +'$2';
    let explanation =  '';
    if (props.explanation) {
        explanation = 'Explanation: ' + (props.explanation + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
    }
    let question_class = 'question';
    if (props.audio && (props.can_play_audio === true || props.question.toLowerCase().indexOf('listening') > 0)) {
        question_class += ' has_audio';
    }
    return (
        <div id={id} data-id={props.question_id} data-answercount={props.answerArea.length} className={"ques-cont ques_id" + props.question_id}>
            <div id={"ques-" + props.question_id } className={question_class}>
                <p className="question-text" dangerouslySetInnerHTML={{__html: props.question.replace(/\\/g, '')}} />
                {props.test === 338 && props.id === 0 &&
                    <p>
                        The AABL&reg; assesses skills in Early Literacy, Verbal and Quantitative Reasoning
                        and Mathematics.  Students must listen to each question and then touch the correct
                        answer.  Be sure to enable the audio output on your device so your child can hear each
                        practice question as it is read aloud.  Your child may touch the replay button to
                        hear the question again.
                    </p>
                }
                {props.audio && (props.can_play_audio === true || props.question.toLowerCase().indexOf('listening') > 0) &&
                    <div className="audio">
                        <audio id={"question_audio" + props.id}>
                            <source src={"https://questions.testingmom.com/audio/" + props.audio} type="audio/mpeg" />
                        </audio>
                        <div className="replay" onClick={function() {props.replayAudio(props.id);}}>
                            <img src="//cdn.testingmom.com/img/ReplayButton.jpg" alt="" style={{border: 'none'}} />
                        </div>
                    </div>
                }
                {props.ans_image !== null &&
                    <div
                        className="image question-image"
                        style={{height: props.image.height + 'px', width: props.image.width + 'px'}}
                    >
                        <img src={"https://questions.testingmom.com/" + props.image.image }
                             alt=""
                             className="question-image"
                        />
                    </div>
                }
                {props.ans_image !== null &&
                    <div
                        className="image answer-image"
                        style={{height: props.ans_image.height + 'px', width: props.ans_image.width + 'px'}}
                        data-width={props.ans_image.width}
                        data-height={props.ans_image.height}>
                        <AnswerAreas id={props.question_id} answerArea={props.answerArea}  wrong={props.wrong} correct={props.correct} />
                        <img src={"https://questions.testingmom.com/" + props.ans_image.image} alt=""/>
                    </div>
                }
                {props.ans_image === null && props.image !== null &&
                    <div
                        className="image answer-image"
                        style={{height: props.image.height + 'px', width: props.image.width + 'px'}}
                        data-width={props.image.width}
                        data-height={props.image.height}>
                        <AnswerAreas
                            id={props.question_id}
                            answerArea={props.answerArea}
                            wrong={props.wrong}
                            correct={props.correct}
                            result={props.result}
                        />
                        <img src={"https://questions.testingmom.com/" + props.image.image} alt="" />
                    </div>
                }
                {props.result !== undefined && props.freeQuestions !== undefined &&
                    <div className="your-response">
                        {props.result.correct !== null && props.result.correct === true &&
                        <p>Your Response: Correct! <FontAwesomeIcon icon={"smile"} /></p>
                        }
                        {props.result.correct !== null && props.result.correct === false &&
                        <p>Your Response: Wrong <FontAwesomeIcon icon={"frown"} /></p>
                        }
                    </div>
                }
                {
                    props.result !== undefined && <p className="explanation" dangerouslySetInnerHTML={{__html: explanation}}/>
                }
                <div className="desc">
                    <div className="report-error" id={'error' + props.id} onClick={function(){props.reportError(props.id);}}>
                        Something wrong with this question? Click here to notify us.
                    </div>
                    {props.result &&
                    <div id="legend">
                        <div><span className="correct-area"/> = Correct Answer</div>
                        <div><span className="your-selection"/> = Your selection</div>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}

export function SetAnswerArea(id, results)
{
    //Pixel width the answer areas are based on
    let base = 470;
    //The biggest width we want to go for images
    let max = 900;
    let questionsDiv = document.getElementById(id);
    if (questionsDiv !== null) {
        let height_control = 50;
        let max_height = questionsDiv.clientHeight - height_control;
        let containers = questionsDiv.getElementsByClassName('ques-cont');
        for (let i = 0; i < containers.length; i++) {
            let question = containers.item(i);
            let image = question.getElementsByClassName('answer-image')[0];
            if (typeof image !== 'undefined') {
                let width = question.getElementsByClassName('question')[0].clientWidth;
                let question_text_height = question.getElementsByClassName('question-text')[0].clientHeight;
                let explanation_text_height = 0;
                let explanation = question.getElementsByClassName('explanation');
                if (explanation.length) {
                    explanation_text_height = explanation[0].clientHeight;
                }
                let description_height = 0;
                let description = question.getElementsByClassName('desc');
                if (description.length) {
                    description_height = description[0].clientHeight;
                }
                let question_max_height = max_height - question_text_height - explanation_text_height - description_height;
                let correctTotal = questionsDiv.getElementsByClassName('correct-total');
                if (correctTotal.length > 0) {
                    question_max_height -= correctTotal[0].clientHeight;
                }
                let yourResponse = question.getElementsByClassName('your-response');
                if (yourResponse.length > 0) {
                    question_max_height -= 70;
                }
                let prop = 1;
                let base_image_width = image.dataset.width;
                let base_image_height = image.dataset.height;
                Array.from(image.getElementsByClassName('correct-answer')).forEach((ca) => {
                    let image_width = base_image_width;
                    let image_height = base_image_height;
                    let cw = ca.dataset.width;
                    let ch = ca.dataset.height;
                    let cx = ca.dataset.posx;
                    let cy = ca.dataset.posy;

                    if (image_width > base) {
                        prop = image_width / base;
                        cw = cw * prop;
                        ch = ch * prop;
                        cx = cx * prop;
                        cy = cy * prop;
                    }
                    if (image_width > max) {
                        prop = max / image_width;
                        image_height = image_height * prop;
                        image_width = max;
                        cw = cw * prop;
                        ch = ch * prop;
                        cx = cx * prop;
                        cy = cy * prop;
                    }
                    if (image_width > width) {
                        prop = width / image_width;
                        image_height = image_height * prop;
                        cw = cw * prop;
                        ch = ch * prop;
                        cx = cx * prop;
                        cy = cy * prop;
                    }
                    if (image_height > question_max_height) {
                        prop = question_max_height / image_height;
                        cw = cw * prop;
                        ch = ch * prop;
                        cx = cx * prop;
                        cy = cy * prop;
                    }
                    ca.style.width = cw + 'px';
                    ca.style.height = ch + 'px';
                    ca.style.margin = cy + 'px 0 0 ' + cx + 'px';
                });

                let image_width = base_image_width;
                let image_height = base_image_height;
                let wrong = image.getElementsByClassName('wrong-answer')[0];
                let aw = wrong.dataset.width;
                let ah = wrong.dataset.height;
                let ax = wrong.dataset.posx;
                let ay = wrong.dataset.posy;

                if (image_width > base) {
                    prop = image_width / base;
                    aw = aw * prop;
                    ah = ah * prop;
                    ax = ax * prop;
                    ay = ay * prop;
                }

                if (image_width > max) {
                    prop = max / image_width;
                    image_height = image_height * prop;
                    image_width = max;
                    aw = aw * prop;
                    ah = ah * prop;
                    ax = ax * prop;
                    ay = ay * prop;
                }

                if (image_width > width) {
                    prop = width / image_width;
                    image_height = image_height * prop;
                    image_width = width;
                    aw = aw * prop;
                    ah = ah * prop;
                    ax = ax * prop;
                    ay = ay * prop;
                }
                if (image_height > question_max_height) {
                    prop = question_max_height / image_height;
                    image_height = question_max_height;
                    image_width = image_width * prop;
                    aw = aw * prop;
                    ah = ah * prop;
                    ax = ax * prop;
                    ay = ay * prop;
                }
                if (results) {
                    image.style.margin = ((question_max_height - image_height) / 2) - 2 + 'px auto';
                } else {
                    image.style.margin = (question_max_height - image_height) / 2 + 'px auto';
                }
                image.style.width = image_width + 'px';
                image.style.height = image_height + 'px';
                let img = image.getElementsByTagName('img')[0];
                img.style.width = image_width + 'px';
                img.style.height = image.height + 'px';
                wrong.style.width = aw + 'px';
                wrong.style.height = ah + 'px';
                wrong.style.margin = ay + 'px 0 0 ' + ax + 'px';
            }
        }
    }
}

Question.propTypes = {
    question: PropTypes.string.isRequired,
    audio: PropTypes.string,
    ans_image: PropTypes.object,
    image: PropTypes.object.isRequired,
    reportError: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    question_id: PropTypes.number.isRequired,
    skills: PropTypes.array,
};
