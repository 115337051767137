import React, {Component} from 'react';
import '../sba-games/SBA.css';
import PropTypes from 'prop-types'
import '../common/ThumbnailLink.css'
import Link from './Link';


class ThumbnailLink extends Component {
    render () {
        let instant_access = "";
        let star = "";
        if (typeof this.props.instant_access !== 'undefined') {
            if (this.props.instant_access === true) {
                instant_access = "instant-access";
                star = 'orange star'
            } else {
                instant_access = "request-access";
                star = 'green star';
            }
        }
        if (typeof(this.props.user_access) !== 'undefined' && this.props.user_access) {
             instant_access = "has-access";
             star = 'blue star';
        }
        let grades = "";
        if (typeof this.props.grade !== 'undefined') {
            grades = this.props.grade
        }
        return (
            <Link to={this.props.link} className={instant_access + " thumbnail-link"}
                  key={this.props.grade_val} title={this.props.title}>
                <img src={this.props.thumbnail} alt={this.props.alt} />
                {grades !== "" &&
                <div className="grades"><span>{grades}</span></div>
                }
                <div className="title"><span>{this.props.display_name}</span></div>
                <div className="description"><span dangerouslySetInnerHTML={{__html: this.props.description}}/></div>
                {star !== "" &&
                    <div className="star-bg">
                        <div className={star}/>
                    </div>
                }
                <div className={'clear'} />
            </Link>
        )
    }
}

ThumbnailLink.propTypes = {
    link: PropTypes.string,
    title: PropTypes.string,
    thumbnail: PropTypes.string,
    alt: PropTypes.string,
    display_name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    instant_access: PropTypes.bool,
    grade_val: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    description: PropTypes.string,
    grade: PropTypes.string
};

export default ThumbnailLink;
