import React, {Component} from 'react';
import './TestDirectory.css'
import {Link} from "react-router-dom";

class TestDirectoryListing extends Component {
    render() {
        let tests_by_letter = {};
        for (let test in this.props.test_pages_by_slug) {
            if (this.props.test_pages_by_slug.hasOwnProperty(test)) {
                let first_letter = this.props.test_pages_by_slug[test].title.substring(0, 1).toUpperCase();
                if (!tests_by_letter.hasOwnProperty(first_letter)) {
                    tests_by_letter[first_letter] = [];
                }
                tests_by_letter[first_letter].push(this.props.test_pages_by_slug[test]);
            }
        }
        return (
            <div className="content-container">
                <p>Click on a test to see more information.</p>
                <div className="listing ">
                    <ul className="test-list">
                    {
                        Object.keys(tests_by_letter).map(function (key) {
                            return (
                                <div className="group" key={key}>
                                    <h2>{key}</h2>
                                    {
                                    tests_by_letter[key].map(function(test){
                                        let title = <span dangerouslySetInnerHTML={{__html: test.title}}/>;
                                        return <Link key={test.id} to={"/test-prep/" + test.slug + '/practice-questions'}>{title}</Link>
                                    })}
                                </div>
                            )
                        })
                    }
                    </ul>
                </div>
            </div>
        )
    }
}

export default TestDirectoryListing;