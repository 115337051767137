import React, {Component} from 'react';
import './TestDirectory.css'
import {Link} from "react-router-dom";
import TestDirectoryListing from "./TestDirectoryListing";
import TestDirectoryStates from "./TestDirectoryStates";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class TestDirectory extends Component {
    render() {
        let Component = TestDirectoryListing;
        let path = this.props.appProps.location.pathname;
        if (this.props.appProps.match.params.view === '') {
            Component = TestDirectoryListing;
        }
        if (this.props.appProps.match.params.view === 'states') {
            Component = TestDirectoryStates;
        }
        let backToDashboard = '/';
        if (this.props.user.active_child) {
            backToDashboard = '/dashboard/' + this.props.user.active_child;
        }
        return (
            <div id="test-directory" className="wrapper">
                <div className="dashboard content-container">
                    <Link className={"back-to-dash"} to={ backToDashboard }>
                        <FontAwesomeIcon icon={'arrow-circle-left'} />Back to Dashboard</Link>
                    <div className="control">
                        <Link to="/tests" className={path.indexOf('test-directory')
                        && path !== '/tests/states' ? 'active' : ''}>How to Prep by Test</Link>
                        <Link to="/tests/states" className={path === '/tests/states' ? 'active' : ''}>State Achievement Tests</Link>
                    </div>
                    <Component
                        appProps={this.props.appProps}
                        deleteDocument={this.props.deleteDocument}
                        grades={this.props.grades}
                        tests={this.props.tests}
                        test_pages_by_slug={this.props.test_pages_by_slug}
                        user={this.props.user}
                        updateChild={this.props.updateChild}
                    />
                </div>
            </div>
        )
    }
}

export default TestDirectory;
