import React, {Component} from 'react';
import './TestDirectory.css'
import {Link} from "react-router-dom";
import Printables from '../common/Printables';
import Document from "../test-prep/Document";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ApiFetch = require('../common/ApiFetch').ApiFetch;
const Loading = require('../common/Loading').Loading;

class StatesCommonCore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            state: [],
            documents: {}
        }
    }

    componentDidMount() {
        let self = this;
        if (typeof this.props.appProps.match.params.state !== 'undefined') {
            ApiFetch('state-standard/?limit=1&state=' + self.props.appProps.match.params.state).then(function(res){
                self.setState({
                    'state': res['results']
                });
                ApiFetch('document/?limit=1000&min_question_count=0&state=' + self.props.appProps.match.params.state).then(function(res){
                    let results = res['results'];
                    let documents = {'': []};
                    for (let doc in results) {
                        if (results.hasOwnProperty(doc)) {
                            for (let grade in results[doc]['grades']) {
                                if (results[doc]['grades'].hasOwnProperty(grade)) {
                                    if (!(results[doc]['grades'][grade]['name'] in documents)) {
                                        documents[results[doc]['grades'][grade]['name']] = [];
                                    }
                                    documents[results[doc]['grades'][grade]['name']].push(results[doc])
                                }
                            }
                            documents[''].push(results[doc]);
                        }
                    }
                    self.setState({
                        documents: documents
                    });
                })
            });
        }
    }

    render() {
        let doc = this.props.appProps.match.params.document;
        if (doc !== undefined) {
            return <Document doc={doc} user={this.props.user}/>
        }
        let backToDashboard = '/';
        if (this.props.user.active_child) {
            backToDashboard = '/dashboard/' + this.props.user.active_child;
        }
        if (this.state.state.length > 0) {
            return (
                <div id="test-directory" className="wrapper">
                    <div className="dashboard content-container">
                        <Link className={"back-to-dash"} to={ backToDashboard }>
                            <FontAwesomeIcon icon={'arrow-circle-left'} />Back to Dashboard</Link>
                        <div className="control">
                            <Link to="/tests">How to Prep by Test</Link>
                            <Link to="/tests/states" className='active'>State
                                Achievement Tests</Link>
                        </div>
                        <h1 dangerouslySetInnerHTML={
                            {__html: this.state.state[0]['title']}
                        }/>
                        <div dangerouslySetInnerHTML={
                            {__html: this.state.state[0]['top_text']}
                        }/>
                        <Printables
                            appProps={this.props.appProps}
                            child={this.props.user.children[this.props.user.active_child]}
                            current_grade=""
                            deleteDocument={this.props.deleteDocument}
                            documents={{documents: this.state.documents}}
                            page={'state'}
                            updateChild={this.props.updateChild}
                            updateUser={this.props.updateUser}
                            user={this.props.user}
                        />
                        <div dangerouslySetInnerHTML={
                            {__html: this.state.state[0]['bottom_text']}
                        }/>
                    </div>
                </div>
            )
        } else {
            return <Loading />
        }
    }
}

export default StatesCommonCore;
