import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import './SBA.css'
import ThumbnailLink from "../common/ThumbnailLink";

const Loading = require('../common/Loading').Loading;
const WEBSITE_URL = require('../common/Urls').WEBSITE_URL;

class GamesIndex extends Component {
    constructor(props) {
        super(props);
        let grade = "";
        if (
            this.props.user.children.length &&
            this.props.user.children.hasOwnProperty(this.props.user.active_child) &&
            this.props.user.children[this.props.user.active_child].grade !== null
        ) {
            grade = this.props.user.children[this.props.user.active_child]['grade']
        }
        var urlParams = new URLSearchParams(window.location.search);
        let param = urlParams.get('grade');
        if (param) {
            if (param === 'all') {
                grade = "";
            } else {
                grade = param
            }
        }
        this.state = {
            'selected_grade': grade
        };
        this.filter = this.filter.bind(this);
    }

    filter(e)
    {
        this.setState({
            selected_grade:  e.target.value
        });
    }

    render () {
        let self = this;
        let i = 0;

        if (Object.keys(this.props.extraProps.games).length) {
            return (
                <div id="skill-building">
                    <div className="dashboard content-container">
                        <h1>Games</h1>
                         <div className="subjects left">
                             <div className={'styled-select'}>
                                 <select name="grade" onChange={this.filter} defaultValue={self.state.selected_grade}>
                                     <option disabled>Filter by Grade Level...</option>
                                     <option value="">All Grades</option>
                                     {
                                         Object.keys(this.props.grades).map(function(grade) {
                                             i++;
                                             if (self.props.grades[grade].name === '2s and 3s'
                                                 || self.props.grades[grade].name === '9th Grade'
                                                 || self.props.grades[grade].name === '10th Grade'
                                                 || self.props.grades[grade].name === '11th Grade'
                                                 || self.props.grades[grade].name === '12th Grade') {
                                                 return false;
                                             }
                                             return <option value={self.props.grades[grade]['id']}
                                                       key={i}>{self.props.grades[grade].name}</option>;
                                         })
                                     }
                                 </select>
                             </div>
                        </div>
                        <div className="clear" />
                        <div className="programs">
                            {
                                Object.keys(this.props.extraProps.games).map(function(key){
                                    i++;
                                    let game = self.props.extraProps.games[key];
                                    let safeDisplayName = <span dangerouslySetInnerHTML={{__html: game.short_title}}/>;
                                    if (game.short_title !== 'Digital Tutor' &&
                                        (self.state.selected_grade === "" ||
                                        game.grades.indexOf(parseInt(self.state.selected_grade)) !== -1)) {
                                        let link = game.url;
                                        if (game.app_url !== null && self.props.user.paid_access) {
                                            link = game.app_url;
                                        } else {
                                            link = WEBSITE_URL + 'buy-now/?redirected=1';
                                        }
                                        return <ThumbnailLink
                                            key={i}
                                            link={link}
                                            alt={game.short_title}
                                            thumbnail={game.thumbnail}
                                            title={game.description}
                                            display_name={safeDisplayName}
                                            description={game.description}
                                        />
                                    } else {
                                        return false;
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            )
        } else {
            return <Loading />
        }
    }
}

export default withRouter(GamesIndex);
