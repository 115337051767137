import React, {Component} from 'react';

import Link from '../common/Link';
import './Enrichment.css'
import getSeason from '../common/GetSeason';

const ApiFetch = require('../common/ApiFetch').ApiFetch;
const Loading = require('../common/Loading').Loading;
const moment = require('moment-timezone');

class Enrichment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            factoid: null,
            grade: 1,
            data: {}
        };
        this.currentWeek = 1;
        this.currentWeekHeight = 0;
    }

    componentDidMount()
    {
        let self = this;
        let currentWeek = moment().week();
        let params = {
            'week': currentWeek + ',' + (currentWeek + 1),
            'ordering': 'week,grade,-online',
            'status': 1,
            'limit': 100
        };
        let queryString = Object.keys(params).map(function(key) {
            return key + '=' + params[key]
        }).join('&');
        ApiFetch('summer-learning?' + queryString, 'GET').then(function(response) {
            if (response.hasOwnProperty('results')) {
                let data = {
                    currentWeek: {},
                    nextWeek: {}
                };
                for (let r in response.results) {
                    if (response.results.hasOwnProperty(r)) {
                        let result = response.results[r];
                        if (!data.currentWeek.hasOwnProperty(result.grade)) {
                            data.currentWeek[result.grade] = {
                                'online': {},
                                'offline': {}
                            };
                            data.nextWeek[result.grade] = {
                                'online': {},
                                'offline': {}
                            };
                        }
                        let info = {
                            title: result.title,
                            link: result.link.replace('https://members.testingmom.com/', '/'),
                            icon: result.icon.file,
                            description: result.description
                        };
                        if (result.week === currentWeek) {
                            if (result.online) {
                                data.currentWeek[result.grade].online = info;
                            } else {
                                data.currentWeek[result.grade].offline = info;
                            }
                        } else {
                            if (result.online) {
                                data.nextWeek[result.grade].online = info;
                            } else {
                                data.nextWeek[result.grade].offline = info;
                            }
                        }
                    }
                }
                self.setState({
                    factoid: response.results[0].factoid[0].factoid,
                    data: data
                });
            }
        });
      
    }

    changeGrade(grade, week)
    {
        this.setState({
            grade: grade
        });
        if (week !== this.currentWeek) {
            let currentWeek = document.getElementById('current-week').getElementsByClassName('item-container');
            let nextWeek = document.getElementById('next-week').getElementsByClassName('item-container');
            if (currentWeek && nextWeek) {
                if (week === 1 && this.currentWeekHeight) {
                    currentWeek[0].style.height = this.currentWeekHeight + 'px';
                    nextWeek[0].style.display = 'none';
                } else {
                    if (this.currentWeekHeight === 0) {
                        this.currentWeekHeight = currentWeek[0].offsetHeight;
                    }
                    currentWeek[0].style.height = 0;
                    nextWeek[0].style.display = 'block';
                }
                this.currentWeek = week;
            }
        }
    }

    render()
    {
        let self = this;
        let currentWeek = moment().week();
        let season = getSeason(currentWeek);
        let currentWeekMoment = moment().week(currentWeek);
        let nextWeekMoment = moment().week(currentWeek + 1);
        let current_week_text = currentWeekMoment.day(0).format('MMMM D') + ' to ' + currentWeekMoment.day(6).format('MMMM D');
        let next_week_text =nextWeekMoment.day(0).format('MMMM D') + ' to ' + nextWeekMoment.day(6).format('MMMM D');
        let onlineOffline = ['online', 'offline'];
        let key = 0;
        return(
            <div className="has-sidebar content-container">
                <div className={"wrapper wbkg selected-program cont " + season + "-Enrichment"} id="enrichment">
                    <div className="header">
                        <div className="logo">
                            <img src={"//cdn.testingmom.com/img/summer-learning/" + season + "-Enrichment.png"}
                                 style={{height: "100px"}} alt=""/>
                        </div>
                        <h2>{season}<span>Enrichment Program</span></h2>
                        {this.state.factoid !== null &&
                        <div className="factoid">
                            <p className="title">LEARNING LOWDOWN</p>
                            <div dangerouslySetInnerHTML={{__html: this.state.factoid}} />
                        </div>
                        }
                        <div className="clear" />
                    </div>
                    <div id="current-week" className="week-container" data-week={ currentWeek }>
                        <div className="week">
                            <p>Plan This Week:</p>
                            <p>{current_week_text}</p>
                        </div>
                        <nav>
                            <ul data-week="current">
                                <li onClick={function(){self.changeGrade(1, 1)}} className={this.state.grade === 1 && this.currentWeek === 1  ? 'active' : ''}>Pre-K to
                                    Kindergarten
                                </li>
                                <li onClick={function(){self.changeGrade(2, 1)}} className={this.state.grade === 2 && this.currentWeek === 1  ? 'active' : ''}>1st to 2nd Grade</li>
                                <li onClick={function(){self.changeGrade(3, 1)}} className={this.state.grade === 3 && this.currentWeek === 1  ? 'active' : ''}>3rd to 6th Grade</li>
                                <li onClick={function(){self.changeGrade(4, 1)}} className={this.state.grade === 4 && this.currentWeek === 1  ? 'active' : ''}>7th to 8th Grade</li>
                            </ul>
                        </nav>
                        {Object.keys(this.state.data).length > 0 &&
                        <div className="item-container">
                            <div className="grade-container">
                                {onlineOffline.map(function(o){
                                    key++;
                                    return (
                                    <div key={key} className={o + " section"}>
                                        <p>{o.toUpperCase()} ACTIVITIES</p>
                                        <div className="item">
                                            <div className="icon">
                                                {self.state.data.currentWeek[self.state.grade][o].link !== '' &&
                                                <Link to={self.state.data.currentWeek[self.state.grade][o].link}>
                                                    <img src={"//cdn.testingmom.com/" +  self.state.data.currentWeek[self.state.grade][o].icon}
                                                         alt={self.state.data.currentWeek[self.state.grade][o].title }/>
                                                </Link>
                                                }
                                                {self.state.data.currentWeek[self.state.grade][o].link === '' &&
                                                    <img src={"//cdn.testingmom.com/" +  self.state.data.currentWeek[self.state.grade][o].icon}
                                                         alt={self.state.data.currentWeek[self.state.grade][o].title }/>
                                                }
                                            </div>
                                            <div className="text">
                                                <div className="title">
                                                    {self.state.data.currentWeek[self.state.grade][o].link !== '' &&
                                                    <Link
                                                        to={self.state.data.currentWeek[self.state.grade][o].link}
                                                    >
                                                        <p dangerouslySetInnerHTML={{__html: self.state.data.currentWeek[self.state.grade][o].title}}/>
                                                    </Link>
                                                    }
                                                    {self.state.data.currentWeek[self.state.grade][o].link === '' &&
                                                    <p dangerouslySetInnerHTML={{__html: self.state.data.currentWeek[self.state.grade][o].title}}/>
                                                    }
                                                </div>
                                                <div dangerouslySetInnerHTML={{__html: self.state.data.currentWeek[self.state.grade][o].description}}/>
                                            </div>
                                            <div className="clear" />
                                        </div>
                                    </div>
                                    )
                                })}
                            </div>
                        </div>
                        }
                        {Object.keys(this.state.data).length === 0 && <div style={{height: '300px', position: 'relative'}}><Loading /></div>}
                        <div className="clear"/>
                    </div>
                    <div id="next-week" className="week-container">
                        <div className="week">
                            <p>Plan Next Week:</p>
                            <p>{next_week_text}</p>
                        </div>
                        <nav>
                            <ul data-week="next">
                                <li onClick={function(){self.changeGrade(1, 2)}} className={this.state.grade === 1 && this.currentWeek === 2 ? 'active' : ''}>Pre-K to
                                    Kindergarten
                                </li>
                                <li onClick={function(){self.changeGrade(2, 2)}} className={this.state.grade === 2 && this.currentWeek === 2  ? 'active' : ''}>1st to 2nd Grade</li>
                                <li onClick={function(){self.changeGrade(3, 2)}} className={this.state.grade === 3 && this.currentWeek === 2  ? 'active' : ''}>3rd to 6th Grade</li>
                                <li onClick={function(){self.changeGrade(4, 2)}} className={this.state.grade === 4 && this.currentWeek === 2  ? 'active' : ''}>7th to 8th Grade</li>
                            </ul>
                        </nav>
                        {Object.keys(this.state.data).length > 0 &&
                            <div className="item-container">
                            {onlineOffline.map(function(o){
                                    key++;
                                    return (
                                    <div key={key} className={o + " section"}>
                                        <p>{o.toUpperCase()} ACTIVITIES</p>
                                        <div className="item">
                                            <div className="icon">
                                                {self.state.data.nextWeek[self.state.grade][o].link !== '' &&
                                                <Link to={self.state.data.nextWeek[self.state.grade][o].link}>
                                                    <img src={"//cdn.testingmom.com/" +  self.state.data.nextWeek[self.state.grade][o].icon}
                                                         alt={self.state.data.nextWeek[self.state.grade][o].title }/>
                                                </Link>
                                                }
                                                {self.state.data.nextWeek[self.state.grade][o].link === '' &&
                                                    <img src={"//cdn.testingmom.com/" +  self.state.data.nextWeek[self.state.grade][o].icon}
                                                         alt={self.state.data.nextWeek[self.state.grade][o].title }/>
                                                }
                                            </div>
                                            <div className="text">
                                                <div className="title">
                                                    {self.state.data.nextWeek[self.state.grade][o].link !== '' &&
                                                    <Link
                                                        to={self.state.data.nextWeek[self.state.grade][o].link}
                                                    >
                                                        <p dangerouslySetInnerHTML={{__html: self.state.data.nextWeek[self.state.grade][o].title}}/>
                                                    </Link>
                                                    }
                                                    {self.state.data.nextWeek[self.state.grade][o].link === '' &&
                                                    <p dangerouslySetInnerHTML={{__html: self.state.data.nextWeek[self.state.grade][o].title}}/>
                                                    }
                                                </div>
                                                <div dangerouslySetInnerHTML={{__html: self.state.data.nextWeek[self.state.grade][o].description}}/>
                                            </div>
                                            <div className="clear" />
                                        </div>
                                    </div>
                                    )
                                })}
                            </div>
                        }
                        <div className="clear"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Enrichment;
